@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.full-width-image {
	margin-bottom:50px;
	position:relative;

	@include breaker.below(grid.$s){
		margin-bottom:60px;
	}

	img {
		width:100%;
		height:auto;
	}

	span {
		position: absolute;
		top: 20px;
		left: 20px;
		color: #fff;
		font-size: 14px;
		background-color: rgba(0,0,0,.75);
		padding: 5px 10px;
	}

	p {
		position:absolute;
		bottom:0px;
		left:0px;
		right:0px;
		padding:20px;
		background-color:rgba(0, 0, 0, 0.75);
		color:theme.$white;
		font-size:16px;
		line-height:26px;

		@include breaker.below(grid.$s){
			bottom:20px;
			left:20px;
			right:20px;
			font-size:12px;
			line-height:16px;
			padding:10px;
		}
	}
}