@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.article-section {
      margin-bottom:80px;

      @include breaker.below(grid.$s){
            margin-bottom:40px;
      }

      &__title {
            font-size:24px;
            line-height:28px;
		margin-bottom:70px;

		@include breaker.below(grid.$s){
			font-size:18px;
			line-height:24px;
			margin-bottom:40px;
		}
      }

      .grid {
            max-width:1180px;
            width:100%;
            margin:0 auto;
            display:grid;
            @supports (display: grid) {
                  display:grid;
                  grid-template-columns: repeat(2, 1fr);
                  grid-column-gap: 120px;
			grid-row-gap:120px;

                  &.col3 {
                        grid-template-columns: repeat(3, 1fr);
                  }

                  &.col4 {
                        grid-template-columns: repeat(4, 1fr);
                  }

			@include breaker.below(grid.$m){
				grid-template-columns: 100%;
				grid-column-gap:20px;
				grid-row-gap:40px;
			}

                  $numCols: 1, 2, 3, 4;

                  @each $cols in $numCols {
                        &.m-col#{$cols} {
                              @include breaker.below(grid.$m) {
                                    grid-template-columns: repeat(#{$cols}, 1fr);
                              }
                        }
                  }

                  @each $cols in $numCols {
                        &.s-col#{$cols} {
                              @include breaker.below(grid.$s) {
                                    grid-template-columns: repeat(#{$cols}, 1fr);
                              }
                        }
                  }


            }
      }

      &__article {
            color:inherit;
            text-decoration:none;

            img {
                  width:100%;
                  height:auto;
                  transition:transform 1s ease-in-out;

            }

            h2 {
                  font-size:21px;
                  line-height:24px;
                  color:theme.$black;
                  text-decoration:underline;
                  margin-bottom:10px;
			transition:opacity .2s ease-in-out;
			@include breaker.below(grid.$s){
				font-size:18px;
				line-height:24px;
			}
            }

            p {
                  font-size:18px;
                  line-height:28px;
			transition:opacity .2s ease-in-out;

			@include breaker.below(grid.$s){
				font-size:14px;
				line-height:24px;
			}
            }

            &:hover {
                  h2 {
                        opacity:0.6;
                  }

                  img {
                        transform:scale(1.05);
                  }
            }
      }

      &__article-image {
            overflow:hidden;
            margin-bottom:30px;
      }
}