@use "sass:math";
@use 'breaker';

// grid variables
$grid-max-width: 1440px;

// Default content settings.
$gutter: 40px; //Whatever you want

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 500px;
$m: 800px;
$l: 1050px;
$xl: 1440px;

// Clearfix the wrapper with columns inside.
.wrapper:after,
.row:after {
	content: "";
	display: table;
	clear: both;
}

// make sure that padding applied to our elements is
// added within their calculated dimensions, giving us far more freedom for defining column widths.
*,
*:after,
*:before {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.col-center {
	margin:0 auto;
	float:none;
}

.text-centered {
	text-align:center;
}

// Grid settings.
@mixin grid($size, $gutter: $gutter) {
	$cycle: math.round(math.div(12, $size));
	$colSize: #{$size + '/12'};
	width: calc(100% *  #{$colSize} - (#{$gutter} - #{$gutter} * #{$colSize}));

 	&:nth-child(n) {
   		margin-right: $gutter;
  	}
  	&:last-child {
    	margin-right: 0;
  	}
  	&:nth-child(#{$cycle}n) {
    	margin-right: 0 !important;
  	}
}

// Custom settings.
.align-center{
	text-align: center;
}
.col-right {
  float: right !important;
  box-sizing: border-box;
}

.col-center{
	display: block;
	float: none !important;
	margin-left: auto !important;
	margin-right: auto !important;
}


.col-1{
	@include grid(1);
}
.col-2{
	@include grid(2);
}
.col-3{
	@include grid(3);
}
.col-4{
	@include grid(4);
}
.col-5{
	@include grid(5);
}
.col-6{
	@include grid(6);
}
.col-7{
	@include grid(7);
}
.col-8{
	@include grid(8);
}
.col-9{
	@include grid(9);
}
.col-10{
	@include grid(10);
}
.col-11{
	@include grid(11);
}
.col-12{
	@include grid(12);
}

@include breaker.below($m){
	.m-col-1{ @include grid(1, ($gutter * 0.5)); }
	.m-col-2{ @include grid(2, ($gutter * 0.5)); }
	.m-col-3{ @include grid(3, ($gutter * 0.5)); }
	.m-col-4{ @include grid(4, ($gutter * 0.5)); }
	.m-col-5{ @include grid(5, ($gutter * 0.5)); }
	.m-col-6{ @include grid(6, ($gutter * 0.5)); }
	.m-col-7{ @include grid(7, ($gutter * 0.5)); }
	.m-col-8{ @include grid(8, ($gutter * 0.5)); }
	.m-col-9{ @include grid(9, ($gutter * 0.5)); }
	.m-col-10{ @include grid(10, ($gutter * 0.5)); }
	.m-col-11{ @include grid(11, ($gutter * 0.5)); }
	.m-col-12{ @include grid(12); }
}

@include breaker.below($l){
.l-col-1 {
		@include grid(1);
		&.no--gutter{
			@include grid(1, $gutter:0px);
		}
	}
	.l-col-2 {
		@include grid(2);
		&.no--gutter{
			@include grid(2, $gutter:0px);
		}
	}
	.l-col-3 {
		@include grid(3);
		&.no--gutter{
			@include grid(3, $gutter:0px);
		}
	}
	.l-col-4 {
		@include grid(4);
		&.no--gutter{
			@include grid(4, $gutter:0px);
		}
	}
	.l-col-5 {
		@include grid(5);
		&.no--gutter{
			@include grid(5, $gutter:0px);
		}
	}
	.l-col-6 {
		@include grid(6);
		&.no--gutter{
			@include grid(6, $gutter:0px);
		}
	}
	.l-col-7 {
		@include grid(7);
		&.no--gutter{
			@include grid(7, $gutter:0px);
		}
	}
	.l-col-8 {
		@include grid(8);
		&.no--gutter{
			@include grid(8, $gutter:0px);
		}
	}
	.l-col-9 {
		@include grid(9);
		&.no--gutter{
			@include grid(9, $gutter:0px);
		}
	}
	.l-col-10 {
		@include grid(10);
		&.no--gutter{
			@include grid(10, $gutter:0px);
		}
	}
	.l-col-11 {
		@include grid(11);
		&.no--gutter{
			@include grid(11, $gutter:0px);
		}
	}
	.l-col-12 {
		@include grid(12);
		&.no--gutter{
			@include grid(12, $gutter:0px);
		}
	}
}



@include breaker.below($s){
	.col-1,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-10,
	.col-11,
	.col-12{
		width: 100%;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

.s-col-1 {
		@include grid(1);
		&.no--gutter{
			@include grid(1, $gutter:0px);
		}
	}
	.s-col-2 {
		@include grid(2);
		&.no--gutter{
			@include grid(2, $gutter:0px);
		}
	}
	.s-col-3 {
		@include grid(3);
		&.no--gutter{
			@include grid(3, $gutter:0px);
		}
	}
	.s-col-4 {
		@include grid(4);
		&.no--gutter{
			@include grid(4, $gutter:0px);
		}
	}
	.s-col-5 {
		@include grid(5);
		&.no--gutter{
			@include grid(5, $gutter:0px);
		}
	}
	.s-col-6 {
		@include grid(6);
		&.no--gutter{
			@include grid(6, $gutter:0px);
		}
	}
	.s-col-7 {
		@include grid(7);
		&.no--gutter{
			@include grid(7, $gutter:0px);
		}
	}
	.s-col-8 {
		@include grid(8);
		&.no--gutter{
			@include grid(8, $gutter:0px);
		}
	}
	.s-col-9 {
		@include grid(9);
		&.no--gutter{
			@include grid(9, $gutter:0px);
		}
	}
	.s-col-10 {
		@include grid(10);
		&.no--gutter{
			@include grid(10, $gutter:0px);
		}
	}
	.s-col-11 {
		@include grid(11);
		&.no--gutter{
			@include grid(11, $gutter:0px);
		}
	}
	.s-col-12 {
		@include grid(12);
		&.no--gutter{
			@include grid(12, $gutter:0px);
		}
	}
}

[class*="col-"]{
	float: left;
	&:last-child{
		margin-right: 0;
	}
}