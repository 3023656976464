@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.theme-cards {
      @supports (display: grid) {
            display:grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 40px;
            width:100%;
            margin-bottom:40px;

            @include breaker.below(grid.$m){
                  grid-column-gap:20px;
                  grid-template-columns: repeat(2, 1fr);
            }

            @include breaker.below(grid.$s){
                  grid-template-columns: repeat(1, 1fr);
            }
      }
}

.theme-card {
      text-decoration:none;
      color:inherit;

      @supports not (display:grid){
            margin-bottom:60px;
      }

      @supports (display: grid) {
            width:100%;
      }

      &__image {
            width:100%;
            height:auto;
            margin-bottom:30px;
      }

      &__title {
            @include mixins.parsefont(theme.$theme-heavy-font);
            font-size:34px;
            line-height:42px;
            color:theme.$black;
            margin-bottom:20px;
      }

      p {
            font-size:16px;
            line-height:24px;
      }

      &:hover {
            .theme-card__title {
                  text-decoration:underline;
            }
      }
}