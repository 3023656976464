@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.current-authors {
      background-color:theme.$theme-authors-bg-color;
      padding:40px 40px 0px 40px;
      margin-bottom:40px;

      @include breaker.below(grid.$s){
            margin-bottom:20px;
            padding:20px;
      }

      &__title a {
            color:inherit;
            text-decoration:none;
      }

      &__title {
            font-size:24px;
            line-height:28px;
            margin-bottom:40px;
            display:inline-block;

            @include breaker.below(grid.$s){
                  font-size:18px;
                  line-height:24px;
                  margin-bottom:30px;
            }

            @include mixins.linkHeaderHover(theme.$theme-authors-section-title);
      }

      .row {
            @supports (display: grid) {
                  display:grid;
                  grid-template-columns: repeat(6, 1fr);
                  grid-column-gap: 40px;
                  grid-row-gap: 40px;
                  width:100%;

                  @include breaker.below(1024px){
                        grid-template-columns: repeat(4, 1fr);
                        grid-column-gap: 10px;
                  }

                  @include breaker.below(grid.$s){
                        grid-template-columns: repeat(3, 1fr);
                  }

                  @include breaker.below(320px){
                        grid-template-columns: repeat(2, 1fr);
                  }
            }
      }

      &__author {
            margin-bottom:40px;

            @supports not (display:grid){
                  margin-bottom:40px;
            }

            @supports (display: grid) {
                  width:100%;
                  margin-right:0 !important;
            }

            &:hover {
                  .current-authors__author-name {
                        opacity:0.6;
                  }
            }
      }

      &__author-image {
            width:100%;
            height:auto;
            margin-bottom:24px;
            filter: grayscale(100%);
      }

      &__no-image {
            padding-bottom:150%;
            margin-bottom:24px;
            position:relative;

            span {
                  color:#000;
                  @include mixins.align-both(absolute);
                  font-size: 60px;
                  line-height: 60px;
                  text-transform:uppercase;
            }
      }

      &__author-name {
            color:theme.$white;
            text-decoration:underline;
            font-size:18px;
            line-height:20px;
            transition:opacity .2s ease-in-out;
      }
}