@use "theme/theme";
@use "breaker";
@use "grid";
@use "mixins";

.site-menu {
  max-width: 480px;
  width: 100%;
  background-color: theme.$theme-menu-bg;
  padding: 140px 40px 40px 40px;
  position: fixed;
  right: 0;
  top: 0;
  overflow-y: scroll;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.26, 0.44, 0.23, 0.88);
  z-index: 30;

  &--open {
    transform: translateX(0);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07);
  }

  &__hamburger {
    position: absolute;
    top: 40px;
    right: 40px;
    text-align: right;
    text-decoration: none;
    width: 40px;

    @include breaker.below(grid.$s) {
      width: 28px;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    &:hover .hamburger span {
      &:nth-child(4) {
        transform: rotate(0deg);
      }
    }

    .hamburger {
      height: 36px;
      width: 40px;
      margin-bottom: 6px;
      display: block;
      position: relative;

      @include breaker.below(grid.$s) {
        margin-bottom: 0;
        width: 28px;
        height: 28px;
      }

      span {
        height: 36px;
        width: 4px;
        background-color: theme.$theme-hamburger-color;
        position: absolute;
        top: 0;
        transition: all 0.2s ease-in-out;

        @include mixins.menuBarMinimized() {
          background-color: theme.$theme-hamburger-color-minimized;
        }

        @include breaker.below(grid.$s) {
          width: 3px;
          height: 28px;
        }

        &:nth-child(1) {
          right: 2px;

          @include breaker.below(grid.$s) {
            right: 0;
          }
        }

        &:nth-child(2) {
          right: 11px;

          @include breaker.below(grid.$s) {
            right: 7px;
          }
        }

        &:nth-child(3) {
          right: 20px;

          @include breaker.below(grid.$s) {
            right: 14px;
          }
        }

        &:nth-child(4) {
          right: 29px;
          transform: rotate(10deg);

          @include breaker.below(grid.$s) {
            right: 21px;
            transform: rotate(10deg);
          }
        }
      }

      &--small {
        height: 20px;
        width: 20px;
        float: right;
        margin-bottom: 5px;
        span {
          height: 20px;
          width: 3px;
        }
      }
    }

    .menu-text {
      text-align: center;
      width: 100%;

      color: theme.$theme-hamburger-color;

      @include mixins.menuBarMinimized() {
        color: theme.$theme-hamburger-color-minimized;
      }

      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1px;
      display: block;

      @include breaker.below(grid.$s) {
        display: none;
      }
    }

    &--open {
      .hamburger span {
        background-color: theme.$black;
        &:nth-child(1) {
          opacity: 0;
        }

        &:nth-child(2) {
          transform: rotate(-45deg);
          right: 7px;
          top: 0;
          transition-delay: 0.4s;
        }

        &:nth-child(3) {
          transform: rotate(45deg);
          top: 0;
          left: 10px;
          transition-delay: 0.2s;
        }

        &:nth-child(4) {
          opacity: 0;
        }
      }

      .menu-text {
        color: theme.$black;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.86px;
        display: inline-block;
      }
    }
  }

  &__login {
    position: absolute;
    right: 0;
    top: 89px;
    right: 153px;
    text-decoration: none;
    text-align: center;
    transition: all 1s;

    &__icon {
      width: 26px;
      height: 27px;
      margin: 0 auto;
      margin-bottom: 5px;
      background-image: theme.$theme-login-icon;

      &.invert {
        background-image: theme.$theme-login-icon-invert;
      }

      @include mixins.menuBarMinimized() {
        background-image: theme.$theme-login-icon-minimized;
      }

      @include breaker.below(grid.$s) {
        width: 19px;
        height: 19px;
        background-size: 19px 19px;
      }
    }

    .login-text {
      font-size: 10px;
      line-height: 14px;
      color: theme.$theme-hamburger-color;
      @include mixins.menuBarMinimized() {
        color: theme.$theme-hamburger-color-minimized;
      }
      display: block;
      text-align: center;

      @include breaker.below(grid.$s) {
        display: none;
      }
    }

    @include breaker.below(grid.$s) {
      width: 19px;
      height: 19px;
      right: 58px;
      @include mixins.vertical-align(absolute);

      img {
        width: 19px;
        height: 19px;
      }
    }
  }

  &__language {
    position: absolute;
    top: 40px;
    font-size: 16px;
    line-height: 24px;
    color: theme.$black;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }

  &__menu {
    list-style: none;
    margin-bottom: 50px;
  }

  &__links {
    text-decoration: none;
    color: theme.$black;
    font-size: 24px;
    line-height: 32px;
    position: relative;
    display: block;
    padding-bottom: 15px;
    margin-bottom: 15px;
    transition: color 0.2s ease-in-out;

    &:after {
      content: '';
      width: 100%;
      height: 4px;
      background-color: theme.$black;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &--sub {
      font-size: 18px;
      line-height: 26px;
      margin-left: 20px;

      &:after {
        background-color: #c7c7c7;
        height: 1px;
      }
    }

    &--current-item {
      color: rgba(theme.$black, 0.6);
    }

    &--open {
      &:after {
        background-color: #c7c7c7;
        height: 1px;
      }
    }

    &:hover {
      color: rgba(theme.$black, 0.45);
    }
  }

  &__sub-wrap {
    position: relative;
    margin-bottom: 15px;

    span {
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;

      &.open {
        &:after {
          opacity: 0;
        }
      }

      &:before {
        content: '';
        width: 4px;
        height: 18px;
        background-color: theme.$black;
        position: absolute;
        left: 50%;
        margin-left: -2px;
        transform: rotate(90deg);
        top: 16px;
        margin-top: -8px;
        transition: color 0.2s ease-in-out;
      }

      &:after {
        content: '';
        width: 4px;
        height: 18px;
        background-color: theme.$black;
        position: absolute;
        margin-top: -8px;
        top: 16px;
        left: 50%;
        margin-left: -2px;
        transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
      }

      &:hover {
        cursor: pointer;

        &:after,
        &:before {
          background-color: rgba(theme.$black, 0.6);
        }
      }
    }

    &--active-parent {
      .site-menu__links:after {
        background-color: #c7c7c7;
        height: 1px;
      }
      .site-menu__submenu {
        display: block;
        border-bottom: 4px solid #1c2024;
      }

      span {
        &:after {
          opacity: 0;
        }
      }
    }
  }

  &__submenu {
    list-style: none;
    display: none;

    li:last-child {
      a {
        margin-bottom: 0;
        &:after {
          display: none;
        }
      }
    }

    &--visible {
      display: block;
      border-bottom: 4px solid theme.$black;
    }
  }

  .cart-holder {
    position: relative;
  }

  &__cart {
    position: absolute;
    right: 0;
    top: 89px;
    right: 209px;
    text-decoration: none;
    text-align: center;
    transition: all 1s;

    &--added {
      .item-count {
        animation: expand;
        animation-duration: 0.4s;
      }
    }

    &__icon {
      width: 23px;
      height: 27px;
      margin: 0 auto;
      margin-bottom: 5px;
      background-image: theme.$theme-cart-icon;
      @include mixins.menuBarMinimized() {
        background-image: theme.$theme-cart-icon-minimized;
      }
      background-repeat: no-repeat;

      @include breaker.below(grid.$s) {
        width: 16px;
        height: 18px;
        background-size: 16px 18px;
      }
    }

    .login-text {
      font-size: 10px;
      line-height: 14px;
      color: theme.$theme-hamburger-color;
      @include mixins.menuBarMinimized() {
        color: theme.$theme-hamburger-color-minimized;
      }
      display: block;
      text-align: center;

      @include breaker.below(grid.$s) {
        display: none;
      }
    }

    .item-count {
      position: absolute;
      right: -4px;
      top: -10px;
      color: #1c2024;
      background-color: #f4f4f4;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      text-align: center;
      font-size: 10px;
      line-height: 20px;
      display: block;

      @include breaker.below(grid.$s) {
        width: 14px;
        height: 14px;
        line-height: 14px;
      }
    }

    @include breaker.below(grid.$s) {
      width: 19px;
      height: 19px;
      right: 98px;
      @include mixins.vertical-align(absolute);

      img {
        width: 19px;
        height: 19px;
      }
    }
  }

  &__returns {
    position: absolute;
    top: 89px;
    right: 277px;
    text-decoration: none;
    text-align: center;
    transition: all 1s;

    &--added {
      .item-count {
        animation: expand;
        animation-duration: 0.4s;
      }
    }

    &__icon {
      width: 22px;
      height: 27px;
      margin: 0 auto;
      margin-bottom: 5px;
      background-image: theme.$theme-returns-icon;
      background-repeat: no-repeat;

      @include breaker.below(grid.$s) {
        width: 16px;
        height: 18px;
        background-size: 16px 18px;
      }
    }

    .login-text {
      font-size: 10px;
      line-height: 14px;
      color: theme.$theme-hamburger-color;
      @include mixins.menuBarMinimized() {
        color: theme.$theme-hamburger-color-minimized;
      }
      display: block;
      text-align: center;

      @include breaker.below(grid.$s) {
        display: none;
      }
    }

    .item-count {
      position: absolute;
      right: -4px;
      top: -10px;
      color: #1c2024;
      background-color: #f4f4f4;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      text-align: center;
      font-size: 10px;
      line-height: 20px;
      display: block;

      @include breaker.below(grid.$s) {
        width: 14px;
        height: 14px;
        line-height: 14px;
      }
    }

    @include breaker.below(grid.$s) {
      width: 19px;
      height: 19px;
      right: 138px;
      @include mixins.vertical-align(absolute);

      img {
        width: 19px;
        height: 19px;
      }
    }
  }

  &__account {
    position: absolute;
    right: 0;
    top: 89px;
    right: 153px;
    text-decoration: none;
    text-align: center;
    transition: all 1s;

    &__icon {
      width: 26px;
      height: 27px;
      margin: 0 auto;
      margin-bottom: 5px;
      background-image: theme.$theme-account-icon;
      @include mixins.menuBarMinimized() {
        background-image: theme.$theme-account-icon-minimized;
      }

      @include breaker.below(grid.$s) {
        width: 19px;
        height: 19px;
        background-size: 19px 19px;
      }
    }

    .login-text {
      font-size: 10px;
      line-height: 14px;
      color: theme.$theme-hamburger-color;
      @include mixins.menuBarMinimized() {
        color: theme.$theme-hamburger-color-minimized;
      }
      display: block;
      text-align: center;

      @include breaker.below(grid.$s) {
        display: none;
      }
    }

    @include breaker.below(grid.$s) {
      width: 19px;
      height: 19px;
      right: 58px;
      @include mixins.vertical-align(absolute);

      img {
        width: 19px;
        height: 19px;
      }
    }
  }
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
