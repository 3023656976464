@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.checkout {
      background-color:#F4F4F4;
	padding:40px;
	margin-bottom:40px;

	@include breaker.below(600px){
		padding:20px;
	}

      @supports (display: grid) {
            display:grid;
            grid-template-columns: 67.96875% 28.90625%;
            grid-column-gap: 40px;
            grid-row-gap: 40px;
		width:100%;

		@include breaker.below(1100px){
			grid-template-columns:repeat(1, 1fr);
		}

		@include breaker.below(600px){
			grid-template-columns:repeat(1, 1fr);
			grid-column-gap: 20px;
		}
	}

	&__no-items {
		a {
			color:theme.$black;
			transition:opacity .2s ease-in-out;

			&:hover {
				opacity:0.6;
			}
		}
	}

	&__title {
		@include mixins.parsefont(theme.$theme-heavy-font);
		font-size:24px;
		line-height:28px;
		margin-bottom:30px;
	}

      &__total {
            background-color:#fff;
		padding:40px;
		margin-bottom:40px;

		@include breaker.below(600px){
			padding:20px;
			margin-bottom:20px;
		}

		ul {
			list-style:none;
			margin-bottom:30px;
		}

		li {
			padding-bottom:10px;
			margin-bottom:10px;
			border-bottom:1px solid #EBEBEB;
		}

		span {
			display:inline-block;
			font-size:16px;
			line-height:24px;
			margin-right:30px;
		}

		p {
			font-size:16px;
			line-height:24px;
			display:inline-block;
		}

		.button {
			width:100%;
			text-align:center;
		}

            table {
                  margin-bottom: 20px;

                  td {
                        vertical-align: top;
                        padding-right: 10px;
                        padding-bottom: 5px;

                        &:last-child {
                              padding-right: 0;
                        }
                  }
            }

            &__price {
                  white-space: nowrap;
            }

            &__sum {
                  white-space: nowrap;
                  margin-bottom: 20px;
            }

            &__info {
                  margin-top: 20px;
                  font-size: 14px;
                  line-height: 1.4em;

                  a {

                        color: theme.$black;
                        transition: opacity .2s ease-in-out;

                        &:hover {
                              opacity: 0.6;
                        }
                  }
            }
      }

      &__contact {
		background-color:#fff;
		padding:40px;

		@include breaker.below(600px){
			padding:20px;
		}

		&__section {
			padding-bottom:20px;
			margin-bottom:20px;
			border-bottom:1px solid #EBEBEB;
			ul {
				list-style:none;
			}

			span {
				font-size:12px;
				line-height:24px;
			}

			p {
				font-size:16px;
				line-height:28px;
			}
		}
	}

	&__items {
		background-color:#fff;
		padding:40px;

		@include breaker.below(600px){
			padding:20px;
		}
	}


	&__table {
		width:100%;

		.cover {
			@include breaker.below(grid.$m){
				display:none;
			}

			@include breaker.below(600px){
				display:block;
				width:100%;
				height:auto;
				margin-top:20px;

				img {
					width:100%;
					height:auto;
				}
			}

			img {
				max-width: 66px;
				max-height: 104px;
			}
		}

		th {
			text-align:left;
			font-size:16px;
			line-height:24px;
			@include mixins.parsefont(theme.$theme-heavy-font);
			padding-bottom:10px;
			border-bottom:1px solid theme.$black;

			&:last-child {
				text-align:right;
			}
		}

		thead {
			@include breaker.below(600px){
				display:none;
			}
		}

		tbody tr {
			border-bottom: 1px solid #EBEBEB;
			margin-bottom:20px;

			td {
				padding-bottom:20px;
				padding-top:20px;

				@include breaker.below(600px){
					display:block;
					width:100%;
					padding-top:0;
				}
			}
		}

		tr {

			td {
				vertical-align: bottom;

				a h2 {
					color:theme.$black;
					transition:opacity .2s ease-in-out;

					&:hover {
						opacity:0.6;
					}
				}

				h2 {
					@include mixins.parsefont(theme.$theme-semibold-font);
					font-size:16px;
					line-height:24px;
					margin-bottom:5px;
				}

				p {
					font-size:12px;
					line-height:24px;
					margin-bottom:5px;
				}

				span {
					font-size:12px;
					line-height:24px;
					display:block;
				}

				.remove-line {
					font-size:12px;
					color:theme.$black;
					transition:opacity .2s ease-in-out;

					&:hover {
						opacity:0.6;
					}
				}

				&:last-child {
					text-align:right;

					@include breaker.below(600px){
						text-align:left;
					}
				}
			}
		}

            input {
                  padding: 2px;
                  width: 100px;
            }
	}

	&__edit {
		.button {
			width:100%;
			text-align:center;
			margin-bottom:20px;
		}

		ul {
			list-style:none;
			text-align:center;
		}

		li {
			display:inline-block;
			margin: 0 10px;
			a {
				font-size:12px;
				line-height:24px;
				color:theme.$black;
				transition: opacity .2s ease-in-out;
				&:hover {
					opacity:0.6;
				}
			}
		}
	}

      &__extra-fields {
            margin-top: 30px;
            margin-bottom: 30px;

            &__field {
                  label {
                        display: block;
                        margin-bottom: 2px;

                        span {
                              margin-right: 0;
                              font-weight: 600;
                        }
                  }

                  input {
                        width: 100%;
                        padding: 2px;
                  }

                  margin-bottom: 20px;
            }
      }

      &__error {
            color: #cc0000;
            margin-bottom: 20px;
            width: 100%;
            text-align: center;
      }
}