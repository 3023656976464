@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

$roomIntroPaddingTop: 60px;
$roomIntroPaddingTopBelowS: 20px;
$roomIntroPatternMarginBottom: 80px;

%room-intro-top-fix {
      margin-top: -$roomIntroPaddingTop;

      @include breaker.below(grid.$s) {
            margin-top: -$roomIntroPaddingTopBelowS;
      }
}

.room-intro {
	padding:60px 0 0;
	max-width:1440px;
	width:100%;
      margin:0 auto;

      @include breaker.below(grid.$s){
            padding:30px 0 0;
      }

      &__logotype {
            text-align:center;
            margin-bottom:20px;

            @include breaker.below(grid.$s) {
                  margin-bottom: 10px;
            }

            img {
                  max-width:113px;
                  width:100%;
                  margin-bottom:20px;

                  @include breaker.below(grid.$s){
                        max-width:64px;
                  }
            }

            h1 {
                  font-size:60px;
                  font-weight: 700;

                  @include breaker.below(grid.$s) {
                        font-size: 36px;
                  }
            }

            @include breaker.below(grid.$s){
                  margin-bottom:$roomIntroPaddingTopBelowS;
            }
      }

      &__content {
            max-width:860px;
            width:100%;
            text-align:center;
            margin:0 auto;
            margin-bottom:60px;
            padding:0 20px;

            @include breaker.below(grid.$s){
                  margin-bottom:20px;
            }

            p {
                  font-size:18px;
                  line-height:28px;

                  @include breaker.below(grid.$s){
                        font-size:14px;
                        line-height:21px;
                  }
            }

            a {
                  color:theme.$black;
                  transition:opacity .2s ease-in-out;

                  &:hover {
                        opacity:0.6;
                  }
            }
      }

      &__news {
            max-width:420px;
            width:100%;
            margin:0 auto;
            margin-bottom:56px;
            text-align:center;

            @include breaker.below(grid.$s){
                  margin-bottom:10px;
            }

            p {
                  font-size:14px;
                  line-height:24px;

                  @include breaker.below(grid.$s){
                        font-size:12px;
                        line-height:18px;
                        padding:0 20px;
                  }
            }

            a {
                  color:theme.$black;
                  transition:opacity .2s ease-in-out;

                  &:hover {
                        opacity:0.6;
                  }
            }

            strong {
                  @include mixins.parsefont(theme.$theme-heavy-font);
            }
      }

      &__pattern {
            height:0;
            max-height:342px;
            padding-bottom:26%;
            margin-bottom: $roomIntroPatternMarginBottom;
            background-size:cover;
		background-repeat:no-repeat;
            background-position:center bottom;

            @include breaker.below(grid.$s){
                  padding-bottom:50%;
                  margin-bottom:40px;
            }

            &.top {
                  @extend %room-intro-top-fix;
            }
      }

      &__full-image {

            text-align: center;
            margin-bottom: $roomIntroPatternMarginBottom;
            @include breaker.below(grid.$s) {
                  margin-bottom: 20px;
            }
            img {
                  max-width: 100%;
                  vertical-align: top;

            }

            &.top {
                  @extend %room-intro-top-fix;
            }
      }
}