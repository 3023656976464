@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.contact {

      h1 {
            font-size:34px;
            line-height:42px;
            @include mixins.parsefont(theme.$theme-heavy-font);
            margin-bottom:20px;
      }

      &__intro {
            max-width:830px;
            width:100%;
            padding-bottom:40px;

            p {
                  font-size:16px;
                  line-height:28px;
                  margin-bottom:15px;

                  @include breaker.below(grid.$s){
                        font-size:16px;
                        line-height:26px;
                        margin-bottom:20px;
                  }
            }

            ul {
                  margin-bottom:30px;
                  list-style:none;

                  li {
                        font-size:16px;
                        line-height:28px;
                        margin-bottom:15px;
                        position:relative;
                        padding-left:20px;

                        @include breaker.below(grid.$s){
                              font-size:16px;
                              line-height:26px;
                              margin-bottom:10px;
                              padding-left:30px;
                        }

                        &:before {
                              content:'';
                              width:6px;
                              height:6px;
                              position:absolute;
                              top:11px;
                              left:0;
                              background-color:theme.$theme-site-standard-color;
                              border-radius:12px;
                        }
                  }
            }

            a {
                  color:theme.$black;
                  font-size:16px;
                  line-height:28px;

                  &:hover {
                        text-decoration:none;
                  }
            }
      }

      &__box {
            width:100%;
            display:inline-block;
            background-color:theme.$theme-button-bg-color;
            padding:70px;
            text-align:center;
            margin-bottom:60px;

            @include breaker.below(grid.$s){
                  padding:40px 20px;
            }

            @supports not (display:grid){
                  padding:70px 40px 30px;
            }

            @supports (display: grid) {
                  display:grid;
                  grid-template-columns: repeat(3, 1fr);
                  grid-column-gap: 30px;
                  grid-row-gap: 40px;
                  width:100%;

                  grid-row-end: 6;

                  @include breaker.below(1024px){
                        grid-template-columns: repeat(2, 1fr);
                  }

                  @include breaker.below(grid.$s){
                        grid-template-columns: repeat(1, 1fr);
                  }
            }

            &__col {

                  @supports not (display:grid){
                        margin-bottom:40px;
                  }

                  @supports (display: grid) {
                        width:100%;
                        margin-bottom:0 !important;
                        margin-right:0 !important;
                  }

                  h2 {
                        @include mixins.parsefont(theme.$theme-heavy-font);
                        color:theme.$theme-button-text-color;
                        font-size:24px;
                        line-height:34px;
                        margin-bottom:10px;
                  }

                  p {
                        color:theme.$theme-button-text-color;
                        font-size:18px;
                        line-height:30px;
                  }

                  a {
                        color:theme.$theme-button-text-color;
                        font-size:18px;
                        line-height:30px;
                  }

                  ul {
                        list-style:none;

                        li {
                              display:inline-block;
                              margin-right:10px;
                        }
                  }

                  &--some {
                        grid-column-end: -1;
                  }
            }
      }


      &__section {
            margin-bottom:60px;
            padding-bottom:40px;
            border-bottom:1px solid #EBEBEB;

            .row {
                  @supports (display: grid) {
                        display:grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-column-gap: 80px;
                        grid-row-gap: 40px;
                        width:100%;

                        @include breaker.below(1024px){
                              grid-template-columns: repeat(2, 1fr);
                        }

                        @include breaker.below(grid.$s){
                              grid-template-columns: repeat(1, 1fr);
                        }
                  }
            }

            &--last {
                  border-bottom:none;
                  padding-bottom:0;
            }

            &__title {
                  text-align:center;
                  font-size:24px;
                  line-height:28px;
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  margin-bottom:60px;
            }

            &__col {
                  text-align:center;

                  @supports not (display:grid){
                        margin-bottom:40px;
                  }

                  @supports (display: grid) {
                        width:100%;
                        margin-bottom:0 !important;
                        margin-right:0 !important;
                  }

                  img {
                        max-width:192px;
                        width:100%;
                        border-radius:192px;
                        margin-bottom:30px;
                        filter: grayscale(100%);

                        &.no-filter {
                              filter:none;
                        }
                  }

                  h2 {
                        @include mixins.parsefont(theme.$theme-heavy-font);
                        font-size:24px;
                        line-height:28px;
				margin-bottom:20px;

				a {
					font-size:24px;
					line-height:28px;
					margin-bottom:20px;
					transition:opacity .2s ease-in-out;

					&:hover {
						opacity:0.6;
					}
				}
                  }

                  p {
                        font-size:16px;
                        line-height:26px;
                        margin-bottom:20px;
                  }

                  a {
                        font-size:16px;
                        line-height:26px;
                        color:theme.$black;
                  }
            }
      }
}