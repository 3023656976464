@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.manus-box {
      padding:80px;
      background-color:theme.$theme-send-manuscript-bg-color;
      text-align:center;
      margin-bottom:100px;

      @include breaker.below(grid.$s){
            padding:40px 20px;
            margin-bottom:60px;
      }

      span {
            text-transform:uppercase;
            font-size:12px;
            line-height:30px;
            letter-spacing:4px;
            color:theme.$theme-site-standard-color;
            margin-bottom:18px;
            padding-bottom:20px;
            position:relative;
            display:block;

            &:after {
                  content:'';
                  width:30px;
                  height:4px;
                  background-color:theme.$theme-site-standard-color;
                  @include mixins.horizontal-align(absolute);
                  bottom:0;
            }
      }

      h2 {
            max-width:636px;
            width:100%;
            margin:0 auto;
            font-size:28px;
            line-height:38px;
            @include mixins.parsefont(theme.$theme-heavy-font);
            margin-bottom:20px;

            @include breaker.below(grid.$s){
                  font-size:24px;
                  line-height:28px;
            }
      }

      p {
            max-width:714px;
            width:100%;
            margin:0 auto;
            font-size:16px;
            line-height:28px;
            margin-bottom:40px;
      }
}