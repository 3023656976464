@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.lib-instagram {
	margin-bottom:80px;

	@include breaker.below(grid.$s){
		margin-bottom:40px;
	}

      h2 {
            font-size:24px;
            line-height:34px;
		margin-bottom:60px;

		@include breaker.below(grid.$s){
			font-size:18px;
			line-height:24px;
			margin-bottom:40px;
		}
      }

      &__grid {
            @supports (display: grid) {
                  display:grid;
                  grid-template-columns: repeat(6, 1fr);
                  width:100%;
                  grid-column-gap: 6.6176470588%;
			grid-row-gap:40px;

			@include breaker.below(980px){
				grid-template-columns: repeat(4, 1fr);
			}

			@include breaker.below(600px){
				grid-template-columns:repeat(3, 1fr);
				grid-column-gap: 20px;
				grid-row-gap: 20px;
			}

			@include breaker.below(grid.$s){
				grid-template-columns:repeat(2, 1fr);
				grid-column-gap: 20px;
				grid-row-gap: 20px;
			}
            }
      }

      &__col {
            text-align:center;

            img {
                  width:100%;
                  border-radius:50%;
                  margin-bottom:25px;
            }

            a {
                  color:theme.$black;
                  margin-bottom:25px;
                  display:block;
                  font-size:18px;
			line-height:24px;

			@include breaker.below(grid.$s){
				font-size:14px;
				line-height:24px;
				margin-bottom:10px;
			}
            }

            p {
                  font-size:18px;
			line-height:24px;
			@include breaker.below(grid.$s){
				font-size:14px;
				line-height:24px;
			}
            }
      }
}