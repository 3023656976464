@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.top-section__extended {
      max-width:1440px;
      width:100%;
      margin: 0 auto;
      margin-bottom:60px;
      background-image:theme.$theme-intro-background-image;
      background-repeat:no-repeat;
      background-color:theme.$theme-intro-background-color;
      background-size:cover;
      background-position:center top;
      height:620px;
      position:relative;
      padding:180px 20px 0 20px;
      color:theme.$theme-intro-bonnier-text-color;

      @include breaker.below(grid.$s){
            padding-top:130px;
            height:auto;
      }

      &__intro {
            text-align:center;
            max-width:760px;
            width:100%;
            margin:0 auto;

            @include breaker.below(grid.$s){
                  padding:0 20px;
            }

            h1 {
                  font-size:46px;
                  line-height:59px;
                  margin-bottom:120px;
                  color: inherit;

                  @include breaker.below(grid.$s){
                        font-size:44px;
                        line-height:52px;
                        margin-bottom:20px;
                  }
            }

            h2 {
                  text-transform:uppercase;
                  font-size:16px;
                  line-height:16px;
                  margin-bottom:50px;
                  color: inherit;

                  @include breaker.below(grid.$s){
                        margin-bottom:20px;
                  }
            }
      }

      &__readmore {
            display:block;
            width:100%;
            max-width:800px;
            margin: 0 auto;
            text-align:center;

            @include breaker.below(grid.$s){
                  padding:0 20px;
            }

            p {
                  font-size:16px;
                  line-height:24px;
            }

            a {
                  color: inherit;
            }
      }

      &__bonnier {
            text-align:right;
            position:absolute;
            bottom:40px;
            right:40px;

            @include breaker.below(1330px){
                  right:40px;
            }

            @include breaker.below(grid.$m){
                  display:none;
            }

            span {
                  color:theme.$theme-intro-bonnier-text-color;
                  font-size:16px;
                  line-height:24px;

                  strong {
                        @include mixins.parsefont(theme.$theme-heavy-font);
                  }

                  a {
                        color:theme.$theme-intro-bonnier-text-color;
                        text-decoration:none;
                        &:hover {
                              text-decoration:underline;
                        }
                  }
            }
      }
}