@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.news {
      padding:40px;
      background-color:theme.$theme-news-background-color;
      margin-bottom:60px;
      z-index:2;
      position:relative;
      max-width:1360px;
      width:100%;
      margin:0 auto;
      margin-bottom:60px;

      &.title-text-image {

            .news__item {
                  margin-bottom: 0;
            }
      }

      @include breaker.below(grid.$s){
            padding:20px;
            margin-bottom:40px;
      }

      &__section-title {
            font-size:24px;
            line-height:28px;
            margin-bottom:40px;
            display:inline-block;
            text-decoration:none;

            @include breaker.below(grid.$m){
                  font-size:18px;
                  line-height:24px;
                  margin-bottom:20px;
            }

            @include mixins.linkHeaderHover(theme.$theme-news-section-title-color);

      }


      &__grid {
            display:grid;
            width:100%;
            margin-bottom:40px;

            @supports (display: grid) {
                  display:grid;
                  grid-template-columns: repeat(3, 1fr);
                  grid-column-gap: 40px;
                  grid-row-gap: 40px;
                  width:100%;

                  @include breaker.below(grid.$m){
                        grid-column-gap:20px;
                        grid-template-columns: repeat(2, 1fr);
                  }

                  @include breaker.below(grid.$s){
                        grid-template-columns: repeat(1, 1fr);
                  }
            }

            .news__item {
                  @supports not (display:grid){
                        margin-bottom:40px;
                  }

                  @supports (display: grid) {
                        width:100%;
                        margin-bottom:0 !important;
                        margin-right:0 !important;
                  }
            }
      }

      &__item {
            margin-bottom:40px;

            &--featured {
                  .grid {
                        @supports (display: grid) {
                              display:grid;
                              grid-template-columns: repeat(2, 1fr);
                              grid-column-gap: 40px;
                              grid-row-gap: 40px;
                              width:100%;

                              @include breaker.below(grid.$m){
                                    grid-template-columns: repeat(1, 1fr);
                                    grid-row-gap: 20px;
                              }
                        }
                  }

                  .news__title {
                        font-size:38px;
                        line-height:48px;
                        margin-bottom:20px;
                        color:theme.$theme-news-title-color;
                        @include mixins.parsefont(theme.$theme-heavy-font);

                        @include breaker.below(grid.$m){
                              font-size:24px;
                              line-height:32px;
                              margin-bottom:0;
                        }
                  }

                  .news__image {
                        margin-bottom:0;
                  }
            }

            &:hover {
                  .news__title {
                        text-decoration:underline;
                  }

                  .news__arrow {
                        transform:translateX(10px);
                  }

                  .news__image img{
                        transform:scale(1.05);
                  }
            }
      }

      &__excerpt {
            font-size:16px;
            line-height:26px;

            @include breaker.below(grid.$m){
                  display:none;
            }
      }

      &__item a {
            color:inherit;
            text-decoration:none;
            display:inline-block;
            width:100%;
      }

      &__image {
            width:100%;
            height:auto;
            margin-bottom:20px;
            overflow:hidden;
      }

      &__image img {
            transition: transform 1s ease-in-out;
      }

      &__date {
            font-size:14px;
            line-height:22px;
            letter-spacing:1px;
            display:block;
            margin-bottom:10px;
            color:theme.$theme-news-title-color;
      }

      &__publisher {
            font-size:14px;
            line-height:22px;
            letter-spacing:1px;
            display:block;
      }

      &__title {
            font-size:22px;
            line-height:28px;
            color:theme.$theme-news-title-color;
            @include mixins.parsefont(theme.$theme-heavy-font);

            @include breaker.below(grid.$m){
                  font-size:24px;
                  line-height:32px;
            }
      }

      &__arrow {
            margin-top:10px;
            transition:transform .2s ease-in-out;
            width:19px;
            height:21px;
            display:block;
            background-image:theme.$theme-news-arrow-color;
            background-repeat:no-repeat;
            background-position:center center;
      }

      &__all-news-link {
            display:flex;



            a {
                  margin-left:auto;
                  color:theme.$theme-news-section-title-color;

                  @include breaker.below(grid.$m){
                        margin-left:0;
                  }

                  &:hover {
                        text-decoration: none;
                  }
            }
      }
}