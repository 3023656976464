@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.halloween {
      background-image: url("../img/blood.png");
      background-repeat: no-repeat;
      background-position-x: center;
      background-size: cover;
      height: 242px;
      max-width: 1264px;
      width: 100%;
      pointer-events: none;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;


      @include breaker.below(grid.$s) {
            height: 140px;
      }

}