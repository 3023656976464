@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.textblock-image {
      margin-bottom:80px;

      @include breaker.below(grid.$s){
            margin-bottom:60px;
      }

      .row {
            max-width:1040px;
            width:100%;
            margin:0 auto;

            &--small {
                  @supports (display: grid) {
                        display:grid;
                        grid-template-columns: 50% 35.1923076923%;
                        grid-column-gap: 14.8076923077%;
                        align-items:center;

                        @include breaker.below(grid.$s){
                              grid-template-columns: repeat(1, 1fr);
                              grid-row-gap: 20px;
                        }
                  }
            }

            &--large {
                  @supports (display: grid) {
                        display:grid;
                        grid-template-columns: 58.6538461538% 30.2884615385%;
                        grid-column-gap: 11.0576923077%;
                        align-items:center;

                        @include breaker.below(grid.$s){
                              grid-template-columns: repeat(1, 1fr);
                              grid-row-gap: 20px;
                        }
                  }
            }

            &--full-width {
                  @supports (display: grid) {
                        display:grid;
                        grid-template-columns: 80%;
                        align-items:center;
                        justify-content:center;
                  }

                  @include breaker.below(grid.$s){
                        grid-template-columns: 100%;
                  }
            }

      }

      &__text {
            @include breaker.below(grid.$s){
                  order:1;
            }
            span {
                  position:relative;
                  font-size:12px;
                  line-height:30px;
                  letter-spacing:4px;
                  text-transform:uppercase;
                  color:theme.$theme-site-standard-color;
                  padding-left:50px;
                  margin-bottom:18px;
                  display:block;

                  &:before {
                        content:'';
                        width:30px;
                        height:4px;
                        background-color:theme.$theme-site-standard-color;
                        @include mixins.vertical-align(absolute);
                        left:0;
                  }
            }

            h1,h2 {
                  font-size:36px;
                  line-height:41px;
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  margin-bottom:15px;
            }

            h3 {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:24px;
                  line-height:36px;
                  margin-bottom:15px;
            }

            h4 {
                  @include mixins.parsefont(theme.$theme-regular-font);
                  font-size: 20px;
                  line-height: 27px;
                  margin-bottom:15px;
            }

            p {
                  font-size:16px;
                  line-height:28px;
                  margin-bottom:15px;

                  @include breaker.below(grid.$s){
                        font-size:16px;
                        line-height:26px;
                        margin-bottom:20px;
                  }
            }

            blockquote p {
                  font-size: 24px;
                  line-height: 38px;
                  @include mixins.parsefont(theme.$theme-regular-font);
                  margin-bottom:15px;
            }

            ul {
                  margin-bottom:30px;
                  list-style:none;

                  li {
                        font-size:16px;
                        line-height:28px;
                        margin-bottom:15px;
                        position:relative;
                        padding-left:20px;

                        @include breaker.below(grid.$s){
                              font-size:16px;
                              line-height:26px;
                              margin-bottom:10px;
                              padding-left:30px;
                        }

                        &:before {
                              content:'';
                              width:6px;
                              height:6px;
                              position:absolute;
                              top:11px;
                              left:0;
                              background-color:theme.$theme-site-standard-color;
                              border-radius:12px;
                        }
                  }
            }

            a {
                  color:theme.$black;
                  font-size:16px;
                  line-height:28px;

                  &:hover {
                        text-decoration:none;
                  }
            }

            strong {
                  @include mixins.parsefont(theme.$theme-heavy-font);
            }

            &--large {
                  p {
                        font-size:20px;
                        line-height:30px;
                        margin-bottom:20px;

                        @include breaker.below(grid.$s){
                              font-size:16px;
                              line-height:26px;
                              margin-bottom:20px;
                        }
                  }

                  a {
                        color:theme.$black;
                        font-size:20px;
                        line-height:30px;
                  }
            }
      }

      &__image {
            position:relative;

            &--small {
                  &:after {
                        content:'';
                        width:100%;
                        height:100%;
                        position:absolute;
                        left:20px;
                        top:20px;
                        background-color:theme.$theme-article-image-shadow-color;

                        @include breaker.below(1024px){
                              left:20px;
                              top:20px;
                        }

                        @include breaker.below(grid.$s){
                              left:10px;
                              top:10px;
                        }
                  }

                  img {
                        max-width:366px;
                        width:100%;
                        height:auto;
                        z-index:2;
                        position:relative;



                        @include breaker.below(grid.$s){
                              max-width:100%;
                        }
                  }

                  .photo-cred {
                        z-index: 3;
                  }
            }

            &--large {
                  &:after {
                        content:'';
                        width:100%;
                        height:100%;
                        position:absolute;
                        left:20px;
                        top:20px;
                        background-color:theme.$theme-article-image-shadow-color;

                        @include breaker.below(1024px){
                              left:20px;
                              top:20px;
                        }

                        @include breaker.below(grid.$s){
                              left:10px;
                              top:10px;
                        }
                  }

                  img {
                        max-width:610px;
                        width:100%;
                        height:auto;
                        z-index:2;
                        position:relative;

                        @include breaker.below(1024px){

                        }

                        @include breaker.below(grid.$s){
                              max-width:100%;
                        }
                  }
            }
      }

      body.with-spotin & {
            &__image {
                  &--small,
                  &--large {
                        &:after {
                              display: none;
                        }
                  }

            }
      }
}

