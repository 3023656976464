@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.current-trailers {
	margin-bottom:80px;

	@include breaker.below(grid.$s){
		margin-bottom:40px;
	}

      &__grid {
            @supports (display: grid) {
                  display:grid;
                  grid-template-columns: repeat(3, 1fr);
                  width:100%;
                  grid-column-gap: 40px;
			grid-row-gap:40px;

			@include breaker.below(grid.$s){
				grid-template-columns: repeat(2, 1fr);
				grid-column-gap: 20px;
			}
            }
      }

      &__title {
            font-size:24px;
            line-height:34px;
            color:theme.$black;
            margin-bottom:40px;
            display:inline-block;
            transition:opacity .2s ease-in-out;

		@include breaker.below(grid.$s){
			font-size:18px;
			line-height:24px;
		}
      }

      &__col {
            a {
                  color:theme.$black;
                  text-decoration:none;

                  &:hover {
                        h3 {
                              opacity:0.6;
                        }

                        .current-trailers__video:after {
                              opacity:0.6;
                        }
                  }
            }

            h3 {
                  font-size:18px;
                  line-height:26px;
                  transition:opacity .2s ease-in-out;
			text-decoration:underline;

			@include breaker.below(grid.$s){
				font-size:14px;
				line-height:24px;
			}
            }

            p {
                  text-decoration:none;
                  font-size:18px;
			line-height:26px;

			@include breaker.below(grid.$s){
				font-size:14px;
				line-height:24px;
			}
            }
      }

      &__video {
            position:relative;
            margin-bottom:19px;

            img {
                  width:100%;
                  max-width:427px;
            }

            &:before {
                  content:'';
                  position:absolute;
                  top:0;
                  left:0;
                  right:0;
                  bottom:0;
                  background-color:rgba(theme.$black, 0.5);
            }

            &:after {
                  content:'';
                  width:51px;
                  height:36px;
                  @include mixins.align-both(absolute);
                  background-image:url('../img/icon-play.svg');
                  background-repeat:no-repeat;
                  transition:opacity .2s ease-in-out;
            }
      }
}