@use "breaker";
@use "grid";

.publisher-bar {
      position:fixed;
      top:0;
      left:0;
      right:0;
      z-index:30;

      &__bg {
            background-color: #222222;
            &__transparent {
                  background-color:rgba(34, 34, 34, 0.95);
            }
      }

      &__content {
            padding:10px;
            .wrapper {
                  display: flex;
            }
            p {
                  font-family:"freight-sans-pro";
                  font-weight:bold;
                  color:#fff;
                  display:flex;
                  margin-left: auto;

                  @include breaker.below(grid.$s){
                        font-size:12px;
                  }

                  &:hover {
                        cursor:pointer;
                        text-decoration: underline;
                  }

                  svg {
                        align-self:center;
                        margin-left:10px;
                  }

                  span {
                        padding-left:4px;
                        font-weight:500;
                  }
            }
      }

      &__publishers {
            display:flex;
            flex-direction: row;
            flex-wrap:wrap;
            padding:40px 0 30px 0;

            margin:0 -20px;

            @include breaker.below(grid.$s){
                  height:100vh;
                  overflow:scroll;
            }

            div {
                  padding:0 20px;
                  width:12.5%;
                  text-align:center;
                  margin-bottom:20px;
                  @include breaker.below(grid.$m){
                        width:33.3%;
                  }

                  a {
                        color:#fff;
                        display: block;

                        &:hover {
                              text-decoration: none;
                        }
                  }

                  p {
                        font-size:14px;
                        line-height:20px;
                        font-weight:600;
                  }

                  img {
                        max-width:70px;
                        width:100%;
                        height:auto;
                        margin-bottom:20px;
                  }
            }
      }
}