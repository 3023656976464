@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.rentauthor {
      margin-bottom:80px;

      @include breaker.below(grid.$s){
            margin-bottom:20px;
      }

      &__title {
            font-size: 24px;
            line-height: 28px;
            text-decoration: underline;
            margin-bottom: 40px;

            @include breaker.below(grid.$s){
                  font-size: 18px;
                  line-height: 24px;
                  margin-bottom: 40px;
            }
      }

      &__intro {
            margin-top:-20px;
            margin-bottom:60px;
            max-width:620px;
            @include breaker.below(grid.$s){
                  margin-bottom:40px;

                  p {
                        font-size:14px;
                        line-height:24px;
                  }
            }

            a {
                  color:theme.$black;
                  transition:opacity .2s ease-in-out;
                  &:hover {
                        opacity:0.6;
                  }
            }
      }

      &__grid {
            @supports (display: grid) {
                  display:grid;
                  grid-template-columns:35.294% 63.236%;
                  grid-column-gap:20px;
                  align-items:center;

                  @include breaker.below(grid.$m){
                        grid-template-columns:100%;
                        grid-row-gap:20px;
                  }
		}
      }

      &__logotype {
            text-align:center;

            a {
                  display:inline-block;
            }

            img {
                  max-width:230px;
                  width:100%;
                  height:auto;
            }
      }

      &__content {
            p {
                  font-size:24px;
                  line-height:34px;
                  margin-bottom:40px;

                  @include breaker.below(grid.$s){
                        font-size:18px;
                        line-height:27px;
                  }

                  a {
                        color:theme.$black;
                        transition:opacity .2s ease-in-out;
                        &:hover {
                              opacity:0.6;
                        }
                  }
            }
      }

      &__links {
            display:inline-block;
            width:100%;
            a {
                  background-color:theme.$black;
                  color:#fff;
                  font-size:16px;
                  line-height:24px;
                  text-decoration:none;
                  padding:10px 20px;
                  float:left;
                  margin-right:10px;
                  margin-bottom:10px;

                  @include breaker.below(grid.$s){
                        font-size:14px;
                        line-height:22px;
                  }

                  &:hover {
                        text-decoration:underline;
                  }
            }
      }
}