@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.password-protected {
      max-width: 700px;
      margin: 0 auto;
      padding: 80px 0;

      input[type=submit] {
            border: none;
            appearance: none;
            width: 100%;
            font-size: 16px;
            line-height: 26px;
            padding: 12px 0;
            text-align: center;
            background-color: theme.$theme-button-bg-color;
            color: theme.$theme-button-text-color;
            outline: none;
            border-radius: 8px;
            transition: opacity .2s ease-in-out;
            position: relative;
            overflow: hidden;
            text-decoration: none;
            margin: 10px 0;

            em {
                  font-style: normal;
                  transition: color .2s ease-in-out;
                  line-height: 26px;
            }

            svg {
                  /* this is the check icon */
                  width: 64px;
                  height: 64px;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  width: 100%;
                  /* move the icon on the right - outside the button */
                  transform: translateX(50%) translateY(-50%);
            }

            &.animate {
                  pointer-events: none;
                  em {
                        color: transparent;
                        transform: translateX(-100%);
                  }

                  svg {
                        transform: translateX(-50%) translateY(-50%);
                        stroke-dashoffset: 0;
                  }
            }

            &:hover {
                  text-decoration: underline;
            }
      }

      input[type="password"]{
            width:100%;
            border:none;
            background-color:#F4F4F4;
            height:44px;
            padding:0 40px 0 20px;
            outline:none;
            border-bottom:2px solid rgba(28, 32, 36, 0.2);
            transition:border-bottom .2s ease-in-out;
            margin-top: 10px;

            &:focus {
                  border-bottom:2px solid rgba(28, 32, 36, 1);
            }
      }

      p {
            margin-bottom: 5px;
      }
}