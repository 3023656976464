@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.sales-materials {

      &__section-title {
            font-size:24px;
            line-height:34px;
		margin-bottom:60px;


		@include breaker.below(grid.$s){
			font-size:18px;
			line-height:24px;
			margin-bottom:40px;
		}

            a {
                  color:theme.$black;
                  transition:opacity .2s ease-in-out;

                  &:hover {
                        opacity:0.6;
                  }
            }
      }

      &__columns {
            padding-bottom:80px;
            margin-bottom:80px;
		border-bottom:1px solid #EBEBEB;

		@include breaker.below(grid.$s){
			padding-bottom:40px;
			margin-bottom:40px;
		}

            @supports (display: grid) {
                  display:grid;
                  grid-template-columns: repeat(6, 1fr);
                  grid-column-gap: 40px;
			width:100%;

			@include breaker.below(grid.$s){
				grid-template-columns:repeat(3, 1fr);
				grid-row-gap:40px;
			}
            }
      }

      &__column {
            text-align:center;
            text-decoration:none;
            transition:opacity .2s ease-in-out;

            img {
                  max-width:128px;
                  width:100%;
                  border-radius:64px;
			margin-bottom:24px;

			@include breaker.below(grid.$s){
				max-width:100%;
				margin-bottom:10px;
			}
            }

            h2 {
                  font-size:18px;
                  line-height:24px;
			color:theme.$black;

			@include breaker.below(grid.$s){
				font-size:14px;
				line-height:24px;
			}
            }

            &:hover {
                  opacity:0.6;
            }
      }
}