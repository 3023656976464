@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.cart {
      background-color:#F4F4F4;
      padding:40px;

      &__books {
            background-color:theme.$white;
            padding:30px;

            &__title {
                  font-size:24px;
                  line-height:28px;
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  margin-bottom:30px;
            }


            table {
                  width:100%;
                  box-sizing:content-box;

                  thead:after {
                        content:'';
                        display:block;
                        padding-bottom:10px;
                  }

                  th {
                        text-align:left;
                        font-size:16px;
                        line-height:24px;
                  }

                  td {
                        h2 {
                              font-size:16px;
                              line-height:24px;
                              margin-bottom:5px;
                        }

                        span {
                              display:block;
                              font-size:12px;
                              line-height:24px;
                        }
                  }
            }
      }

      &__total {
            margin-bottom:40px;
            padding:30px;
            background-color:theme.$white;

            h2 {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:24px;
                  line-height:28px;
                  margin-bottom:30px;
            }

            &__row {
                  margin-bottom:10px;
                  padding-bottom:10px;
                  border-bottom:1px solid #EBEBEB;
                  p {
                        display:inline-block;
                        font-size:16px;
                        line-height:24px;
                  }

                  span {
                        font-size:16px;
                        line-height:24px;
                        margin-right:25px;
                  }
            }

            .button {
                  margin-top:20px;
                  display:block;
            }
      }


      &__records {
            padding:30px;
            background-color:theme.$white;
            h2 {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:24px;
                  line-height:28px;
                  margin-bottom:30px;
            }

            section {
                  border-bottom:1px solid #EBEBEB;
                  padding-bottom:10px;
                  margin-bottom:20px;
            }

            span {
                  @include mixins.parsefont(theme.$theme-regular-font);
                  font-size:12px;
                  line-height:24px;
            }

            p {
                  font-size:16px;
                  line-height:28px;
                  margin-bottom:10px;
            }

            &__options {
                  margin-top:20px;
                  a {
                        color:theme.$black;
                        font-size:12px;
                        line-height:24px;
                        margin-right:20px;
                  }
            }

            .button {
                  margin-top:10px;
            }
      }
}