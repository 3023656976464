@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.book-columns {
      width:100%;

      @supports (display: grid) {
            display:grid;
            grid-column-gap: 40px;
            grid-row-gap: 40px;
            align-items:end;

            &--two {
                  grid-template-columns: repeat(2, 1fr);
            }

            &--three {
                  grid-template-columns: repeat(3, 1fr);
            }

            &--four {
                  grid-template-columns: repeat(4, 1fr);
            }

            &--five {
                  grid-template-columns: repeat(5, 1fr);
            }

            &--six {
                  grid-template-columns: repeat(6, 1fr);
            }

            @include breaker.below(1024px){
                  grid-template-columns: repeat(4, 1fr);
                  grid-column-gap: 10px;
            }

            @include breaker.below(grid.$s){
                  grid-template-columns: repeat(3, 1fr);
            }

            @include breaker.below(320px){
                  grid-template-columns: repeat(2, 1fr);
            }
      }

      &__item {
            overflow:hidden;
            position:relative;

            &--year {
                  padding-bottom:21px;
            }
      }

      &__book-link {
            color:inherit;

            &:hover {
                  .book-columns__title {
                        opacity:0.6;
                  }
            }
      }

      &__cover {
            margin-bottom:20px;

            img {
                  width:100%;
                  height:auto;
            }
      }

      &__title {
            text-decoration: underline;
            font-size: 16px;
            line-height: 24px;
            transition: opacity .2s ease-in-out;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            transition:opacity .2s ease-in-out;
      }

      &__author-wrap {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
      }

      &__book-authors {
            font-size: 16px;
            line-height: 24px;
            color: #a9a9a9;
            transition: opacity .2s ease-in-out;
            white-space: nowrap;

            &:hover {
                  opacity:0.6;
            }

            &--no-link {
                  &:hover {
                        opacity:1;
                  }
            }
      }

      &__book-price {
            font-size: 0.8em;
      }

      &__year {
            position:absolute;
      }
}