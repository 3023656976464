@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.iframe {
      margin-bottom:80px;

      @include breaker.below(grid.$s){
            margin-bottom:40px;
      }

      h2 {
            font-size: 24px;
            line-height: 28px;
            text-decoration: underline;
            margin-bottom: 40px;
      }

      iframe {
            width: 100%;
            height: 500px; /* May be overridden by js event from iframe source */
      }
}