@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.contact-splash {
      padding:40px;
      background-color: theme.$theme-contact-puff-bg;
      margin-bottom:40px;

      @include breaker.below(grid.$s){
            padding:20px;
      }

      .icon,
      img {
            display:inline-block;
            margin-right:40px;
            margin-top:5px;

            @include breaker.below(grid.$s){
                  margin-top:0;
                  margin-bottom:20px;
            }
      }

      p {
            font-size:32px;
            line-height:32px;
            color:theme.$theme-contact-puff-color;
            display:inline-block;

            @include breaker.below(grid.$s){
                  font-size:24px;
                  line-height:36px;
            }
      }

      a {
            text-decoration:none;

            @include mixins.linkHeaderHover(theme.$theme-contact-puff-color);
      }
}