.mt-20 {
      margin-top: 20px;
}

.ml-10 {
      margin-left: 10px;
}

.ml-20 {
      margin-left: 20px;
}

.mb-40 {
      margin-bottom: 40px;
}