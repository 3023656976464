@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.release-message {
      text-align:center;
      margin-bottom:80px;
      max-width:840px;
      width:100%;
      margin: 0 auto;
      margin-bottom:80px;

      &__header {
            text-decoration:underline;
            font-size:24px;
            line-height:28px;
            margin-bottom:80px;
      }

      &__content {
            color:inherit;
            text-decoration:none;
            max-width:630px;
            width:100%;
            margin:0 auto;
            display:block;
      }

      &__image {
            max-width:840px;
            width:100%;
            margin-bottom:40px;
      }

      &__date {
            font-size:14px;
            line-height:28px;
            letter-spacing:1px;
            display:block;
            margin-bottom:10px;
      }

      &__title {
            font-size:48px;
            line-height:60px;
            @include mixins.parsefont(theme.$theme-heavy-font);
            margin-bottom:20px;
      }

      p {
            font-size:16px;
            line-height:26px;
      }

      a {
            position:relative;
            color:inherit;
            text-decoration:none;
            display:block;
            padding-bottom:51px;

            &:hover {
                  .release-message__title {
                        text-decoration:underline;
                  }

                  &:after {
                        transform:translateX(10px);
                  }
            }

            &:after {
                  content:'';
                  width:19px;
                  height:21px;
                  background-image:url('../img/icon-arrow-right.svg');
                  background-size:19px 21px;
                  background-repeat:no-repeat;
                  @include mixins.horizontal-align(absolute);
                  bottom:0px;
                  transition:transform .2s ease-in-out;
            }
      }
}