@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.my-orders {
      margin-bottom:40px;
      padding:40px;
      background-color:#F4F4F4;

      @include breaker.below(grid.$s){
            padding:20px;
      }

      &__holder {
            padding:40px;
            background-color:theme.$white;

            @include breaker.below(grid.$s){
                  padding:20px;
            }
      }

      &__title {
            @include mixins.parsefont(theme.$theme-heavy-font);
            text-align:center;
            font-size:36px;
            line-height:42px;
            padding-bottom:40px;
            margin-bottom:23px;
            border-bottom:1px solid #EBEBEB;

            @include breaker.below(grid.$s){
                  font-size: 24px;
                  line-height: 31px;
                  margin-bottom:20px;
                  padding-bottom:20px;
            }
      }

      &__table-header {
            border-bottom:1px solid theme.$black;
            display:flex;
            flex-direction: row;
            justify-content:space-between;

            div {
                  width:25%;
            }

            .align-right {
                  text-align:right;
            }

            p {
                  @include mixins.parsefont(theme.$theme-regular-font);
                  font-size:16px;
                  line-height:24px;
                  margin-bottom:10px;
            }
      }

      &__table-body {
            .align-right {
                  text-align:right;
            }

            p {
                  @include mixins.parsefont(theme.$theme-semibold-font);
                  font-size:16px;
                  line-height:28px;
            }
      }

      &__table-row {
            padding:20px 0 15px;
            border-bottom:1px solid #EBEBEB;
            display:flex;
            flex-direction: row;
            justify-content:space-between;

            div {
                  width:25%;
            }
      }



      section {
            width:100%;
            overflow-y:scroll;
      }

      .table-wrap {
            @include breaker.below(grid.$m){
                  width:550px;
            }
      }
}