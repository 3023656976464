@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.press-contacts {
	&__title {
		font-size:24px;
		line-height:28px;
		margin-bottom:40px;
	}

	.row {
		@supports (display: grid) {
			display:grid;
			grid-template-columns: repeat(4, 1fr);
			grid-column-gap: 20px;
			grid-row-gap: 40px;
			width:100%;

			@include breaker.below(1024px){
				grid-template-columns: repeat(3, 1fr);
			}

			@include breaker.below(760px){
				grid-template-columns: repeat(2, 1fr);
			}

			@include breaker.below(grid.$s){
				grid-template-columns: repeat(1, 1fr);
			}
		}
	}

	&__col {
		text-align:center;

		img {
			max-width:192px;
			width:100%;
			border-radius:192px;
			margin-bottom:30px;
			filter: grayscale(100%);
		}

		h2 {
			@include mixins.parsefont(theme.$theme-heavy-font);
			font-size:24px;
			line-height:28px;
			margin-bottom:20px;
		}

		p {
			font-size:16px;
			line-height:26px;
			margin-bottom:20px;
		}

		a {
			font-size:16px;
			line-height:26px;
			color:theme.$black;
		}
	}
}