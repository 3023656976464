$theme: ('theme-heavyitalic-font': ('family': 'calluna-sans', 'weight': 700, 'style': 'italic'), 'theme-semibold-font': ('family': 'calluna-sans', 'weight': 600, 'style': 'normal'), 'theme-heavy-font': ('family': 'calluna-sans', 'weight': 700, 'style': 'normal'), 'theme-semibolditalic-font': ('family': 'calluna-sans', 'weight': 600, 'style': 'italic'), 'theme-regular-font': ('family': 'calluna-sans', 'weight': 400, 'style': 'normal'), 'theme-regular-italic-font': ('family': 'calluna-sans', 'weight': 400, 'style': 'italic'), 'theme-logotype-max-width': 154px, 'theme-logotype-minimized-width': 80px, 'theme-logotype-mobile-width': 60px, 'theme-intro-background-color': #1C2024, 'theme-intro-background-image': '../img/albertbonnier-top2.png', 'theme-intro-books-author-color': #FFFFFF, 'theme-intro-bonnier-text-color': #FFFFFF, 'theme-intro-main-text-color': #FFFFFF, 'theme-intro-search-text-color': #FFFFFF, 'theme-intro-search-icon': '../img/icon-magnifier-white-small.svg', 'theme-menu-bg': #FFFFFF, 'theme-menu-bar-bg': #C7361E, 'theme-hamburger-color': #FFFFFF, 'theme-news-background-color': #F4F4F4, 'theme-news-arrow-color': '../img/icon-arrow-right-red.svg', 'theme-news-title-color': #202020, 'theme-news-section-title-color': #202020, 'theme-books-section-title': #202020, 'theme-authors-section-title': #FFFFFF, 'theme-authors-bg-color': #1C2024, 'theme-themes-title-color': #FFFFFF, 'theme-contact-puff-bg': #C7361E, 'theme-contact-puff-color': #FFFFFF, 'theme-qa-color': #202020, 'theme-site-standard-color': #C7361E, 'theme-button-bg-color': #C7361E, 'theme-button-text-color': #FFFFFF, 'theme-article-image-shadow-color': #C7361E, 'theme-login-icon': '../img/icon-login.svg', 'theme-cart-icon': '../img/icon-cart.svg', 'theme-account-icon': '../img/icon-account.svg');@use "sass:map";

//Romanus och selling fonts
@use 'romanussellingfonts';

//Theme specific colors, fonts etc
@use 'fonts';
@use 'theme/theme.scss' with (
      $theme: $theme
);

//Other
@use 'normalize';
//@use 'entypo';
@use 'breaker';
@use 'mixins';
@use 'grid';
@use 'tools';

//Components
@use 'components/publisher-bar';
@use 'components/title-text-image';
@use 'components/menu-bar';
@use 'components/minibag';
@use 'components/miniaccount';
@use 'components/menu-bar-bf';
@use 'components/site-menu';
@use 'components/top-section';
@use 'components/top-section-bf';
@use 'components/top-section-extended';
@use 'components/breadcrumbs';
@use 'components/rooms';
@use 'components/news';
@use 'components/book-columns';
@use 'components/current-books';
@use 'components/current-authors';
@use 'components/current-themes';
@use 'components/contact-splash';
@use 'components/common-questions';
@use 'components/filterbox';
@use 'components/search';
@use 'components/sort-results';
@use 'components/search-results';
@use 'components/search-active-filters';
@use 'components/book';
@use 'components/author';
@use 'components/theme-card';
@use 'components/theme-header';
@use 'components/pagination';
@use 'components/contact';
@use 'components/button';
@use 'components/article';
@use 'components/about';
@use 'components/account-information';
@use 'components/login-modal';
@use 'components/modal-shadow';
@use 'components/my-orders';
@use 'components/cart';
@use 'components/plain-text';
@use 'components/checkout';
@use 'components/order-completed';
@use 'components/textblock-image';
@use 'components/full-width-image';
@use 'components/manus-box';
@use 'components/lightgallery';
@use 'components/table';
@use 'components/bookdatabase';
@use 'components/newsletter';
@use 'components/media-account';
@use 'components/current-trailers';
@use 'components/lib-instagram';
@use 'components/press-contacts';
@use 'components/our-writers';
@use 'components/release-message';
@use 'components/room-intro';
@use 'components/rooms-teaser';
@use 'components/employer-branding';
@use 'components/rentauthor';
@use 'components/sales-materials';
@use 'components/article-section';
@use 'components/footer';
@use 'components/iframe';
@use 'components/notfound';
@use 'components/password-protected';
@use 'components/halloween';
@use 'components/rule-newsletter';
@use 'components/press-images';
@use 'components/fancybox';
@use 'components/expanding-search';
@use 'components/all-search';

//Body settings
body {
  @include mixins.parsefont(theme.$theme-regular-font);
  color: theme.$black;

  &.api-work {
    padding-top: 140px;

    @media only screen and (max-width: 500px) {
      padding-top: 90px;
    }
  }

  &.not-frontpage.page-template-page-search-results,
  &.not-frontpage.all-search-results {
    padding-top: 0;
  }

  &.not-frontpage {
    padding-top: 140px;

    @media only screen and (max-width: 500px) {
      padding-top: 90px;
    }
  }

  &.logged-in {
    .menu-bar {
      margin-top: 32px;
    }

    .site-menu {
      margin-top: 32px;
    }
  }
}

//content
.content {
  background-color: #f4f4f4;
  max-width: 1360px;
  width: 100%;
  margin: 0 auto 40px;
  padding: 40px;
}

.wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  z-index: 2;

  @include breaker.below(grid.$m) {
    padding: 0 20px;
  }
}

.img-with-cred {
  position: relative;

  img {
    max-width: 100%;
  }

  .photo-cred {
    position: absolute;
    top: 0px;
    left: 0px;
    color: theme.$white;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 5px 10px;
  }
}

.page-title {
  font-size: 60px;
  line-height: 42px;
  @include mixins.parsefont(theme.$theme-heavy-font);
  margin-bottom: 40px;

  @include breaker.below(grid.$s) {
    font-size: 34px;
    line-height: 42px;
  }
}

.title-link {
  color: theme.$theme-news-section-title-color;
  text-decoration: none;
  position: relative;

  &:hover {
    &:after {
      display: none;
    }

    &:before {
      width: 100%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: theme.$theme-news-section-title-color;
  }

  &:before {
    content: '';
    position: absolute;
    height: 3px;
    width: 0;
    bottom: 0;
    left: 0;
    background-color: theme.$theme-news-section-title-color;
    transition: width 0.2s cubic-bezier(0.405, 0.07, 0.575, 0.995) 0.1s;
  }
}

.footer-bazar {
  max-width: 1360px;
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  background-color: #41566e;

  @include breaker.below(grid.$s) {
    padding: 40px 20px 8px 20px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    margin: 0 -20px;

    @include breaker.below(grid.$m) {
      flex-direction: column;
    }
  }

  &__col {
    width: 33.333%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    @include breaker.below(grid.$m) {
      width: 100%;
      margin-bottom: 32px;
      text-align: center;
    }

    @include breaker.below(grid.$s) {
      text-align: left;
    }
  }

  &__social {
    display: flex;
    flex-direction: row;
    align-self: center;

    @include breaker.below(grid.$s) {
      align-self: flex-start;
      margin: 0 -10px;
    }
  }

  &__social-icon {
    width: 46px;
    height: 46px;
    margin: 0 10px;
    background-size: 46px 46px;

    &--fb {
      background-image: url('../img/facebook-beige.png');
    }

    &--instagram {
      background-image: url('../img/instagram-beige.png');
    }

    &--linkedin {
      background-image: url('../img/linkedin-beige.png');
    }
  }

  h2 {
    color: #f5efe8;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Gilroy';
    text-decoration: underline;
    margin-bottom: 12px;
    font-weight: 600;
    letter-spacing: 0.05rem;

    &.center {
      text-align: center;
      @include breaker.below(grid.$s) {
        text-align: left;
      }
    }
  }

  p,
  a {
    font-size: 16px;
    line-height: 26px;
    color: #f5efe8;
  }
}

body.login-modal-visible {
  overflow: hidden;
}

[v-cloak] {
  display: none;
}

.page-template-page-books-vue,
.page-template-page-authors-vue {
  #search-top {
    margin-top: 20px;
  }
}
