@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.modal-shadow {
      position:fixed;
      top:0;
      left:0;
      right:0;
      bottom:0;
      z-index:3;
      background-color:rgba(0, 0, 0, 0.5);
      opacity:0;
      pointer-events:none;
      transition:opacity .3s ease-in-out;

      &--visible {
            opacity:1;
            pointer-events:auto;
      }
}