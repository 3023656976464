@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

$mobileBreakpoint: grid.$m;

.title-text-image {
  background-color: #f2f2ef;
  display: flex;
  padding: 50px 87px;
  margin-bottom: 54px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.2s ease-in-out;

  @include breaker.above($mobileBreakpoint) {
    justify-content: space-between;
  }

  @include breaker.below($mobileBreakpoint) {
    padding: 20px;
    flex-direction: column;
    margin-bottom: 40px;
  }

  &:hover {
    background-color: #e5e4de;

    .title-text-image__title {
      text-decoration: underline;
    }

    .title-text-image__image img {
      transform: scale(1.05);
    }
  }

  &--dark {
    background-color: #1c2024;

    &:hover {
      background-color: #333638;
    }

    .title-text-image__title {
      color: #fff;
    }

    .title-text-image__content {
      color: #fff;
    }

    svg path {
      fill: #fff;
    }

    .title-text-image__quoter {
      color: #fff;
    }
  }

  .title-text-image__image {
    margin-right: 87px;
    margin-left: 0;

    @include breaker.below($mobileBreakpoint) {
      margin-right: 0;
    }
  }

  &--right {
    flex-direction: row-reverse;

    @include breaker.below($mobileBreakpoint) {
      flex-direction: column;
    }

    .title-text-image__image {
      margin-right: 0;
      margin-left: 126px;

      @include breaker.below($mobileBreakpoint) {
        margin-left: 0;
      }
    }
  }

  &__title {
    font-size: 36px;
    line-height: 50px;
    @include mixins.parsefont(theme.$theme-heavy-font);
    font-weight: bold;
    margin-bottom: 16px;

    @include breaker.below(grid.$m) {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 10px;
    }
  }

  &__content {
    font-size: 17px;
    line-height: 27px;
    @include mixins.parsefont(theme.$theme-regular-font);
    margin-bottom: 20px;

    @include breaker.below(grid.$m) {
      font-size: 14px;
      line-height: 24px;
    }

    &--quote {
      font-size: 24px;
      line-height: 32px;
      font-family: 'Conv_Aileron-Heavy';
      margin-bottom: 13px;
    }
  }

  &__quoter {
    font-size: 17px;
    line-height: 27px;
    font-family: 'freight-sans-pro';
    margin-bottom: 20px;

    @include breaker.below($mobileBreakpoint) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__image {
    width: 100%;
    overflow: hidden;

    @include breaker.above($mobileBreakpoint) {
      max-width: 530px;
    }

    img {
      transition: all 1s ease-in-out;
      width: 100%;
      height: auto;

      @include breaker.below($mobileBreakpoint) {
        margin-bottom: 30px;
      }
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    @include breaker.above($mobileBreakpoint) {
      max-width: 527px;
    }
  }
}
