@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.theme-header {
      padding-bottom:20px;
      margin-bottom:20px;
      border-bottom:1px solid #EBEBEB;

      &--hidden {
            display: none;
      }

      &--mb {
            margin-bottom:40px;
      }

      h1,h2 {
            @include mixins.parsefont(theme.$theme-heavy-font);
            font-size:34px;
            line-height:42px;

            @include breaker.below(grid.$s){
                  font-size:24px;
                  line-height:28px;
            }
      }
}