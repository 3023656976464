@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.search-results-wrapper {
      transition-property: opacity;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;

      &--loading {
            opacity: 0.0;
      }

      &.mt40 {
            margin-top:40px;
      }

      &.mb40 {
            margin-bottom:40px;
      }
}

.search-results {

      @supports (display: grid) {
            display:grid;
            grid-template-columns: repeat(6, minmax(0, 1fr));
            grid-column-gap: 20px;
            grid-row-gap: 40px;


            @include breaker.below(1024px){
                  grid-template-columns: repeat(4, minmax(0, 1fr));
            }

            @include breaker.below(grid.$s){
                  grid-template-columns: repeat(3, minmax(0, 1fr));
                  grid-column-gap: 10px;
            }

            @include breaker.below(320px){
                  grid-template-columns: repeat(2, minmax(0, 1fr));
            }
      }

      &--authors {
            @supports (display: grid) {
                  grid-template-columns: repeat(4, minmax(0, 1fr));
            }
      }

      &__item {
            color:inherit;
            text-decoration:none;

            @supports not (display:grid){
                  margin-bottom:40px;
            }

            @supports (display: grid) {
                  width:100%;
                  margin-right:0 !important;
            }
      }

      &__link {
            color:initial;
      }

      &__author_link {
            color:initial;
      }

      &__author_links_popup {
		  	display: none;

		  	&.active {
				display: block;
			}
      }

      &__title {
            text-decoration:underline;
            font-size:16px;
            line-height:24px;

            @include breaker.below(grid.$s){
                  font-size:14px;
                  line-height:22px;
            }
      }

      &__book-cover-wrap{
            margin-bottom:20px;
            position:relative;
            height:285px;
            overflow:hidden;

            @include breaker.below(grid.$s){
                  height:145px;
            }

            @include breaker.below(320px){
                  height:200px;
            }
      }

      &__cover {
            width:100%;
            height:auto;
            position:absolute;
            bottom:0;
      }

      &__author-name {
            font-size:16px;
            line-height:24px;
            color:#A9A9A9;

            @include breaker.below(grid.$s){
                  font-size:14px;
                  line-height:22px;
            }
      }

      &__initials {
            @include mixins.align-both(absolute);
            font-size:60px;
            line-height:60px;
            @include mixins.parsefont(theme.$theme-heavy-font);
      }
}