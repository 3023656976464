@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.current-books {
      background-color:theme.$white;
      margin-bottom:40px;

      &--padding {
            padding:40px;

            @include breaker.below(grid.$s){
                  padding:20px;
            }
      }

      &__holder {
            @supports (display: grid) {
                  display:grid;
                  grid-template-columns: repeat(6, 1fr);
                  grid-column-gap: 40px;
                  grid-row-gap: 40px;
                  width:100%;
                  align-items:end;

                  @include breaker.below(1024px){
                        grid-template-columns: repeat(4, 1fr);
                        grid-column-gap: 10px;
                  }

                  @include breaker.below(grid.$s){
                        grid-template-columns: repeat(3, 1fr);
                  }

                  @include breaker.below(320px){
                        grid-template-columns: repeat(2, 1fr);
                  }
            }
      }

      &__title a {
            color:inherit;
            text-decoration:none;
      }

      &__title {
            font-size:24px;
            line-height:28px;
            margin-bottom:40px;

            @include breaker.below(grid.$s){
                  font-size:18px;
                  line-height:24px;
            }

            @include mixins.linkHeaderHover(theme.$theme-books-section-title);
      }

      &__book {
            color:inherit;
            text-decoration:none;
            overflow:hidden;
            @supports not (display:grid){
                  margin-bottom:40px;
            }

            @supports (display: grid) {
                  //width:100%;
                  margin-right:0 !important;
            }
      }

      &__book-title {
            text-decoration:underline;
            font-size:16px;
            line-height:24px;
            transition:opacity .2s ease-in-out;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;


            @include breaker.below(grid.$s){
                  font-size:14px;
                  line-height:22px;
            }
      }

      &__book-cover-wrap {
            margin-bottom:20px;
      }

      &__book-cover {
            width:100%;
            height:auto;
      }

      &__author-wrap {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
      }

      &__book-authors {
            font-size:16px;
            line-height:24px;
            color:#A9A9A9;
            transition:opacity .2s ease-in-out;
            white-space: nowrap;

            &:hover {
                  opacity:0.6;
            }

            @include breaker.below(grid.$s){
                  font-size:14px;
                  line-height:22px;
            }
      }

      &__book-link {
            color:initial;

            &:hover {
                  h3 {
                        opacity:0.6;
                  }
            }
      }
}