@use "mixins";
@use "breaker";
@use "grid";
@use "theme/theme";

.menu-bar--minimized, .menu-bar--not-frontpage {
	.minibag--active, .miniaccount--active {
		transform:translateY(121px);

		@include breaker.below(grid.$s){
			transform:translateY(70px);
		}
	}
}

.minibag {
	max-width:305px;
	width:100%;
	padding:30px;
	background-color:white;
	position:absolute;
	right: 160px;
	box-shadow: 0 0 30px 0 rgba(0,0,0,0.15);
	transform:translateY(-100%);
	opacity:0;
      transition:transform .4s ease-in-out, opacity .4s ease-in-out;

	@include breaker.below(grid.$s){
		right:0px;
		left:0px;
		max-width:100%;
	}

	&--active {
		opacity:1;
		transform:translateY(160px);

		@include breaker.below(grid.$s){
			transform:translateY(70px);
		}
      }

      &__empty {
            font-size:14px;
            line-height:24px;
            display:block;
            width:100%;
            text-align:center;
            margin-bottom:20px;
            padding-bottom:20px;
            border-bottom:1px solid #EBEBEB;

            a {
                  color:theme.$black;
                  transition:opacity .2s ease-in-out;

                  &:hover {
                        opacity:0.6;
                  }
            }
      }

	.button {
		width:100%;
		text-align:center;
	}

	h2 {
		text-align:center;
		font-size:20px;
		line-height:26px;
		@include mixins.parsefont(theme.$theme-heavy-font);
		margin-bottom:20px;
	}

	ul {
		list-style:none;
		padding-bottom:20px;
		margin-bottom:20px;
            border-bottom:1px solid #EBEBEB;
            height:155px;
            overflow-y:scroll;
            padding-right:17px;
	}

	&__row {
		position:relative;
		padding-left:20px;
		margin-bottom:10px;

		&:last-child {
			margin-bottom:0;
		}

		&__booktitle {
			font-size:14px;
			line-height:24px;
			@include mixins.parsefont(theme.$theme-heavy-font);
			position:relative;
		}

		&__count {
			font-size:14px;
			line-height:24px;
			@include mixins.parsefont(theme.$theme-heavy-font);
			@include mixins.vertical-align(absolute);
			left:-20px;
		}

		&__author {
			font-size:14px;
			line-height:24px;
		}

		&__remove {
			position:absolute;
			bottom:8px;
			right:0;
			width:11px;
			height:11px;
			background-image:url('../img/icon-close.svg');
			background-position:center center;
			background-size:11px 10px;
			background-repeat:no-repeat;

			&:hover {
				border-bottom:1px solid black;
			}
		}
	}
}