@use "theme/theme";
@use "breaker";
@use "grid";
@use "mixins";

.menu-bar.login-hidden .expanding-search-button {
  right: 129px;

  @include breaker.below(grid.$s) {
    right: 84px;
  }
}

.expanding-search-button {
  width: 56px;
  height: 56px;
  position: absolute;
  right: 272px;
  top: 50%;
  transform: translateX(50%) translateY(-50%);
  appearance: none;
  border: none;
  background: none;

  svg {
    width: 28px;
    height: 28px;
    color: theme.$theme-hamburger-color;

    @include mixins.menuBarMinimized() {
      color: theme.$theme-hamburger-color-minimized;
    }
  }

  @include breaker.below(grid.$s) {
    right: 100px;

    width: 19px;
    height: 19px;

    svg {
      width: 19px;
      height: 19px;
    }
  }

  &--cart-visible {
    right: 258px;

    @include breaker.below(grid.$s) {
      right: 144px;
    }
  }
}

.expanding-search {
  width: 100%;
  max-width: 360px;
  position: absolute;
  right: 40px;
  top: 101px;
  display: none;

  &.show {
    display: block;
  }

  @include breaker.below(grid.$s) {
    top: 70px;
    width: 100%;
    max-width: 100%;
    right: 0;
  }

  &__label {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__input {
    height: 56px;
    width: 100%;
    padding: 0 56px 0 16px;
    border: 1px solid #1c2024;
    outline: none;
  }

  &__previous-searches {
    padding: 10px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    h4 {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__clear-button {
    background: none;
    border: none;
    color: #000;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
    text-align: left;
    text-transform: uppercase;

    &:hover {
      background-color: #f8f8f8;
    }
  }

  &__previous-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__previous-item {
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:before {
      content: '';
      background-image: url('data:image/svg+xml;base64,ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMjQgMjQiPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTMuNSw4SDEyVjEzTDE2LjI4LDE1LjU0TDE3LDE0LjMzTDEzLjUsMTIuMjVWOE0xMywzQTksOSAwIDAsMCA0LDEySDFMNC45NiwxNi4wM0w5LDEySDZBNyw3IDAgMCwxIDEzLDVBNyw3IDAgMCwxIDIwLDEyQTcsNyAwIDAsMSAxMywxOUMxMS4wNywxOSA5LjMyLDE4LjIxIDguMDYsMTYuOTRMNi42NCwxOC4zNkM4LjI3LDIwIDEwLjUsMjEgMTMsMjFBOSw5IDAgMCwwIDIyLDEyQTksOSAwIDAsMCAxMywzIiAvPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIDwvc3ZnPg==');
      display: inline-block;
      background-size: 16px 16px;
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &__suggestion-grouped {
    padding: 10px;

    h4 {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 24px;
    }

    .expanding-search__suggestion {
      padding: 10px 0;

      &.article-icon:before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPmZpbGUtZG9jdW1lbnQtb3V0bGluZTwvdGl0bGU+PHBhdGggZD0iTTYsMkEyLDIgMCAwLDAgNCw0VjIwQTIsMiAwIDAsMCA2LDIySDE4QTIsMiAwIDAsMCAyMCwyMFY4TDE0LDJINk02LDRIMTNWOUgxOFYyMEg2VjRNOCwxMlYxNEgxNlYxMkg4TTgsMTZWMThIMTNWMTZIOFoiIC8+PC9zdmc+');
      }

      &.book-icon:before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPmJvb2stb3Blbi12YXJpYW50LW91dGxpbmU8L3RpdGxlPjxwYXRoIGQ9Ik0xMiAyMS41QzEwLjY1IDIwLjY1IDguMiAyMCA2LjUgMjBDNC44NSAyMCAzLjE1IDIwLjMgMS43NSAyMS4wNUMxLjY1IDIxLjEgMS42IDIxLjEgMS41IDIxLjFDMS4yNSAyMS4xIDEgMjAuODUgMSAyMC42VjZDMS42IDUuNTUgMi4yNSA1LjI1IDMgNUM0LjExIDQuNjUgNS4zMyA0LjUgNi41IDQuNUM4LjQ1IDQuNSAxMC41NSA0LjkgMTIgNkMxMy40NSA0LjkgMTUuNTUgNC41IDE3LjUgNC41QzE4LjY3IDQuNSAxOS44OSA0LjY1IDIxIDVDMjEuNzUgNS4yNSAyMi40IDUuNTUgMjMgNlYyMC42QzIzIDIwLjg1IDIyLjc1IDIxLjEgMjIuNSAyMS4xQzIyLjQgMjEuMSAyMi4zNSAyMS4xIDIyLjI1IDIxLjA1QzIwLjg1IDIwLjMgMTkuMTUgMjAgMTcuNSAyMEMxNS44IDIwIDEzLjM1IDIwLjY1IDEyIDIxLjVNMTEgNy41QzkuNjQgNi45IDcuODQgNi41IDYuNSA2LjVDNS4zIDYuNSA0LjEgNi42NSAzIDdWMTguNUM0LjEgMTguMTUgNS4zIDE4IDYuNSAxOEM3Ljg0IDE4IDkuNjQgMTguNCAxMSAxOVY3LjVNMTMgMTlDMTQuMzYgMTguNCAxNi4xNiAxOCAxNy41IDE4QzE4LjcgMTggMTkuOSAxOC4xNSAyMSAxOC41VjdDMTkuOSA2LjY1IDE4LjcgNi41IDE3LjUgNi41QzE2LjE2IDYuNSAxNC4zNiA2LjkgMTMgNy41VjE5TTE0IDE2LjM1QzE0Ljk2IDE2IDE2LjEyIDE1LjgzIDE3LjUgMTUuODNDMTguNTQgMTUuODMgMTkuMzggMTUuOTEgMjAgMTYuMDdWMTQuNTdDMTkuMTMgMTQuNDEgMTguMjkgMTQuMzMgMTcuNSAxNC4zM0MxNi4xNiAxNC4zMyAxNSAxNC41IDE0IDE0Ljc2VjE2LjM1TTE0IDEzLjY5QzE0Ljk2IDEzLjM0IDE2LjEyIDEzLjE2IDE3LjUgMTMuMTZDMTguNTQgMTMuMTYgMTkuMzggMTMuMjQgMjAgMTMuNFYxMS45QzE5LjEzIDExLjc0IDE4LjI5IDExLjY3IDE3LjUgMTEuNjdDMTYuMjIgMTEuNjcgMTUuMDUgMTEuODIgMTQgMTIuMTJWMTMuNjlNMTQgMTFDMTQuOTYgMTAuNjcgMTYuMTIgMTAuNSAxNy41IDEwLjVDMTguNDEgMTAuNSAxOS4yNiAxMC41OSAyMCAxMC43OFY5LjIzQzE5LjEzIDkuMDggMTguMjkgOSAxNy41IDlDMTYuMTggOSAxNSA5LjE1IDE0IDkuNDZWMTFaIiAvPjwvc3ZnPg==');
      }
    }
  }

  &__suggestions {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #1c2024;
    border-top: none;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
  }

  &__suggestion {
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    scroll-margin-bottom: 37px;

    &:before {
      content: '';
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPm1hZ25pZnk8L3RpdGxlPjxwYXRoIGQ9Ik05LjUsM0E2LjUsNi41IDAgMCwxIDE2LDkuNUMxNiwxMS4xMSAxNS40MSwxMi41OSAxNC40NCwxMy43M0wxNC43MSwxNEgxNS41TDIwLjUsMTlMMTksMjAuNUwxNCwxNS41VjE0LjcxTDEzLjczLDE0LjQ0QzEyLjU5LDE1LjQxIDExLjExLDE2IDkuNSwxNkE2LjUsNi41IDAgMCwxIDMsOS41QTYuNSw2LjUgMCAwLDEgOS41LDNNOS41LDVDNyw1IDUsNyA1LDkuNUM1LDEyIDcsMTQgOS41LDE0QzEyLDE0IDE0LDEyIDE0LDkuNUMxNCw3IDEyLDUgOS41LDVaIiAvPjwvc3ZnPg==');
      display: inline-block;
      background-size: 16px 16px;
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    &:hover {
      background-color: #f0f0f0;
    }

    &.highlight {
      background-color: #d3d3d3;
      font-weight: bold;
    }

    .highlighted {
      font-weight: bold;
    }
  }

  &__button {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;

    button {
      font-size: 12px;
      text-decoration: none;
      color: #000;
      padding: 10px;
      background-color: #fff;
      text-align: center;
      display: block;
      border-top: 1px solid #1c2024;
      border-left: none;
      border-bottom: none;
      border-right: none;
      width: 100%;
      outline: none;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
