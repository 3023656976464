@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.filterbox-holder {
      border:1px solid #EBEBEB;
      padding:20px;

      @include breaker.below(grid.$m){
            display:none;
      }

      &--mobile {
            @include breaker.above(grid.$m){
                  display:none;
            }
            @include breaker.below(grid.$m){
                  margin-bottom:30px;
                  &.filterbox-holder--visible {
                        display:block;
                  }
            }
      }
}

.filterbox {
      padding-bottom:20px;
      margin-bottom:20px;
      border-bottom:2px solid #EBEBEB;

      &:last-child {
            margin-bottom:0;
            padding-bottom:0;
            border-bottom:0;
      }

      &__button {
            @include breaker.below(grid.$m){
                  width:25%;
                  text-align:center;
            }

            @include breaker.below(grid.$s){
                  width:100%;
            }
      }

      &__title {
            text-transform:uppercase;
            font-size:14px;
            line-height:28px;
            letter-spacing:1px;
            margin-bottom:15px;
      }

      &__reset {
            float: right;
      }

      &__row {
            display:block;
            font-size:12px;
            line-height:18px;
            user-select: none;
            cursor:pointer;
            position:relative;
            padding-left: 28px;
            margin-bottom:15px;
            padding-right:40px;

            &:hover {
                  input ~ .filterbox__checkmark {
                        background-image:url('../img/icon-checkmark.svg');
                        background-size:12px 10px;
                        background-repeat:no-repeat;
                        background-position:center center;
                  }
            }

            input {
                  position:absolute;
                  opacity:0;
                  cursor:pointer;

                  &:checked ~ .filterbox__checkmark {}

                  &:checked ~ .filterbox__checkmark:after {
                        display:block;
                  }
            }

            .filterbox__checkmark:after {
                  width:12px;
                  height:10px;
                  @include mixins.align-both(absolute);
                  background-image:url('../img/icon-checkmark.svg');
                  background-size:12px 10px;
                  background-repeat:no-repeat;
                  background-position:center center;
            }
      }

      &__no-match {
            display: block;
            font-size: 12px;
            line-height: 18px;
            user-select: none;
            cursor: pointer;
            position: relative;
      }

      &__checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 18px;
            width: 18px;
            border:1px solid theme.$black;

            &:after {
                  content:'';
                  position: absolute;
                  display: none;
            }
      }

      &__checkboxes {

      }

      &__more {
            display:none;

            &--visible {
                  display:block;
            }
      }

      &__count {
            color:#908684;
            background:theme.$lightgray;
            @include mixins.parsefont(theme.$theme-semibold-font);
            font-size:10px;
            line-height:18px;
            padding:0px 8px;
            border-radius:11px;
            @include mixins.vertical-align(absolute);
            right:0;
      }

      &__input {
            margin-right:10px;
      }

      &__show-more {
            font-size:12px;
            line-height:28px;
            color:theme.$black;
            text-decoration:none;
            position:relative;
            padding-left:30px;

            &:hover {
                  text-decoration:underline;
            }

            &:before {
                  content:'';
                  width:20px;
                  height:20px;
                  @include mixins.vertical-align(absolute);
                  left:0;
                  background-image:url('../img/icon-expand-filter.svg');
                  background-repeat:no-repeat;
            }
      }

      &__freetext {
            width: 100%;
            margin-bottom: 15px;
            font-size: 90%;
            padding: 2px;
            border: 1px solid #EBEBEB;
      }
}