@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.grecaptcha-badge {display: none}
.rulemailer-email-check {
      display: none !important;
}
.rule-confirmation-box {
      color: white;
      input[type=checkbox] {
            display: inline-block;
            width: auto;
            margin-bottom: 0;
            cursor: pointer;
      }

      a {
            color: inherit;
      }

      margin-bottom: 40px;


}

.rulemailer-subscriber-form {
      button[type=submit] {
            background: transparent;
            text-decoration: underline;
            outline: none;
            border: 0;
      }
}