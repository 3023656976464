@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.common-questions {
      margin-bottom:40px;
      background-color:theme.$white;

      &__category {
            @include mixins.parsefont(theme.$theme-heavy-font);
            font-size:24px;
            line-height:28px;
            margin-bottom:40px;
      }

      li {
            margin-bottom:20px;
      }

      &__header a {
            color:inherit;
            text-decoration:none;
      }

      &__header {
            font-size:24px;
            line-height:28px;
            margin-bottom:40px;
            display:inline-block;

            @include mixins.linkHeaderHover(theme.$theme-qa-color);
      }

      &__collapsible {
            list-style:none;
      }

      &__collapsible-header {
            border-bottom:4px solid theme.$black;
            padding-bottom:20px;
            position:relative;

            h3 {
                  font-size:18px;
                  line-height:24px;
                  padding-right:28px;
                  cursor:pointer;
                  color:theme.$theme-qa-color;

                  &:hover {
                        opacity:0.6;

                        &:after, &:before {
                              opacity:0.6;
                        }
                  }

                  &:after {
                        content:'';
                        width:18px;
                        height:18px;
                        background-image:url('../img/icon-expand.svg');
                        background-repeat:no-repeat;
                        background-size:18px 18px;
                        background-position:center center;
                        position:absolute;
                        right:0;
                        top:3px;
                        transition:opacity .2s ease-in-out;
                  }

                  &:before {
                        content:'';
                        background-image:url('../img/icon-minimize.svg');
                        width:18px;
                        height:18px;
                        background-repeat:no-repeat;
                        background-size:18px 18px;
                        background-position:center center;
                        position:absolute;
                        right:0;
                        top:3px;
                        opacity:0;
                        transition:opacity .2s ease-in-out;
                  }
            }

            &--visible {
                  h3 {
                        &:after {
                              opacity:0 !important;
                        }

                        &:before {
                              opacity:1;
                        }
                  }
                  .common-questions__collapsible-body {
                        max-height:500px;
                  }

            }
      }

      &__collapsible-body {
            max-height:0;
            overflow:hidden;
            transition:max-height .4s ease-in-out;

            &__inner {
                  padding:20px 40px 0px;

                  @include breaker.below(grid.$s){
                        padding:20px 0;
                  }
            }

            p {
                  font-size:16px;
                  line-height:28px;
                  margin-bottom:20px;
                  color:theme.$theme-qa-color;

                  &:last-child {
                        margin-bottom:0;
                  }
            }

            a {
                  color:theme.$theme-qa-color;
            }
      }
}