@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.login-modal {

      z-index:5;
      position:fixed;
      transform:translateY(100%);
      padding: 65px 0;
      left:0;
      top: 0;
      width: 100%;
      overflow-y: auto;
      opacity:0;
      transition:transform .3s ease-in-out, opacity .3s ease-in-out;
      pointer-events:none;
      height: 100%;
      box-sizing: border-box;

      &__content {
            position: relative;
            padding:40px 100px;
            max-width:670px;
            width:100%;
            margin: 0 auto;
            background-color:theme.$white;

            @include breaker.below(grid.$s){
                  padding:40px 20px;
            }
      }

      @include breaker.below(grid.$s){
            overflow-y:scroll;
            overflow-x:hidden;
            margin:0;
            left:0;
            top:0px;
            padding: 0;

            -webkit-overflow-scrolling: touch;
            position:fixed;
      }

      &--visible {
            transform:translateY(0%);
            opacity:1;
            pointer-events:auto;
	}

	&__error {
		color:#cc0000;
		margin-bottom:20px;
		width:100%;
		text-align:center;
	}

      &__close {
            text-decoration:none;
            position:absolute;
            right:20px;
            top:20px;

            img {
                  float:right;
            }

            span {
                  font-size:12px;
                  line-height:20px;
                  letter-spacing:0.86px;
                  display:block;
                  color:theme.$black;
            }
      }


      &__title {
            @include mixins.parsefont(theme.$theme-heavy-font);
            line-height:42px;
            font-size:36px;
            text-align:center;
            margin-bottom:40px;

            img {
                  margin-right:16px;
            }
      }

      &__title-text {
            text-align:center;
            font-size:16px;
            line-height:24px;
            margin-bottom:35px;
      }


      &__label {
            margin-bottom:22px;
            display:block;
            span {
                  display:block;
                  font-size:14px;
                  line-height:24px;
                  margin-bottom:10px;
                  display:block;
            }

            input[type="text"], input[type="password"]{
                  width:100%;
                  border:none;
                  background-color:#F4F4F4;
                  height:44px;
                  padding:0 40px 0 20px;
                  outline:none;
                  border-bottom:2px solid rgba(28, 32, 36, 0.2);
                  transition:border-bottom .2s ease-in-out;

                  &:focus {
                        border-bottom:2px solid rgba(28, 32, 36, 1);
                  }
            }
      }

      &__form {
            margin-bottom:40px;
      }

      button{
            border:none;
            appearance:none;
            width:100%;
            font-size:16px;
            line-height:26px;
            padding:12px 0;
            text-align:center;
            background-color:theme.$theme-button-bg-color;
            color:theme.$theme-button-text-color;
            outline:none;
		border-radius:8px;
		transition:opacity .2s ease-in-out;
		position:relative;
            overflow:hidden;
            text-decoration:none;

		em {
			font-style:normal;
			transition:color .2s ease-in-out;
			line-height:26px;
		}

		svg {
			/* this is the check icon */
			width: 64px;
			height: 64px;
			position: absolute;
			left: 50%;
			top: 50%;
			width: 100%;
			/* move the icon on the right - outside the button */
			transform: translateX(50%) translateY(-50%);
		}

		&.animate {
			pointer-events:none;
			em {
				color: transparent;
				transform: translateX(-100%);
			}

			svg {
				transform: translateX(-50%) translateY(-50%);
				stroke-dashoffset: 0;
			}
		}

		&:hover {
			text-decoration:underline;
		}
      }

      &__checkbox {
            display:block;
            margin-bottom:20px;
      }

      &__forgotten {
            text-align:center;
            color:theme.$black;
            font-size:12px;
            line-height:24px;
            margin-bottom:20px;
		display:inline-block;
		transition:opacity .2s ease-in-out;

		&:hover {
			opacity:0.6;
		}
      }

      &__account {
            padding-top:40px;
            padding-bottom:40px;
            margin-bottom:40px;
            border-top:1px solid #EBEBEB;
            border-bottom:1px solid #EBEBEB;

            h2 {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:20px;
                  line-height:26px;
                  margin-bottom:20px;
                  text-align:center;
            }

            a {
                  color:theme.$black;
                  font-size:12px;
                  line-height:24px;
                  margin-right:20px;
			transition:opacity .2s ease-in-out;

                  &:hover {
                        opacity:0.6;
                  }
            }
      }


      &__create-account {
            text-align:center;
            padding-top:40px;
            padding-bottom:40px;
            margin-top:40px;
            border-top:1px solid #EBEBEB;

            h2 {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:20px;
                  line-height:26px;
                  margin-bottom:20px;
                  text-align:center;
            }

            p {
                  font-size:16px;
                  line-height:24px;
                  margin-bottom:40px;
            }

            &__button {
                  width:100%;
                  display:block;
                  font-size:16px;
                  line-height:26px;
                  padding:12px 0;
                  text-align:center;
                  outline:none;
                  border-radius:8px;
                  border:1px solid theme.$black;
                  color:theme.$black;
                  text-decoration:none;
			margin-bottom:20px;

			&:hover {
				text-decoration:underline;
			}
            }

            &__readmore {
                  color:theme.$black;
                  font-size:12px;
			line-height:24px;
			transition:opacity .2s ease-in-out;

			&:hover {
				opacity:0.6;
			}
            }

            &__already-customer {
                  margin-top: 40px;
            }
      }

      .warning-text {
            color: #cc0000;
            margin-bottom: 10px;
      }
}