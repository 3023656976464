@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.media-account {
      background-color:theme.$black;
      padding:40px;
	margin-bottom:40px;

	@include breaker.below(grid.$s){
		padding:20px;
	}

      @supports (display: grid) {
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            width:100%;
		grid-column-gap: 100px;

		@include breaker.below(1100px){
			grid-template-columns: 100%;
		}
      }

      &__title-large {
            font-size:42px;
            line-height:34px;
            color:theme.$white;
		margin-bottom:30px;

		@include breaker.below(grid.$s){
			font-size:24px;
			line-height:32px;
			margin-bottom: 20px;
		}
      }

      &__title-small {
            font-size:24px;
            line-height:34px;
            margin-bottom:10px;
            color:theme.$white;

            img {
                  float:right;
		}

		@include breaker.below(grid.$s){
			font-size:18px;
			line-height:34px;
		}
      }

      &__paragraph-large {
            color:theme.$white;
            font-size:24px;
            line-height:34px;
            margin-bottom:30px;

            strong {
                  @include mixins.parsefont(theme.$theme-heavy-font);
		}

		@include breaker.below(grid.$s){
			font-size:14px;
			line-height:24px;
			margin-bottom:20px;
		}
      }

      &__paragraph-small {
            color:theme.$white;
            font-size:14px;
            line-height:22px;
            margin-bottom:40px;

		@include breaker.below(grid.$s){
			display:block;
			margin-bottom:20px;
			font-size:12px;
			line-height:21px;
		}

            strong {
                  @include mixins.parsefont(theme.$theme-heavy-font);
            }

            a {
                  color:theme.$white;
                  transition:opacity .2s ease-in-out;

                  &:hover {
                        opacity:0.6;
                  }
            }
      }

      &__col {
            padding:40px;
		align-self:center;

		@include breaker.below(grid.$s){
			padding:20px;
		}

            &--text-center {
			text-align:center;
			@include breaker.below(grid.$s){
				padding:0;
			}
            }

            &--bordered {
                  border:1px solid #474A4D;
            }
      }

      label {
            width:100%;
            color:theme.$white;
            display:block;
            font-size:18px;
            line-height:24px;
		margin-bottom:10px;

		@include breaker.below(grid.$s){
			font-size:14px;
			line-height:24px;
		}
      }

      input {
            max-width:560px;
            width:100%;
            display:block;
            padding:14px 20px;
            font-size:18px;
            line-height:24px;
            margin-bottom:40px;
		outline:none;

		@include breaker.below(grid.$s){
			padding: 8px 10px;
			font-size:14px;
			line-height:24px;
			margin-bottom:20px;
		}
      }

      &__link {
            color:theme.$white;
            font-size:18px;
            line-height:24px;
            transition:opacity .2s ease-in-out;
            position:relative;
		padding-right:36px;

		@include breaker.below(grid.$s){
			font-size:14px;
			line-height:21px;
		}

            &:after {
                  content:'';
                  width:19px;
                  height:21px;
                  background-image:url('../img/icon-arrow-right-white.svg');
                  position:absolute;
                  right:0;
                  top:0;
            }

            &:hover {
                  opacity:0.6;
            }
      }

      &__login_error {
            color: theme.$white;
            margin-bottom: 10px;
      }
}