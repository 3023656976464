@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.bookdatabase {
      min-height:415px;
      display:flex;
      margin-bottom:54px;
      position:relative;
      color:inherit;
      text-decoration:none;
      overflow:hidden;

      @include breaker.below(grid.$m){
            flex-direction:column;
            margin-bottom:40px;
      }

      &:hover {
            .bookdatabase__image {
                  transform:scale(1.05);
            }

            .button {
                  text-decoration:underline;
            }
      }

      &__image {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background-size:cover;
            background-position: center;
            transition:all 1s ease-in-out;

            &--left {
                  right: 50%;
            }

            &--right {
                  left: 50%;
            }

            @include breaker.below(grid.$m){
                  position:static;
                  width:100%;
                  height:194px;
            }
      }

      &__content {
            position:relative;
            width:50%;
            padding: 50px 87px;
            display:flex;

            @include breaker.below(grid.$m){
                  padding:20px;
                  width:100%;
            }

            &--left {
                  margin-left:auto;
            }


            &--gray {
                  background-color: #f2f2f2;
            }

            &--black {
                  background-color: #1C2024;

                  .bookdatabase__content-inner h2 {
                        color:#fff;
                  }

                  .bookdatabase__content-inner p {
                        color:#fff;
                  }

                  .bookdatabase__content-inner .button {
                        background-color: #E5E4DF;
                        color:#1C2024;

                        svg path {
                              fill: #1C2024;
                        }
                  }
            }

            &--white {
                  background-color:#fff;
            }
      }

      &__content-inner {
            align-self:center;

            h2 {
                  font-size:20px;
                  line-height:27px;
                  font-family: "freight-sans-pro";
                  font-weight:500;
                  text-decoration:underline;
                  margin-bottom:14px;
            }

            p {
                  font-size:17px;
                  line-height:27px;
                  font-family: "freight-sans-pro";
                  margin-bottom:40px;

                  @include breaker.below(grid.$s){
                        font-size:14px;
                        line-height:20px;
                        margin-bottom:20px;
                  }
            }

            .button {
                  color:#E5E4DF;
                  background-color:#1C2024;
                  padding:8px 35px;
                  font-size:16px;
                  line-height:24px;
                  font-family: 'Conv_Aileron-SemiBold';
                  display:flex;
                  flex-direction: row;
                  align-items: center;
                  border-radius:20px;
                  display:inline-block;

                  svg path {
                        fill:#E5E4DF;
                  }
            }
      }
}