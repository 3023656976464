@use "sass:map";
@use "sass:math";
@use "sass:string";
@use "sass:meta";
@use "theme/theme";

// Line up.
@mixin vertical-align($position: relative){
	position: $position;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin vertical-align($position: relative){
	  position: $position;
	  top: 50%;
	  transform: translateY(-50%);
}

@mixin horizontal-align($position: relative){
	  position: $position;
	  left: 50%;
	  transform: translateX(-50%);
}

@mixin align-both($position: relative){
	 position: $position;
	 left: 50%;
	 top:50%;
	 transform: translate(-50%, -50%);
}

@mixin no-align($position:relative){
	position:initial !important;
	left: initial !important;
	top: initial !important;
	transform:none !important;
}

@mixin getfont($family, $weight: null, $style: null){
	font-family:$family;
	@if ( $weight )
	{
		font-weight:$weight;
	}

	@if ( $style )
	{
		font-style: string.unquote($style);
	}

}

@mixin parsefont($data){

	@if (meta.type-of($data) == string)
	{
		@include getfont($data);
	}
	@else if ($data)
	{
		//@debug $data;
		@include getfont(map.get($data, family), map.get($data, weight), map.get($data, style))
	}


}

@mixin linkHeaderHover($color) {
      color:$color;

      @if (theme.$theme-header-link-animation == none)
      {
            text-decoration: underline;
            &:hover {
                  text-decoration: none;
            }
      }
      @else
      {
            position:relative;
            &:hover {
                  &:after {
                        display:none;
                  }

                  &:before {
                        width:100%;
                  }
            }

            &:after {
                  content:'';
                  position:absolute;
                  height:3px;
                  width:100%;
                  bottom:0px;
                  left:0;
                  background-color:$color;
            }

            &:before {
                  content:'';
                  position:absolute;
                  height:3px;
                  width:0%;
                  bottom:0px;
                  left:0;
                  background-color:$color;
                  transition: width .2s cubic-bezier(.405,.07,.575,.995) .1s;
            }
      }
}

// Lets dance with the gradients
@mixin gradient($top, $bottom, $base, $min:0%,$max:100%){
    background: $base;
    background-image: -moz-linear-gradient(bottom,  $bottom $min, top $max);
    background-image: -o-linear-gradient(bottom, $bottom $min, top $max);
    background-image: -webkit-linear-gradient(bottom, $bottom $min,  top $max);
    background-image: linear-gradient(bottom, $bottom $min, $top $max);
}

@mixin gradientHorizontal($left, $right, $base, $min:0%,$max:100%){
    background: $base; /* Old browsers */
    background: -moz-linear-gradient(left, $left $min, $right $max); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop($min,$left), color-stop($max,$right)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, $left $min,$right $max); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, $left $min,$right $max); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, $left $min,$right $max); /* IE10+ */
    background: linear-gradient(to right, $left $min,$right $max); /* W3C */
}

@function get-vw($target, $context) {
	$vw-context: ($context*.01) * 1px;
	@return math.div($target, $vw-context) * 1vw;
}

@mixin menuBarMinimized() {
      .menu-bar--not-frontpage &,
      .menu-bar--minimized & {
            @content;
      }
}