@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.top-section-bf {
      position:relative;
      width:100%;
      max-width:1360px;
      margin:142px auto 0 auto;
      background-image: url('../img/sveavagen-ekport-ny3.png');
      background-repeat:no-repeat;
      background-size: cover;
      background-position:center center;
      height: 500px;
      display:flex;
      flex-direction: column;

      @include breaker.below(grid.$s){
            margin: 106px auto 40px auto;
            min-height: 500px;
      }

      &:after {
            content:'';
            position:absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
            background-color:rgba(28, 32, 36, 0.33);
            z-index:1;
      }

      &__content {
            position:relative;
            z-index:2;
            max-width:511px;
            width:100%;
            display:flex;
            flex-direction: column;
            align-items:flex-start;
            margin-top:77px;
            margin-left:89px;

            @include breaker.below(grid.$s){
                  justify-content: center;
                  margin:0;
                  height:auto;
                  padding:0 20px;
                  height:100%;
            }


            span {
                  font-family: "freight-sans-pro";
                  font-weight:400;
                  font-size:16px;
                  text-transform:uppercase;
                  color:#fff;
            }

            h1 {
                  font-family: "freight-sans-pro";
                  font-weight:bold;
                  font-size: 46px;
                  line-height: 48px;
                  color:#fff;
                  margin-bottom:40px;
                  margin-top: 10px;

                  @include breaker.below(grid.$s){
                        font-size:36px;
                        line-height:45px;
                  }

            }

            .button {
                  color:#1C2024;
                  background-color:#E5E4DF;
                  padding:8px 35px;
                  font-size:16px;
                  line-height:24px;
                  font-family: 'Conv_Aileron-SemiBold';
                  display:flex;
                  flex-direction: row;
                  align-items: center;
                  border-radius:20px;

            }
      }

      &__explainer {
            background-color:#E5E4DF;
            margin-top:auto;
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 80px;
            max-width:818px;
            width:100%;
            z-index:2;
            padding:35px 20px 35px 89px;

            @include breaker.below(grid.$s){
                  display:block;
                  padding:20px;
            }

            div {
                  width:50%;

                  @include breaker.below(grid.$s){
                        width:100%;
                  }
            }

            p {
                  font-size:15px;
                  line-height:24px;
                  font-family: 'Conv_Aileron-Regular';

                  @include breaker.below(grid.$s){
                        font-size:14px;
                        margin-bottom:10px;

                        &:last-child {
                              margin-bottom:0;
                        }
                  }
            }
      }
}