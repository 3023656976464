@use "theme/theme";
@use "grid";
@use "breaker";

.menu-bar-bf {
      position:fixed;
      top:60px;
      left:0;
      right:0;
      background-image:theme.$theme-intro-background-image;
      background-position:center top;
      background-repeat:no-repeat;
      background-size:cover;
      background-color:theme.$theme-menu-bar-bg;
      height:51px;
      transition:height 1s;
      z-index:3;
      max-width:1440px;
      margin:0 auto;

      @include breaker.below(grid.$s){
            height:90px;
      }

      &__logotype {
            position:absolute;
            top:0px;
            left:80px;
            transition:top 1s;
            max-width:278px;
            width:100%;

            @include breaker.below(grid.$s){
                  max-width:195px;
                  top:36px;
                  left:20px;
            }

            @include breaker.below(320px){
                  max-width:170px;
            }
      }

      .site-menu__hamburger {
            top:0px;
            right:80px;
            transition:top 1s;

            @include breaker.below(grid.$s){
                  right:20px;
                  top:20px;
            }
      }

      &--not-frontpage {
            height:85px;

            @include breaker.below(grid.$s){
                  height:90px;
            }

            .menu-bar__logotype {
                  top:31px;
                  left:40px;

                  @include breaker.below(grid.$s){
                        left:20px;
                        top:36px;
                  }
            }

            .site-menu__hamburger {
                  top:15px;
                  right:40px;

                  @include breaker.below(grid.$s){
                        right:20px;
                  }
            }

            .site-menu__login {
                  top:25px;
                  right:113px;

                  @include breaker.below(grid.$s){
                        right:58px;
                  }
            }
      }

      &--minimized {
            height:85px;

            @include breaker.below(grid.$s){
                  height:90px;
            }

            .menu-bar__logotype {
                  top:31px;

                  @include breaker.below(grid.$s){
                        top:36px;
                  }
            }

            .site-menu__hamburger {
                  top:15px;

                  @include breaker.below(grid.$s){
                        top:20px;
                  }
            }

            .site-menu__login {
                  top:25px;
            }
      }
}