@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.order-completed {
      background-color:#F4F4F4;
      padding:40px;
      margin-bottom:40px;

      @include breaker.below(grid.$s){
            padding:20px;
            margin-bottom:20px;
      }

      &__holder {
            max-width:673px;
            width:100%;
            margin:0 auto;
            background-color:theme.$white;
            padding:60px;
            text-align:center;

            @include breaker.below(grid.$s){
                  padding:40px;
            }
      }

      h1 {
            @include mixins.parsefont(theme.$theme-heavy-font);
            font-size:36px;
            line-height:42px;
            text-align:center;
            margin-bottom:44px;
            position:relative;

            &:after {
                  content:'';
                  width:30px;
                  height:4px;
                  background-color:theme.$theme-site-standard-color;
                  @include mixins.horizontal-align(absolute);
                  bottom:-24px;
            }

            @include breaker.below(grid.$s){
                  font-size: 24px;
                  line-height: 31px;
            }
      }

      p {
            font-size:16px;
            line-height:24px;
            margin-bottom:40px;
      }

      &__button-holder {
            padding-top:60px;
            border-top:1px solid #EBEBEB;

            .button:first-child {
                  margin-right:40px;
            }

            @include breaker.below(grid.$s){
                  padding-top:20px;
                  .button {
                        width:100%;
                        text-align:center;

                        &:first-child {
                              margin-bottom:20px;
                        }
                  }
            }
      }
}