@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.rooms {
      padding:40px;
      background:theme.$lightgray;

      @include breaker.below(grid.$s){
            padding:20px;
      }



      &--full {
            margin-bottom:40px;

            .row {
                  @supports (display: grid) {
                        display:grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-column-gap: 30px;
                        grid-row-gap: 40px;
                        width:100%;

                        @include breaker.below(1024px){
                              grid-template-columns: repeat(2, 1fr);
                        }

                        @include breaker.below(grid.$s){
                              grid-template-columns: repeat(1, 1fr);
                        }
                  }
            }

            @include breaker.below(grid.$s){
                  margin-bottom:20px;
            }

            .rooms__description {
                  float:right;
            }

            .rooms__logotype {
                  margin-bottom:60px;
                  @include breaker.below(grid.$s){
                        margin-bottom:20px;
                  }
            }

            .rooms__column {
                  margin-bottom:0;

                  @include breaker.below(grid.$s){
                        margin-bottom:40px;
                  }
            }
      }

      &__logotype {
            margin-bottom:30px;
            max-width: 150px;
      }

      &__description {
		display:block;
		font-family:"freight-sans-pro";
		font-weight:400;
            font-size:16px;
            line-height:26px;
            margin-bottom:40px;

            @include breaker.below(1024px){
                  max-width:500px;

            }

            @include breaker.below(grid.$m){
                  max-width:300px;
            }

            a {
                  color:theme.$black;
                  text-decoration:underline;
                  transition:opacity .2s ease-in-out;

                  &:hover {
                        opacity:0.6;
                  }
            }

            strong {
			font-family:"freight-sans-pro";
			font-weight:700;
            }
      }

      &__column {
            text-align:center;
            margin-bottom:40px;
            text-decoration:none;
            color:theme.$black;

            @supports not (display:grid){
                  margin-bottom:40px;
            }

            @supports (display: grid) {
                  width:100%;
                  margin-right:0 !important;
            }

            &:last-child {
                  margin-bottom:0;
            }

            &:hover {
                  .rooms__column__link {
                        opacity:0.6;
                  }
            }
      }

      &__column__image {
            max-width:94px;
            width:100%;
            display:inline-block;
            margin-bottom:20px;
            transition:transform 1s ease-in-out;
      }

      &__column__link {
		font-family:"freight-sans-pro";
		font-weight:600;
            display:block;
            font-size:18px;
            line-height:24px;
            margin-bottom:10px;
            text-decoration:underline;
            transition:opacity .2s ease-in-out;
      }

      &__column__text {
		font-family:"freight-sans-pro";
		font-weight:400;
            font-size:14px;
            line-height:20px;
      }
}