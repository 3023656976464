@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.current-themes {
      background-color:theme.$black;
      padding:40px;
      margin-bottom:40px;

      @include breaker.below(grid.$s){
            padding:20px;
            margin-bottom:20px;
      }

      .row {
            width:100%;

            @supports (display: grid) {
                  display:grid;
                  grid-column-gap: 40px;
                  grid-row-gap: 40px;

                  &:after {
                        display:none;
                  }

                  &.grid-4_2 {
                        grid-template-columns: repeat(4, 4fr);

                        @include breaker.below(1024px){
                              grid-template-columns: repeat(3, 3fr);
                              grid-column-gap: 40px;
                        }

                        @include breaker.below(grid.$m){
                              grid-template-columns: repeat(2, 2fr);
                              grid-column-gap: 40px;
                        }

                        @include breaker.below(grid.$s){
                              grid-template-columns: repeat(2, 2fr);
                              grid-column-gap: 20px;
                        }
                  }

                  &.grid-3_1 {
                        grid-template-columns: repeat(3, 3fr);

                        @include breaker.below(1024px){
                              grid-template-columns: repeat(3, 3fr);
                              grid-column-gap: 40px;
                        }

                        @include breaker.below(grid.$m){
                              grid-template-columns: repeat(2, 2fr);
                              grid-column-gap: 40px;
                        }

                        @include breaker.below(grid.$s){
                              grid-template-columns: repeat(1, 100%);
                        }
                  }

                  &.grid-2_1 {
                        grid-template-columns: repeat(2, 2fr);

                        @include breaker.below(grid.$s){
                              grid-template-columns: repeat(1, 100%);
                        }
                  }
            }
      }

      &__genre {
            @supports not (display:grid){
                  margin-bottom:40px;
            }

            @supports (display: grid) {
                  width:100%;
                  margin:0 !important;
            }
      }

      &__genre a {
            text-decoration:none;

            &:hover {
                  .current-themes__genre-title {
                        text-decoration:underline;
                  }
            }
      }

      &__title a {
            color:inherit;
            text-decoration:none;
      }

      &__title {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 40px;
            display:inline-block;

            @include breaker.below(grid.$s){
                  font-size:18px;
                  line-height:24px;
                  margin-bottom:28px;
            }

            @include mixins.linkHeaderHover(theme.$white);
      }

      &__genre-image {
            width:100%;
            height:auto;
            vertical-align: top;
            margin-bottom:30px;

            @include breaker.below(grid.$s){
                  margin-bottom:20px;
            }
      }

      &__genre-title {
            color:theme.$theme-themes-title-color;
            font-size:34px;
            line-height:42px;
            word-break:break-word;
            margin-bottom:20px;
            @include mixins.parsefont(theme.$theme-heavy-font);

            @include breaker.below(grid.$s){
                  font-size:26px;
                  line-height:32px;
            }
      }

      p {
            color:theme.$white;
            font-size:18px;
            line-height:24px;

            @include breaker.below(grid.$s){
                  font-size:14px;
                  line-height:22px;
                  margin-bottom:40px;

                  @supports not (display:grid){
                        margin-bottom:40px;
                  }

                  @supports (display: grid) {
                        width:100%;
                        margin-bottom:0;
                  }
            }
      }

      &__tag {
            font-size:12px;
            line-height:12px;
            text-decoration:none;
            color:theme.$green;
            background-color:theme.$white;
            padding: 7px 10px;
            float:left;
            margin-right:20px;
            position:relative;

            &:after {
                  content:'';
                  width: 0;
                  height: 0;
                  position:absolute;
                  top:0;
                  right:-8px;
                  border-style: solid;
                  border-width: 13px 0 13px 8px;
                  border-color: transparent transparent transparent #fff;
            }

            &:before {
                  content:'';
                  width:4px;
                  height:4px;
                  @include mixins.vertical-align(absolute);
                  right:0;
                  background-color:theme.$green;
                  border-radius:2px;
            }
      }
}