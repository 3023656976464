@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.sort-results {
      margin-top:33px;

      &--nmt {
            margin-top:0;
      }

      &__hits {
            font-size:14px;
            line-height:36px;
            margin-bottom:20px;
            display:block;
	}

	&__search-all-publishers {
		font-size:14px;
		line-height:14px;
		display:block;
		margin-bottom:20px;

		a {
			color:theme.$black;
			transition:opacity .2s ease-in-out;

			&:hover {
				opacity:0.6;
			}
		}
	}

      &__sorting {
            float:right;
            margin-right:40px;
            line-height:36px;
            font-size:14px;

            select {
                  margin-left:10px;
                  border:1px solid theme.$black;
                  border-radius:0;
                  background-color:theme.$white;
                  appearance: none;
                  font-size:14px;
                  line-height:28px;
                  padding:3px 75px 3px 12px;
                  background-image:url('../img/icon-chevron-down.svg');
                  background-repeat:no-repeat;
                  background-position:right 10px center;
                  outline:none;

                  @include breaker.below(410px){
                        margin-left:0;
                  }
            }

            @include breaker.below(1380px){
                  float:left;
            }
      }

      &__views {
            float:right;

            @include breaker.below(grid.$s){
                  display:none;
            }

            &__view {
                  width:39px;
                  height:36px;
                  float:left;
                  margin-left:10px;
                  background-size:39px 36px;
                  background-repeat:no-repeat;
                  opacity:0.3;
                  transition:opacity .2s ease-in-out;
				  cursor: pointer;

                  &:hover {
                        opacity:1;
                  }

                  &--active {
                        opacity:1;
                  }

                  &--normal {
                        background-image:url('../img/icon-normal-view.svg');
                  }

                  &--listing {
                        background-image:url('../img/icon-listing-view.svg');
                  }

                  &:first-child {
                        margin-left:0;
                  }
            }
      }

      &__filter-button {
            margin-bottom:30px;
            display:none;
            @include breaker.below(grid.$m){
                  display:block;
            }
      }
}