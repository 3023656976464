@font-face {
	font-family: 'Conv_Aileron-SemiBold';
	src: url('../fonts/Aileron-SemiBold.eot');
	src: local('☺'), url('../fonts/Aileron-SemiBold.woff') format('woff'), url('../fonts/Aileron-SemiBold.ttf') format('truetype'), url('../fonts/Aileron-SemiBold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Aileron-HeavyItalic';
	src: url('../fonts/Aileron-HeavyItalic.eot');
	src: local('☺'), url('../fonts/Aileron-HeavyItalic.woff') format('woff'), url('../fonts/Aileron-HeavyItalic.ttf') format('truetype'), url('../fonts/Aileron-HeavyItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Aileron-Heavy';
	src: url('../fonts/Aileron-Heavy.eot');
	src: local('☺'), url('../fonts/Aileron-Heavy.woff') format('woff'), url('../fonts/Aileron-Heavy.ttf') format('truetype'), url('../fonts/Aileron-Heavy.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Aileron-SemiBoldItalic';
	src: url('../fonts/Aileron-SemiBoldItalic.eot');
	src: local('☺'), url('../fonts/Aileron-SemiBoldItalic.woff') format('woff'), url('../fonts/Aileron-SemiBoldItalic.ttf') format('truetype'), url('../fonts/Aileron-SemiBoldItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Aileron-Regular';
	src: url('../fonts/Aileron-Regular.eot');
	src: local('☺'), url('../fonts/Aileron-Regular.woff') format('woff'), url('../fonts/Aileron-Regular.ttf') format('truetype'), url('../fonts/Aileron-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
      font-family: 'Conv_Lora-Regular';
      src: url('../fonts/Lora-Regular.eot');
      src: local('☺'), url('../fonts/Lora-Regular.woff') format('woff'), url('../fonts/Lora-Regular.ttf') format('truetype'), url('../fonts/Lora-Regular.svg') format('svg');
      font-weight: normal;
      font-style: normal;
}


// SuisseWorks
@font-face {
      font-family: 'Suisse Works Book';
      src: url('../fonts/SuisseWorks-Book.woff2') format('woff2'),
      url('../fonts/SuisseWorks-Book.woff') format('woff'),
      url('../fonts/SuisseWorks-Book.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'Suisse Works';
      src: url('../fonts/SuisseWorks-Bold.woff2') format('woff2'),
      url('../fonts/SuisseWorks-Bold.woff') format('woff'),
      url('../fonts/SuisseWorks-Bold.ttf') format('truetype');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'Suisse Works';
      src: url('../fonts/SuisseWorks-MediumItalic.woff2') format('woff2'),
      url('../fonts/SuisseWorks-MediumItalic.woff') format('woff'),
      url('../fonts/SuisseWorks-MediumItalic.ttf') format('truetype');
      font-weight: 500;
      font-style: italic;
      font-display: swap;
}

@font-face {
      font-family: 'Suisse Works';
      src: url('../fonts/SuisseWorks.woff2') format('woff2'),
      url('../fonts/SuisseWorks.woff') format('woff'),
      url('../fonts/SuisseWorks.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'Suisse Works';
      src: url('../fonts/SuisseWorks-Medium.woff2') format('woff2'),
      url('../fonts/SuisseWorks-Medium.woff') format('woff'),
      url('../fonts/SuisseWorks-Medium.ttf') format('truetype');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'Suisse Works';
      src: url('../fonts/SuisseWorks-BoldItalic.woff2') format('woff2'),
      url('../fonts/SuisseWorks-BoldItalic.woff') format('woff'),
      url('../fonts/SuisseWorks-BoldItalic.ttf') format('truetype');
      font-weight: bold;
      font-style: italic;
      font-display: swap;
}

@font-face {
      font-family: 'Suisse Works Book';
      src: url('../fonts/SuisseWorks-BookItalic.woff2') format('woff2'),
      url('../fonts/SuisseWorks-BookItalic.woff') format('woff'),
      url('../fonts/SuisseWorks-BookItalic.ttf') format('truetype');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
}

@font-face {
      font-family: 'Suisse Works';
      src: url('../fonts/SuisseWorks-Italic.woff2') format('woff2'),
      url('../fonts/SuisseWorks-Italic.woff') format('woff'),
      url('../fonts/SuisseWorks-Italic.ttf') format('truetype');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
}


// ABC Monument Grotesk
@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-HeavyItalic.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-HeavyItalic.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-HeavyItalic.ttf') format('truetype');
      font-weight: 800;
      font-style: italic;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-LightItalic.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-LightItalic.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-LightItalic.ttf') format('truetype');
      font-weight: 300;
      font-style: italic;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk Ultra';
      src: url('../fonts/ABCMonumentGrotesk-Ultra.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-Ultra.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-Ultra.ttf') format('truetype');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-Black.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-Black.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-Black.ttf') format('truetype');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-BlackItalic.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-BlackItalic.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-BlackItalic.ttf') format('truetype');
      font-weight: 900;
      font-style: italic;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-ThinItalic.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-ThinItalic.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-ThinItalic.ttf') format('truetype');
      font-weight: 100;
      font-style: italic;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-Regular.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-Regular.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-Regular.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk Ultra';
      src: url('../fonts/ABCMonumentGrotesk-UltraItalic.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-UltraItalic.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-UltraItalic.ttf') format('truetype');
      font-weight: 900;
      font-style: italic;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-MediumItalic.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-MediumItalic.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-MediumItalic.ttf') format('truetype');
      font-weight: 500;
      font-style: italic;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-Light.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-Light.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-Light.ttf') format('truetype');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-BoldItalic.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-BoldItalic.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-BoldItalic.ttf') format('truetype');
      font-weight: bold;
      font-style: italic;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-RegularItalic.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-RegularItalic.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-RegularItalic.ttf') format('truetype');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-Heavy.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-Heavy.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-Heavy.ttf') format('truetype');
      font-weight: 800;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-Medium.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-Medium.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-Medium.ttf') format('truetype');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-Thin.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-Thin.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-Thin.ttf') format('truetype');
      font-weight: 100;
      font-style: normal;
      font-display: swap;
}

@font-face {
      font-family: 'ABC Monument Grotesk';
      src: url('../fonts/ABCMonumentGrotesk-Bold.woff2') format('woff2'),
      url('../fonts/ABCMonumentGrotesk-Bold.woff') format('woff'),
      url('../fonts/ABCMonumentGrotesk-Bold.ttf') format('truetype');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
}


