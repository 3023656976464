@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.search-active-filters {
      list-style-type: none;
      display: block;

      li {
            font-size: 14px;
            display: inline-block;
            margin: 0 10px 10px 0;
            padding: 6px 8px;
            background-color: #F4F4F4;
            border-radius: 5px;
      }

      a {
            text-decoration: none;
            color: theme.$black;

            img {
                  height: 10px;
                  vertical-align: baseline;
                  margin-left: 2px;
            }
      }
}