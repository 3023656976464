@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.press-images {
      &:hover {
                  h2 {
                        opacity:0.6;
                  }

                  img {
                        transform:scale(1.05);
                  }
      }




      h2 {
            font-size:21px;
            line-height:24px;
            color:theme.$black;
            margin-bottom:10px;
            transition:opacity .2s ease-in-out;
            @include breaker.below(grid.$s){
                  font-size:18px;
                  line-height:24px;
            }
      }
      p {
            font-size:18px;
            line-height:28px;
            transition:opacity .2s ease-in-out;

            @include breaker.below(grid.$s){
                  font-size:14px;
                  line-height:24px;
            }
      }
      &__image {
            position:relative;
            display:block;
            margin-bottom:30px;
            overflow:hidden;

            img {
                  width:100%;
                  height:auto;
                  transition:transform 1s ease-in-out;
            }
      }

      &__image-overlay {
            position:absolute;
            bottom:0;
            right:0;
            display:flex;
            flex-direction: row;
            justify-items:center;
            align-items:center;
            gap:6px;
            background-color:rgba(0,0,0,0.5);
            padding:6px;

            p {
                  color:#fff;
            }
      }
}