@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.search {
      margin-bottom:20px;
      position: relative;

      &__input {
            width:100%;
            background-color:#F4F4F4;
            border:none;
            padding:21px 66px 21px 24px;
            outline:none;
            font-size:18px;
            line-height:24px;
            border-bottom:4px solid rgba(28, 32, 36, 0.2);
            transition:border-bottom .2s ease-in-out;
            box-sizing:border-box !important;

            &:focus {
                  border-bottom:4px solid rgba(28, 32, 36, 1);
            }

            &::placeholder {
                  color: #C7C7C7;
                  font-size:18px;
                  line-height:24px;
            }
      }

      &__input-label {
            position:relative;
            display:inline-block;
            width:100%;
      }

      &__submit {
            width:26px;
            height:26px;
            background-image:url('../img/icon-magnifier.svg');
            background-repeat:no-repeat;
            position:absolute;
            top:20px;
            right:20px;
            appearance: none;
            border:none;
            background-color:transparent;
            outline:none;
      }

      @include breaker.below(1380px){
            .col-6 {
                  width:100%;
            }
      }
}