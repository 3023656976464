@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.table-wrapper {
      margin-bottom:60px;

      @include breaker.below(1024px){
           overflow-x:scroll;
           width:100%;
      }
}

.table {
      @include breaker.below(1024px){
            width:1024px;
      }

      &--five-columns {
            .table__head {
                  grid-template-columns: repeat(5, 1fr);
            }

            .table__body__row {
                  grid-template-columns: repeat(5, 1fr);
            }
      }

      &--six-columns {
            .table__head {
                  grid-template-columns: repeat(6, 1fr);
            }

            .table__body__row {
                  grid-template-columns: repeat(6, 1fr);
            }
      }

      &__head {
            width:100%;
            display:grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 40px;
            padding-bottom:10px;
            border-bottom:2px solid theme.$black;

            @include breaker.below(grid.$m){
                  grid-column-gap: 20px;
            }

            &__column {

                  p {
                       font-size:18px;
                       line-height:24px;
                       @include mixins.parsefont(theme.$theme-heavy-font);
                  }



                  &:last-child {
                        text-align:right;
                  }

                  &--no-alignment {
                        &:last-child {
                              text-align:left;
                        }
                  }
            }
      }

      &__body {
            &__row {
                  display:grid;
                  grid-template-columns: repeat(4, 1fr);
                  grid-column-gap: 40px;
                  padding:10px 0;
                  border-bottom:1px solid #C7C7C7;

                  @include breaker.below(grid.$m){
                        grid-column-gap: 20px;
                  }

                  &__column {
                        a {
                              display:inline-block;
                              color:inherit;
                              transition:opacity .2s ease-in-out;
                              font-size:14px;
                              line-height:24px;

                              &:hover {
                                    opacity:0.6;
                              }
                        }

                        p {
                              font-size:14px;
                              line-height:24px;
                        }

                        &:last-child {
                              text-align:right;
                        }

                        &--no-alignment {
                              &:last-child {
                                    text-align:left;
                              }
                        }
                  }
            }
      }
}