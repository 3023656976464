@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.our-writers {
	margin-bottom:80px;

	@include breaker.below(grid.$s){
		margin-bottom:40px;
	}

      &__title {
            color:theme.$black;
            font-size:24px;
            line-height:34px;
            margin-bottom:40px;
		display:inline-block;

		@include breaker.below(grid.$s){
			font-size:18px;
			line-height:24px;
		}
      }

      &__grid {
            @supports (display: grid) {
                  display:grid;
                  grid-template-columns: repeat(4, 1fr);
                  width:100%;
                  grid-column-gap: 40px;
                  grid-row-gap:40px;

                  @include breaker.below(grid.$m){
                        grid-template-columns: repeat(3, 1fr);
				grid-column-gap: 20px;
			}

			@include breaker.below(grid.$s){
                        grid-template-columns: repeat(2, 1fr);
				grid-column-gap: 20px;
			}
            }
      }

      &__col {
            color:inherit;
            text-decoration:none;
            font-size:18px;
            line-height:26px;

            img {
                  max-width:426px;
                  width:100%;
                  margin-bottom:20px;
            }

            p {
			margin-bottom:20px;

			@include breaker.below(grid.$s){
				font-size:14px;
				line-height:24px;
				margin-bottom:10px;
			}
            }

            span {
                  text-decoration:underline;
			transition:opacity .2s ease-in-out;
			@include breaker.below(grid.$s){
				font-size:14px;
				line-height:24px;
			}
            }

            &:hover {
                  span {
                        opacity:0.6;
                  }
            }
      }
}