@use "breaker";
@use "grid";
@use "mixins";
@use "theme/theme";

.menu-bar {
      position:fixed;
      top:0;
      left:0;
      right:0;
      height:101px;
      transition:height 1s, background-color .2s ease-in-out;
      z-index:3;
      max-width:1440px;
      margin:0 auto;

      .with-publisher-bar & {
            margin-top:41px;

            @include breaker.below(grid.$s){
                  margin-top:36px;
            }
      }

      @include breaker.below(grid.$s){
            height:70px;
      }

      &--bg {
		        transition:height 1s, background-color .2s ease-in-out;
            background-color:theme.$theme-menu-bar-bg;
            .menu-bar__bg {
                  opacity:1;
            }
      }

      &__bg {
            position:absolute;
            left:0;
            right:0;
            bottom:0;
            top:0;
            opacity:0;
            transition:opacity .2s ease-in-out;
            background-image:theme.$theme-intro-background-image;
            background-position:center top;
            background-repeat:no-repeat;
            background-size:cover;
      }

      &__logotype {
            position:absolute;
            top:80px;
            left:80px;
            transition:all 1s;
            max-width:theme.$theme-logotype-max-width;
            width:100%;

            @include mixins.menuBarMinimized() {
                  display: none;
            }

            @include breaker.below(grid.$s){
                  max-width:theme.$theme-logotype-mobile-width;
                  left:20px;
                  @include mixins.vertical-align(absolute);
            }

            &--minimized {
                  display: none;

                  @include mixins.menuBarMinimized() {
                        display: block;
                  }
            }
      }

      .site-menu__hamburger {
            top:80px;
            right:80px;
            transition:all 1s;

            @include breaker.below(grid.$s){
                  right:20px;
                  @include mixins.vertical-align(absolute);
            }
      }

      .menu-bar__logotype {
            @include mixins.vertical-align(absolute);
            left:40px;
            max-width:theme.$theme-logotype-minimized-width;

            @include breaker.below(grid.$s){
                  max-width:theme.$theme-logotype-mobile-width;
            }

            @include breaker.below(grid.$s){
                  left:20px;
            }
      }

      .site-menu__hamburger {
            @include mixins.vertical-align(absolute);
            right:40px;

            @include breaker.below(grid.$s){
                  right:20px;
            }
      }

      .site-menu__login {
            @include mixins.vertical-align(absolute);
            right:113px;

            @include breaker.below(grid.$s){
                  right:58px;
            }
      }

      .site-menu__account {
            @include mixins.vertical-align(absolute);
            right:113px;

            @include breaker.below(grid.$s){
                  right:58px;
            }
      }

      .site-menu__cart {
            @include mixins.vertical-align(absolute);
            right: 169px;

            @include breaker.below(grid.$s){
                  right:98px;
            }
      }

      .site-menu__returns {
            @include mixins.vertical-align(absolute);
            right: 230px;

            @include breaker.below(grid.$s){
                  right:136px;
            }
      }

      &--minimized,
      &--not-frontpage {
            background-color:theme.$theme-menu-bar-bg;
            background-image:theme.$theme-intro-background-image;
            background-position:center top;
            background-repeat:no-repeat;
            background-size:cover;
      }

      &--minimized {
            height:101px;

            @include breaker.below(grid.$s){
                  height:70px;
            }

            .menu-bar__logotype {
                  @include mixins.vertical-align(absolute);
                  max-width:theme.$theme-logotype-minimized-width;

                  @include breaker.below(grid.$s){
                        max-width:theme.$theme-logotype-mobile-width;
                  }
            }

            .site-menu__hamburger {
                  @include mixins.vertical-align(absolute);
		}

		.site-menu__cart {
			@include mixins.vertical-align(absolute);
		}

		.site-menu__returns {
			@include mixins.vertical-align(absolute);
		}

		.site-menu__account {
			@include mixins.vertical-align(absolute);
		}

            .site-menu__login {
                  @include mixins.vertical-align(absolute);
            }
	}
}