@use "theme/theme";

.breadcrumbs {
      width:100%;
      display:inline-block;
      margin-bottom:20px;

      a {
            font-size:12px;
            line-height:24px;
            color:theme.$black;
      }

      span {
            font-size:12px;
            line-height:24px;
      }
}