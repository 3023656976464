@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.account-information {
      background-color:theme.$white;
      max-width:1280px;
      width:100%;
      margin:0 auto;
      padding:40px;
      display:inline-block;

      @include breaker.below(grid.$s){
            padding:20px;
      }

      a {
            color: theme.$black;
            transition: opacity .2s ease-in-out;

            &:hover {
                  opacity: 0.6;
            }
      }

      &__bg {
            background-color:#F4F4F4;
            padding:40px;
            margin-bottom:80px;

            @include breaker.below(grid.$s){
                  padding:20px;
                  margin-bottom:40px;
            }
      }

      &__title {
            font-size:36px;
            line-height:42px;
            @include mixins.parsefont(theme.$theme-heavy-font);
            text-align:center;
            margin-bottom:40px;

            @include breaker.below(grid.$s){
                  font-size: 24px;
                  line-height: 31px;
                  margin-bottom:20px;
            }
      }

      section {
            margin-bottom:40px;
            border-bottom:1px solid #EBEBEB;

            &.first {
                  border-top:1px solid #EBEBEB;
                  padding-top:40px;

                  @include breaker.below(grid.$s){
                        padding-top:20px;
                  }
            }

            @include breaker.below(grid.$s){
                  margin-bottom:20px;
            }
      }

      &__section-title {
            font-size:24px;
            line-height:28px;
            @include mixins.parsefont(theme.$theme-heavy-font);
            margin-bottom:40px;

            @include breaker.below(grid.$s){
                  font-size:18px;
                  line-height:24px;
                  margin-bottom:20px;
            }

            &--large {
                  font-size: 36px;
                  line-height: 42px;
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  margin-bottom: 40px;

                  @include breaker.below(grid.$s) {
                        font-size: 24px;
                        line-height: 31px;
                        margin-bottom: 20px;
                  }
            }
      }

      &__form-errors,
      &__form-errors__mirror
      {
            color:#cc0000;
            padding:20px 0 0 0;
            display:inline-block;
            width:100%;

            &__mirror {
                  padding: 0 0 20px 0;
            }
      }

      &__input {
            display:block;
            margin-bottom:40px;

            ul {
                  list-style:none;
            }

            li {
                  color:#cc0000;
                  margin-bottom:20px;
                  width:100%;
            }

            &--textarea {
                  margin-top:66px;
            }

            p {
                  font-size:16px;
                  line-height:20px;
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  margin-bottom:5px;
            }

            span {
                  font-size:12px;
                  line-height:20px;
                  @include mixins.parsefont(theme.$theme-semibold-font);
                  display:block;
                  margin-bottom:10px;
            }
      }

      &__select {
            display:block;
            margin-bottom:40px;

            span {
                  font-size:12px;
                  line-height:20px;
                  @include mixins.parsefont(theme.$theme-semibold-font);
                  display:block;
                  margin-bottom:10px;
            }

            p {
                  font-size:16px;
                  line-height:20px;
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  margin-bottom:5px;
            }

            ul {
                  list-style:none;
            }

            li {
                  color:#cc0000;
                  margin-bottom:20px;
                  width:100%;
            }
      }

      &__textarea {
            display:block;
            margin-bottom:40px;

            ul {
                  list-style:none;
            }

            li {
                  color:#cc0000;
                  margin-bottom:20px;
                  width:100%;
            }

            span {
                  font-size:12px;
                  line-height:20px;
                  @include mixins.parsefont(theme.$theme-semibold-font);
                  display:block;
                  margin-bottom:10px;
            }

            p {
                  font-size:16px;
                  line-height:20px;
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  margin-bottom:5px;
            }

            textarea {
                  width:100%;
                  height:100px;
                  display:block;
                  resize:none;
                  border:1px solid theme.$bordergray;
                  padding:10px 20px;
                  outline:none;
            }
      }

      &__checkbox {
            display:block;
            margin-bottom:40px;
            position:relative;
            padding-left:38px;

            @include breaker.below(grid.$s){
                  margin-bottom:20px;
            }

            &.error {
                  color:#cc0000;

                  span {
                        border:1px solid #cc0000;
                  }
            }

            a {
                  color:theme.$black;
                  transition:opacity .2s ease-in-out;
                  &:hover {
                        opacity:0.6;
                  }
            }

            &--policy-text {
                  margin-bottom:17px;
            }

            &:hover {
                  cursor:pointer;
            }

            span {
                  position: absolute;
                  top: 1px;
                  left: 0;
                  height: 18px;
                  width: 18px;
                  border:1px solid theme.$black;

                  &:after {
                        content:'';
                        position: absolute;
                        display: none;
                  }
            }

            &:hover {

            }

            input {
                  position:absolute;
                  opacity:0;
                  cursor:pointer;

                  &:checked ~ span {}

                  &:checked ~ span:after {
                        display:block;
                  }
            }

            span:after {
                  width:12px;
                  height:10px;
                  @include mixins.align-both(absolute);
                  background-image:url('../img/icon-checkmark.svg');
                  background-size:12px 10px;
                  background-repeat:no-repeat;
                  background-position:center center;
            }
      }

      &__policy-text {
            font-size:12px;
            line-height:24px;
            margin-bottom:40px;
            display:block;

            &--nmb {
                  margin-bottom:0;
            }
      }

      input[type="text"], input[type="password"]{
            width:100%;
            border:1px solid theme.$bordergray;
            outline:none;
            padding:10px 20px;
            font-size:16px;
            line-height:28px;
      }

      select {
            appearance: none;
            width:100%;
            text-align:center;
            border:none;
            border:1px solid theme.$black;
            padding:10px 20px;
            background-color:theme.$white;
            border-radius:0;
            font-size:16px;
            line-height:28px;
            background-image:url('../img/icon-select.svg');
            background-repeat:no-repeat;
            background-position:center right 30px;
      }

      .button {
            margin-right:20px;
      }
}