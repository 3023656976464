/**
 * @license
 * MyFonts Webfont Build ID 3806712, 2019-09-12T05:04:23-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Bely-Bold by TypeTogether
 * URL: https://www.myfonts.com/fonts/type-together/bely/bold/
 *
 * Webfont: Bely-Italic by TypeTogether
 * URL: https://www.myfonts.com/fonts/type-together/bely/italic/
 *
 * Webfont: Bely-Regular by TypeTogether
 * URL: https://www.myfonts.com/fonts/type-together/bely/regular/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3806712
 * Licensed pageviews: 500,000
 * Webfonts copyright: Copyright &#x00A9; 2015 by Roxane Gataud. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3a15f8");


@font-face {font-family: 'Bely-Bold';src: url('../fonts/romanusselling/3A15F8_0_0.eot');src: url('../fonts/romanusselling/3A15F8_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/romanusselling/3A15F8_0_0.woff2') format('woff2'),url('../fonts/romanusselling/3A15F8_0_0.woff') format('woff'),url('../fonts/romanusselling/3A15F8_0_0.ttf') format('truetype');}


@font-face {font-family: 'Bely-Italic';src: url('../fonts/romanusselling/3A15F8_1_0.eot');src: url('../fonts/romanusselling/3A15F8_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/romanusselling/3A15F8_1_0.woff2') format('woff2'),url('../fonts/romanusselling/3A15F8_1_0.woff') format('woff'),url('../fonts/romanusselling/3A15F8_1_0.ttf') format('truetype');}


@font-face {font-family: 'Bely-Regular';src: url('../fonts/romanusselling/3A15F8_2_0.eot');src: url('../fonts/romanusselling/3A15F8_2_0.eot?#iefix') format('embedded-opentype'),url('../fonts/romanusselling/3A15F8_2_0.woff2') format('woff2'),url('../fonts/romanusselling/3A15F8_2_0.woff') format('woff'),url('../fonts/romanusselling/3A15F8_2_0.ttf') format('truetype');}
