@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.newsletter {
      background-color:theme.$black;
      padding:40px;
	margin-bottom:40px;

	@include breaker.below(grid.$s){
		padding:20px;
	}

      @supports (display: grid) {
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            width:100%;
            grid-column-gap: 100px;

		@include breaker.below(1110px){
			grid-template-columns:100%;
			grid-column-gap:50px;
		}

		@include breaker.below(grid.$s){
			grid-template-columns: 100%;
			grid-column-gap:0;
		}
      }

      &__title-small {
            font-size:24px;
            line-height:34px;
            color:theme.$white;
		margin-bottom:40px;

		@include breaker.below(grid.$s){
			font-size: 18px;
			line-height: 34px;
			margin-bottom:20px;
		}

            img {
                  float:right;
			margin-top:-5px;
                  max-width: 100%;

			@include breaker.below(grid.$s){
				display:none;
			}
            }
      }

      &__title-large {
            color:theme.$white;
            font-size: 32px;
            line-height: 42px;
            margin-bottom:40px;
		text-align:center;

		@include breaker.below(grid.$s){
			font-size:24px;
			line-height:32px;
			margin-bottom:20px;
		}
      }

      &__col {
		padding:40px;

		@include breaker.below(1110px){
			display:flex;
			display:block;
			&:nth-child(1){
				order:2;
			}
			&:nth-child(2){
				order:1;
			}
		}

		@include breaker.below(600px){
			padding:20px;
		}

            &--border {
                  border:1px solid #474A4D;
            }
      }

      label {
            width:100%;
            color:theme.$white;
            display:block;
            font-size:18px;
            line-height:24px;
		margin-bottom:10px;

		@include breaker.below(grid.$s){
			font-size:14px;
			line-height:24px;
		}
      }

      input {
            max-width:560px;
            width:100%;
            display:block;
            padding:14px 20px;
            font-size:18px;
            line-height:24px;
            margin-bottom:40px;
		outline:none;

		@include breaker.below(grid.$s){
			padding: 8px 10px;
			font-size:14px;
			line-height:24px;
			margin-bottom:20px;
		}
      }

      &__error-message {
            color: red;
      }

      &__success-message {
            color: white;
      }

      &__link {
            color:theme.$white;
            font-size:18px;
            line-height:24px;
            transition:opacity .2s ease-in-out;
            position:relative;
            padding-right:36px;

            &:after {
                  content:'';
                  width:19px;
                  height:21px;
                  background-image:url('../img/icon-arrow-right-white.svg');
                  position:absolute;
                  right:0;
                  top:0;
            }

            &:hover {
                  opacity:0.6;
		}

		@include breaker.below(grid.$s){
			font-size:14px;
			line-height:21px;
		}
      }

      &__integrity_policy_link {
            font-size: 16px;
            display: block;
            float: right;
            color: theme.$white;
      }

      &__people {
            margin-bottom:30px;
            display:flex;
            flex-direction: row;
            justify-content: center;

		@include breaker.below(grid.$s){
			margin-bottom:20px;
		}
      }

      &__person {
            text-align:center;
            padding:0 10px;
            width:20%;


            img {
                  max-width:64px;
                  width:100%;
                  border-radius:32px;
			margin-bottom:14px;


			@include breaker.below(1110px){
				max-width:100%;
                        border-radius:50%;
                  }

                  @include breaker.below(grid.$s){
                        margin-bottom:0;
                  }
            }
            p {
                  color:theme.$white;
                  font-size:14px;
                  line-height:20px;
                  @include breaker.below(grid.$s){
                        display:none;
                  }
            }
      }

      &__content {
		text-align:center;

		@include breaker.below(grid.$s){
			margin-bottom:20px;
		}

            strong {
                  @include mixins.parsefont(theme.$theme-heavy-font);
            }
            p {
                  color:theme.$white;
                  font-size:14px;
                  line-height:22px;
            }
      }
}