@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.rooms-teaser {
      background-color:#E6E4DF;
      margin-bottom:40px;

      &__title {
            font-size:24px;
            line-height:29px;
		margin-bottom:80px;

		@include breaker.below(grid.$s){
			font-size:18px;
			line-height:24px;
		}
      }

      &__padding {
		padding:40px;

		@include breaker.below(grid.$s){
			padding:20px;
		}
      }

      &__rooms {
            margin-bottom:40px;

            @supports (display: grid) {
                  display:grid;
                  grid-template-columns: repeat(4, 1fr);
                  width:100%;
			grid-column-gap: 40px;

                  @include breaker.below(800px) {
                        grid-template-columns: repeat(2, 1fr);
                        grid-row-gap: 40px;
                  }

			@include breaker.below(grid.$s){
				grid-template-columns:100%;
			}
            }
      }

      &__room {
            text-align:center;
      }

      a {
            color:inherit;
            text-decoration:none;
            transition:opacity .2s ease-in-out;

            &:hover {
                  opacity:0.6;
            }
      }

      h3 {
            font-size:18px;
            line-height:24px;
            margin-bottom:10px;
            text-decoration:underline;
      }

      p {
            @include mixins.parsefont(theme.$theme-semibold-font);;
            font-size:14px;
            line-height:20px;
      }

      img {
		max-width:94px;
		width:100%;
            margin-bottom:20px;
      }


      &__pattern {
            height:0;
		padding-bottom: 50.148%;
		background-size:cover;
		background-position:center center;
      }
}