@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.employer-branding {
      background-color:#1C2024;
      margin-bottom:54px;

      @include breaker.below(grid.$s){
            margin-bottom:40px;
      }

      &--light {
            background-color: #E5E4DE;

            .employer-branding__intro {
                  color:#000;

                  a {
                        color:#000;
                  }
            }

            .employer-branding__articles__column {
                  color:#000;
            }

            .employer-branding__icons {
                  background-color:#F2F2EF;
            }

            .employer-branding__icons-column span{
                  color:#000;
            }

            .employer-branding__icons-column {
                  &:hover {
                        background-color:#E5E4DE;
                  }
            }

            .employer-branding__icons-column__links {
                  background-color:#E5E4DE;
            }

            .employer-branding__icons-column__links a {
                  color:#000;
            }

            .employer-branding__intro a svg path, .employer-branding__icons-column span svg path{
                  fill:#000;
            }

      }

      &__intro {
            color:#fff;
            display:flex;
            flex-direction: row;
            padding:60px 70px;
            margin:0 -20px;

            @include breaker.below(grid.$s){
                  padding:20px;
                  flex-direction: column;
            }

            p {
                  font-size:20px;
                  line-height:30px;
            }

            a {
                  font-size:16px;
                  line-height:24px;
                  color:#fff;
                  display: flex;
                  align-items: center;
                  margin-top:auto;

                  &:hover {
                        text-decoration:none;
                  }

                  svg {
                        margin-right:10px;
                  }

                  @include breaker.below(grid.$s){
                        margin-top:10px;
                  }
            }

            div {
                  width:100%;
                  max-width:700px;
                  padding:0 20px;

                  a {
                        margin-top: 10px;
                  }
            }
      }

      &__articles {
            margin:0 -20px 40px -20px;
            padding: 0 70px;
            display:flex;
            flex-direction: row;
            flex: 0 auto;
            justify-content:center;
            align-items:center;
            align-content:center;

            @include breaker.below(grid.$s){
                  padding:20px;
                  flex-direction: column;
                  margin:0;
            }
      }

      .employer-branding__articles__image {
            overflow:hidden;
            margin-bottom:10px;
      }

      &__articles__column {
            width:33.333%;
            padding:0 20px;
            text-decoration:none;
            color:#fff;
            overflow:hidden;

            @include breaker.below(grid.$s){
                  padding:0;
                  width:100%;
                  margin-bottom:40px;

                  &:last-child {
                        margin-bottom:0;
                  }
            }

            &:hover {
                  text-decoration:underline;

                  img {
                        transform:scale(1.05);
                  }
            }

            img {
                  width:100%;
                  height:auto;
                  transition:transform 1s ease-in-out;
            }

            span {
                  font-size:20px;
                  line-height:27px;
                  font-weight:500;
            }
      }

      &__icons {
            display: flex;
            flex-direction: row;
            background-color:#333638;

            @include breaker.below(grid.$s){
                  flex-wrap:wrap;
                  padding:20px 0;
            }
      }

      &__icons-column {
            width:20%;
            padding:20px;
            display:flex;
            flex-direction: column;
            position:relative;

            @include breaker.below(grid.$s){
                  width:50%;
            }

            &:hover {
                  cursor:pointer;
                  background-color:#1C2024;
                  .employer-branding__icons-column__links {
                        display:flex;
                  }

                  span {
                        text-decoration: underline;
                  }
            }

            img {
                  height:68px;
                  margin-bottom:20px;

                  @include breaker.below(grid.$s){
                        height:50px;
                  }
            }

            span {
                  color:#fff;
                  font-size:16px;
                  line-height:24px;
                  text-align: center;
                  font-family: 'Conv_Aileron-SemiBold';
                  display: flex;
                  align-items: center;
                  align-self: center;

                  svg {
                        margin-right:10px;

                        @include breaker.below(grid.$s){
                              width:10px;
                              height:10px;
                        }
                  }

                  @include breaker.below(grid.$s){
                        font-size:12px;
                        line-height:20px;
                  }
            }
      }

      &__icons-column__links {
            position:absolute;
            left:0;
            right:0;
            display:none;
            top:100%;
            background-color:#1C2024;
            flex-direction: column;
            padding:20px;
            z-index:3;

            a {
                  color:#fff;
                  font-size:16px;
                  line-height:24px;
                  margin-bottom:6px;

                  &:hover {
                        text-decoration:none;
                  }
            }
      }

}