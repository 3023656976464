@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.article {
      max-width:830px;
      width:100%;
      margin:0 auto;
      position:relative;

      &__print {
            &__title {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:12px;
                  line-height:24px;
                  margin-bottom:20px;

                  @include breaker.below(1100px){
                        margin-bottom:10px;
                  }
            }

            ul {
                  list-style:none;
            }

            &__icon {
                  display:inline-block;
                  width:46px;
                  height:46px;
                  background-size:36px 36px;
                  background-position:center center;
                  border: 2px solid black;
                  border-radius: 23px;

                  @include breaker.below(grid.$s){
                        width:32px;
                        height:32px;
                        background-size:32px 32px;
                  }

                  background-image:url('../img/icon-print-black.svg');

                  &:hover {
                        //background-image:url('../img/icon-print-white.svg');
                  }
            }
      }

      &__share {
            position:absolute;
            right:-117px;
            top:80px;
            z-index:2;

            @include breaker.below(1100px){
                  position:static;
                  max-width:750px;
                  width:100%;
                  margin:0 auto;
                  margin-bottom:20px;
            }

            ul {
                  list-style:none;
            }

            &__title {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:12px;
                  line-height:24px;
                  margin-bottom:20px;

                  @include breaker.below(1100px){
                        margin-bottom:10px;
                  }
            }

            li {
                  margin-bottom:10px;
                  height:46px;
                  width:46px;

                  @include breaker.below(1100px){
                        display:inline-block;
                        margin-bottom:0;
                        margin-right:10px;
                  }

                  @include breaker.below(grid.$s){
                        width:32px;
                        height:32px;
                  }
            }

            &__share-btn {
                  display:inline-block;
                  width:46px;
                  height:46px;
                  background-size:46px 46px;
                  background-position:center center;

                  @include breaker.below(grid.$s){
                        width:32px;
                        height:32px;
                        background-size:32px 32px;
                  }
            }

            &__facebook {
                  background-image:url('../img/icon-facebook-black.svg');

                  &:hover {
                        background-image:url('../img/icon-facebook-white.svg');
                  }
            }

            &__twitter {
                  background-image:url('../img/icon-twitter-black.svg');
                  &:hover {
                        background-image:url('../img/icon-twitter-white.svg');
                  }
            }

            &__linkedin {
                  background-image:url('../img/icon-linkedin-black.svg');

                  &:hover {
                        background-image:url('../img/icon-linkedin-white.svg');
                  }
            }
      }


      &__breadcrumbs {
            border-bottom:1px solid #EBEBEB;
            margin-bottom:20px;
      }

      &__description {
            max-width:750px;
            width:100%;
            margin:0 auto;
            margin-bottom:20px;

            p {
                  font-size:16px;
                  line-height:30px;
            }

            strong {
                  @include mixins.parsefont(theme.$theme-heavy-font);
            }

            span {
                  font-size:12px;
                  line-height:24px;
            }
      }

	.photo-cred {
		position:absolute;
		top:0px;
		left:0px;
		color:theme.$white;
		font-size:14px;
		background-color: rgba(0, 0, 0, 0.75);
		padding:5px 10px;
	}

      &__image {
            //background-color:theme.$theme-site-standard-color;
            max-width:800px;
            width:100%;
            margin:0 auto;
            margin-bottom:40px;
            position:relative;

            @include breaker.below(grid.$s){
                  margin-bottom:20px;
		}

            img {
                  width:100%;
                  height:auto;
            }

            p {
                  position:absolute;
                  bottom:0px;
                  left:0px;
                  right:auto;
                  padding:20px;
                  background-color:rgba(0, 0, 0, 0.75);
                  color:theme.$white;
                  font-size:16px;
                  line-height:26px;

                  @include breaker.below(grid.$s){
                        bottom:20px;
                        left:20px;
                        right:20px;
                        font-size:12px;
                        line-height:16px;
                        padding:10px;
                  }
            }
      }

      &__content {
            max-width:750px;
            width:100%;
            margin:0 auto;
            padding-bottom:20px;
            border-bottom:1px solid #EBEBEB;
            margin-bottom:60px;

            .wp-caption-text{
                  font-size:12px;
                  margin-bottom:0;
            }

            .alignleft {
                  float:left;
			margin:9px 30px 0px 0;

			@include breaker.below(grid.$s){
				width:100%;
                        height:auto;
                        margin-bottom:15px;
			}
            }

            .alignright {
                  float:right;
			margin:9px 0 0 30px;

			@include breaker.below(grid.$s){
				width:100%;
                        height:auto;
                        margin-bottom:15px;
			}
            }

		img.alignright {
			float:right;
			margin:9px 0 30px 30px;

			@include breaker.below(grid.$s){
				width:100%;
				height:auto;
			}
		}
		img.alignleft {
			float:left;
			margin:9px 30px 30px 0;

			@include breaker.below(grid.$s){
				width:100%;
				height:auto;
			}
		}
		img.aligncenter {
			display: block;
			margin-left: auto;
			margin-right: auto;

			@include breaker.below(grid.$s){
				width:100%;
				height:auto;
			}
		}
		a img.alignright {
			float:right;
			margin:9px 0 30px 30px;

			@include breaker.below(grid.$s){
				width:100%;
				height:auto;
			}
		}
		a img.alignleft {
			float:left;
			margin:9px 30px 30px 0;

			@include breaker.below(grid.$s){
				width:100%;
				height:auto;
			}
		}
		a img.aligncenter {
			display: block;
			margin-left: auto;
			margin-right: auto;

			@include breaker.below(grid.$s){
				width:100%;
				height:auto;
			}
		}

            h1 {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:50px;
                  line-height:60px;
                  margin-bottom:30px;

                  @include breaker.below(grid.$s){
                        font-size:34px;
                        line-height:40px;
                        margin-bottom:20px;
                  }
            }

            h2 {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:28px;
                  line-height:38px;
                  margin-bottom:20px;
            }

            h3 {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:24px;
                  line-height:32px;
                  margin-bottom:15px;
            }

            h4,h5,h6 {
                  @include mixins.parsefont(theme.$theme-heavy-font);
            }

            p {
                  font-size:20px;
                  line-height:34px;
			margin-bottom:15px;

                  @include breaker.below(grid.$s){
                        font-size:16px;
                        line-height:26px;
                  }
            }

            blockquote p {
                  font-size:34px;
                  line-height:46px;
                  @include mixins.parsefont(theme.$theme-heavyitalic-font);
            }

            strong {
                  @include mixins.parsefont(theme.$theme-heavy-font);
            }

            a {
                  color:theme.$black;
            }

            ul {
                  margin-bottom:30px;
                  list-style:none;

                  li {
                        font-size:16px;
                        line-height:28px;
                        margin-bottom:15px;
                        position:relative;
                        padding-left:20px;

                        @include breaker.below(grid.$s){
                              font-size:16px;
                              line-height:26px;
                              margin-bottom:10px;
                              padding-left:30px;
                        }

                        &:before {
                              content:'';
                              width:6px;
                              height:6px;
                              position:absolute;
                              top:11px;
                              left:0;
                              background-color:theme.$theme-site-standard-color;
                              border-radius:12px;
                        }
                  }
            }

            i, em {
                  @include mixins.parsefont(theme.$theme-regular-italic-font);
            }

            ol {
                  margin-bottom:30px;
                  padding-left:20px;

                  @include breaker.below(grid.$s){
                        padding-left:15px;
                  }

                  li {
                        font-size:20px;
                        line-height:34px;
                        margin-bottom:30px;
                        position:relative;

                        @include breaker.below(grid.$s){
                              font-size:16px;
                              line-height:26px;
                              margin-bottom:10px;
                        }
                  }
            }
      }

      &__tags {
            max-width:750px;
            width:100%;
            margin:0 auto;
            margin-bottom:80px;

            .book-content__tags {
                  width:100%;
                  display:flex;
                  flex-direction: row;
                  flex-wrap:wrap;
            }

            h3 {
                  font-size:20px;
                  line-height:34px;
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  margin-bottom:20px;
            }

            &__tag {
                  font-size:12px;
                  line-height:12px;
                  color:theme.$white;
                  background-color:theme.$black;
                  padding:4px 10px;
                  text-decoration:none;
                  position:relative;
                  margin-right:18px;
                  margin-bottom:10px;

                  &:after {
                        content:'';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 10px 0 10px 8px;
                        border-color: transparent transparent transparent theme.$black;
                        position:absolute;
                        top:0;
                        right:-8px;
                  }

                  &:before {
                        content:'';
                        width:4px;
                        height:4px;
                        background-color:theme.$white;
                        border-radius:2px;
                        @include mixins.vertical-align(absolute);
                        right:0;
                        z-index:10;
                  }

                  &:hover {
                        text-decoration:underline;
                  }
            }
      }

      &__files {
            max-width: 750px;
            width: 100%;
            margin: 0 auto 60px;


            h3 {
                  font-family: "freight-sans-pro";
                  font-weight: 700;
                  font-style: normal;
                  font-size: 36px;
                  line-height: 40px;
                  margin-bottom: 30px;
            }

            &__file {
                  margin-top: 20px;
                  img {
                        margin-right: 20px;
                  }

                  a {
                        text-decoration: none;
                        color:theme.$black;
                  }

                  .description {
                        display: inline-block;
                        h4 {
                              font-family: "freight-sans-pro";
                              font-weight: 700;
                              font-style: normal;
                              font-size: 20px;
                              margin-bottom: 3px;
                        }

                        p {

                        }
                  }
            }
      }
}

.single-news {
      .article {
            &__image {
                  padding-bottom: 28px;
                  .photo-cred {
                        top:auto;
                        bottom: 0;
                        width: 100%;
                        background-color: black;
                        min-height: 28px;
                  }
            }
      }
}