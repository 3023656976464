@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.notfound {
      max-width:720px;
      width:100%;
      margin:0 auto;
      min-height:720px;
      display:grid;
      align-items:center;

      @include breaker.below(grid.$s){
            min-height:auto;
            padding:20px 0 40px 0;
      }

      h1 {
            font-size:36px;
            line-height:42px;
            margin-bottom:20px;
      }

      h2 {
            font-size:24px;
            line-height:28px;
            margin-bottom:10px;
      }

      ul {
            margin-bottom: 30px;
            list-style: none;
      }

      li {
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 5px;
            position: relative;
            padding-left: 20px;

            &:before {
                  content: '';
                  width: 6px;
                  height: 6px;
                  position: absolute;
                  top: 11px;
                  left: 0;
                  background-color: #205F58;
                  border-radius: 12px;
            }
      }

      &__buttons {

      }
}