@use "theme/theme";
@use "mixins";
@use "breaker";
@use "grid";

.all-search {
  padding: 24px;
  background-color: #e5e4df;
  box-sizing: border-box;
  max-width: 100%;

  section {
    margin-bottom: 64px;
  }

  &__section-title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 24px;
    display: inline-block;
    text-decoration: none;
    color: #1c2024;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 3px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: #1c2024;
    }
  }

  .all-search__grid > * {
    min-width: 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 1.5rem;
    align-items: end;

    @include breaker.below(1024px) {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 10px;
    }

    @include breaker.below(grid.$s) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breaker.below(320px) {
      grid-template-columns: repeat(2, 1fr);
    }

    &--three-columns {
      align-items: start;
      grid-template-columns: repeat(3, 1fr);
      @include breaker.below(grid.$m) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &--columns {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
  }

  &__page {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #1c2024;
    color: inherit;
    text-decoration: none;

    &:hover h2 {
      text-decoration: underline;
    }

    h2 {
      font-size: 22px;
      line-height: 28px;
      color: theme.$theme-news-title-color;
      @include mixins.parsefont(theme.$theme-heavy-font);

      @include breaker.below(grid.$m) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  &__book {
    &__link {
      color: inherit;
      text-decoration: none;

      &:hover .all-search__book__title {
        opacity: 0.6;
      }
    }

    &__cover {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }

    &__title {
      text-decoration: underline;
      font-size: 16px;
      line-height: 24px;
      transition: opacity 0.2s ease-in-out;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      transition: opacity 0.2s ease-in-out;
    }

    &__author-wrap {
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__author {
      font-size: 16px;
      line-height: 24px;
      color: #a9a9a9;
      transition: opacity 0.2s ease-in-out;
      white-space: nowrap;

      &:hover {
        opacity: 0.6;
      }

      &--no-link {
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__author {
    &__link {
      color: inherit;
      text-decoration: none;
      position: relative;

      &:hover .all-search__author__title {
        opacity: 0.6;
      }
    }
    &__cover {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }

    &__initials-cover-wrap{
      margin-bottom:20px;
      position:relative;
      height:285px;
      overflow:hidden;

      @include breaker.below(grid.$s){
            height:145px;
      }

      @include breaker.below(320px){
            height:200px;
      }
    }

    &__initials {
      @include mixins.align-both(absolute);
      font-size:60px;
      line-height:60px;
      @include mixins.parsefont(theme.$theme-heavy-font);
    }

    &__title {
      text-decoration: underline;
      font-size: 16px;
      line-height: 24px;
      transition: opacity 0.2s ease-in-out;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      transition: opacity 0.2s ease-in-out;
    }
  }

  &__see-more {
    text-align: right;
    margin-top: 24px;
    a {
      font-size: 16px;
      line-height: 24px;
      color: #1c2024;
      text-decoration: underline;
      display: inline-block;
    }
  }

  .news__item {
    margin-bottom: 0;
  }
}
