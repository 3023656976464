@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.footer {
      background-color:theme.$black;
      color:#fff;
      padding:40px 0;

      .flex {
            display:flex;
      }

      .align-center {
            align-items: center;
      }

      .flex-row {
            flex-direction: row;
            flex-wrap:wrap;

            @include breaker.below(grid.$s){
                  flex-direction: column;
            }
      }

      .flex-col {
            flex-direction: column;
            flex-wrap:wrap;
      }

      .w-1-2 {
            width:50%;

            @include breaker.below(grid.$m){
                  width:100%;
            }
      }

      .w-full {
            width: 100%;
      }

      .w-4-5 {
            width:80%;

            @include breaker.below(grid.$m){
                  width:100%;
            }
      }

      .w-8-12 {
            width:66.666667%;

            @include breaker.below(grid.$m){
                  width:100%;
            }
      }

      .bold {
            font-weight:bold;
      }

      .text-17 {
            font-size:17px;
            line-height: 27px;
      }

      .text-white {
            color:#fff;
      }

      .text-inherit {
            color: inherit;
      }

      .hover-underline {
            text-decoration:none;

            &:hover {
                  text-decoration:underline;
            }
      }

      .hover-no-underline {
            &:hover {
                  text-decoration: none;
            }
      }

      .ml-auto {
            margin-left:auto;

            @include breaker.below(grid.$m){
                  margin-left:0;
            }
      }

      .mb-20 {
            margin-bottom:20px;
      }
      .m-mb-40 {
            @include breaker.below(grid.$m){
                  margin-bottom:40px;
            }
      }
      .mb-40 {
            margin-bottom:40px;
      }

      .mr-20 {
            margin-right:20px;
      }

      .-mx-20 {
            margin:0 -40px;
      }

      .pr-20 {
            padding-right:20px;
      }

      .pr-40 {
            padding-right:40px;

            @include breaker.below(grid.$m){
                  padding-right:0;
            }
      }

      .pb-20 {
            padding-bottom:20px;
      }

      .px-20 {
            padding-left:40px;
            padding-right:40px;
      }

      .border-top {
            border-top:1px solid #fff;
            padding-top:40px;
      }

      .footer-social {
            display: flex;

            .footer-social-item {
                  margin-left: 20px;

                  &:first-child {
                        margin-left: 0;
                  }
            }
      }

      .footer-menu {
            .menu-item {
                  margin-right: 20px;
            }
            @include breaker.below(grid.$m) {
                  flex-direction: column;

                  .menu-item {
                        margin-right: 0;
                        margin-bottom: 10px;
                  }
            }


      }

      .footer-excerpt {
            a { color: inherit; }
      }
}