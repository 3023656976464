@use "mixins";
@use "breaker";
@use "grid";
@use "theme/theme";

.miniaccount {
	max-width:305px;
	width:100%;
	padding:30px;
	background-color:white;
	position:absolute;
	right: 89px;
	box-shadow: 0 0 30px 0 rgba(0,0,0,0.15);
	opacity:0;
	transform:translateY(-100%);
	transition:transform .4s ease-in-out, opacity .4s ease-in-out;

	@include breaker.below(grid.$s){
		right:0px;
		left:0px;
		max-width:100%;
	}

	&--active {
		opacity:1;
		transform:translateY(160px);

		@include breaker.below(grid.$s){
			transform:translateY(70px);
		}
	}

	h2 {
		text-align:center;
		font-size:20px;
		line-height:26px;
		@include mixins.parsefont(theme.$theme-heavy-font);
		margin-bottom:20px;
	}

	ul {
		list-style:none;
		padding-bottom:30px;
		margin-bottom:30px;
		border-bottom:1px solid #EBEBEB;
	}

	li {
		margin-bottom:10px;

		&:last-child {
			margin-bottom:0;
		}

		a {
			color:theme.$black;
			transition:opacity .2s ease-in-out;
			&:hover {
				opacity:0.6;
			}
		}
	}

      &__info {
            margin-bottom: 15px;

            p {
                  margin-bottom: 5px;
                  font-size: 15px;
            }
      }

	.button {
		width:100%;
		text-align:center;
	}
}