@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.author {
      max-width:1360px;
      width:100%;
      margin:0 auto;
      background-color:#F4F4F4;
      padding:40px;
      margin-bottom:40px;
      padding-bottom:100px;

      @include breaker.below(grid.$s){
            padding:20px;
      }

      &--is-desktop {
            @supports (display: grid) {
                  display:grid;
                  grid-template-columns:29.6875% 65.625%;
                  grid-column-gap: 60px;

                  @include breaker.below(grid.$s){
                        grid-template-columns:100%;
                  }
            }
            @include breaker.below(grid.$m){
                  display:none;
            }
      }

      &--is-mobile {
            display:none;
            @include breaker.below(grid.$m){
                  display:block;
            }

            .author__cover__photographer {
                  margin-top: 10px;
            }
      }

      &__cover {
            padding:40px;
            margin-bottom:10px;
            background-color:theme.$white;

            .caption {
                  display:none;
            }

            @include breaker.below(grid.$s){
                  padding:20px;
                  margin-bottom:20px;
            }

            img {
                  width:100%;
                  height:auto;
            }

            a {
                  position:relative;
                  &:after {
                        content:'';
                        width:33px;
                        height:33px;
                        position:absolute;
                        right:0;
                        top:0;
                        background-color:theme.$white;
                        background-image:url('../img/icon-magnifier.svg');
                        background-size:21px 21px;
                        background-position:center center;
                        background-repeat:no-repeat;
                  }
            }

            &__photographer {
                  font-size:12px;
                  line-height:12px;
                  display:inline-block;
            }
      }

      &__sidebar-title {
            @include mixins.parsefont(theme.$theme-heavy-font);
            font-size:20px;
            line-height:24px;
            margin-bottom:20px;
      }

      &__download-images {
            width:100%;
            margin-top:30px;

            @include breaker.below(grid.$m){
                  padding-bottom: 40px;
                  margin-bottom: 40px;
                  border-bottom: 1px solid #c7c7c7;
            }

            .caption {
                  display:none;
            }

            .grid {
                  @supports (display: grid) {
                        display: grid;
                        width:100%;
                        grid-template-columns: repeat(3, 1fr);
                        grid-column-gap: 20px;
                        grid-row-gap:20px;

                        @include breaker.below(grid.$s){
                              grid-template-columns:repeat(3, 1fr);
                              grid-column-gap: 10px;
                              grid-row-gap:10px;
                        }
                  }
            }

            &__image {
                  position:relative;
                  &:after {
                        content:'';
                        width:22px;
                        height:22px;
                        position:absolute;
                        right:0;
                        top:0;
                        background-color:theme.$white;
                        background-image:url('../img/icon-magnifier.svg');
                        background-size:14px 14px;
                        background-position:center center;
                        background-repeat:no-repeat;
                  }

                  img {
                        width:100%;
                        height:auto;
                  }
            }
      }

      &__content__intro {
            padding-bottom:40px;
            margin-bottom:40px;
            border-bottom:1px solid theme.$bordergray;
            h1 {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:60px;
                  line-height:70px;
                  margin-bottom:24px;
            }

            .sub-header {
                  margin-top: -18px;
                  font-weight: 700;
                  font-style: italic;
                  margin-bottom: 20px;
            }

            p {
                  font-size:16px;
                  line-height:28px;
			margin-bottom:15px;

                  &:last-child {
                        margin-bottom:0;
                  }
            }
      }

      &__content__books {
            padding-bottom:40px;
            margin-bottom:40px;
            border-bottom:1px solid theme.$bordergray;

            h2 {
                  font-size:20px;
                  line-height:28px;
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  margin-bottom:20px;
            }

            &__book {
                  img {
                        width:100%;
                        height:auto;
                        margin-bottom:20px;
                  }
                  a {
                        color:theme.$black;
                        font-size:16px;
                        line-height:24px;
                  }

                  p {
                        font-size:16px;
                        line-height:24px;
                  }
            }

            .grid {
                  @supports (display: grid) {
                        display:grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-column-gap: 40px;
                        row-gap:40px;
                        align-items:end;

                        @include breaker.below(grid.$s){
                              grid-template-columns:repeat(2, 1fr);
                        }
                  }
            }
      }

      &__content__news {
            &__section-title {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:20px;
                  line-height:28px;
                  margin-bottom:40px;
            }

            &__col {
                  span {
                        font-size:14px;
                        line-height:22px;
                        letter-spacing:1px;
                        margin-bottom:10px;
                        display:block;
                  }

                  h2 {
                        color:theme.$black;
                        @include mixins.parsefont(theme.$theme-heavy-font);
                        font-size:24px;
                        line-height:32px;
                        margin-bottom:24px;
                  }

                  a {
                        text-decoration:none;
                        color:theme.$black;

                        &:hover {
                              text-decoration:underline;
                        }
                  }
            }

            .grid {
                  @supports (display: grid) {
                        display:grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-column-gap: 40px;
                        row-gap:40px;
                        align-items:end;

                        @include breaker.below(grid.$s){
                              grid-template-columns:repeat(1, 1fr);
                        }
                  }
            }
      }
}