@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.plain-text {
      background-color:#F4F4F4;
      padding:40px;
      margin-bottom:40px;

      &__holder {
            background-color:theme.$white;
            padding:40px;
            max-width:673px;
            width:100%;
            margin:0 auto;
      }

      h1 {
            @include mixins.parsefont(theme.$theme-heavy-font);
            font-size:36px;
            line-height:42px;
            text-align:center;
            margin-bottom:44px;
            position:relative;

            &:after {
                  content:'';
                  width:30px;
                  height:4px;
                  background-color:theme.$theme-site-standard-color;
                  @include mixins.horizontal-align(absolute);
                  bottom:-24px;
            }
      }

      p {
            font-size:16px;
            line-height:28px;
            margin-bottom:40px;
      }

      a {
            color:theme.$black;
      }

      &__button-holder {
            border-top:1px solid #EBEBEB;
            padding-top:40px;
      }

      .button {
            width:100%;
            text-align:center;
      }
}