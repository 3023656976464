@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.book {
      max-width: 1360px;
      width: 100%;
      margin: 0 auto;
      background-color: #F4F4F4;
      padding: 40px;
      margin-bottom: 40px;

      @include breaker.below(grid.$s) {
            padding: 20px;
      }

      &--is-desktop {
            @supports (display: grid) {
                  display: grid;
                  grid-template-columns:29.6875% 65.625%;
                  grid-column-gap: 60px;

                  @include breaker.below(grid.$s) {
                        grid-template-columns:100%;
                  }
            }
            @include breaker.below(grid.$m) {
                  display: none;
            }
      }


      &--is-mobile {
            display: none;
            @include breaker.below(grid.$m) {
                  @supports (display: grid) {
                        display: grid;
                        grid-template-columns:1;
                        grid-column-gap: 30px;

                        @include breaker.below(grid.$s) {
                              grid-template-columns:100%;
                        }
                  }
            }
      }

      &__cover {
            text-align: center;
            padding: 40px;
            margin-bottom: 40px;
            background-color: theme.$white;

            @include breaker.below(grid.$s) {
                  padding: 20px;
                  margin-bottom: 30px;
            }

            .caption {
                  display: none;
            }

            img {
                  max-width: 100%;
                  height: auto;
            }

            a {
                  position: relative;

                  &:after {
                        content: '';
                        width: 33px;
                        height: 33px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-color: theme.$white;
                        background-image: url('../img/icon-magnifier.svg');
                        background-size: 21px 21px;
                        background-position: center center;
                        background-repeat: no-repeat;
                  }
            }
      }

      &__sidebar-title {
            @include mixins.parsefont(theme.$theme-heavy-font);
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 20px;

            @include breaker.below(grid.$s) {
                  font-size: 16px;
                  line-height: 28px;
            }
      }

      &__sidebar-section {
            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: 1px solid theme.$bordergray;

            @include breaker.below(grid.$s) {
                  margin-bottom: 20px;
                  padding-bottom: 20px;
            }
      }

      &__use-photos {
            font-size: 16px;
            line-height: 24px;
            color: theme.$black;
            margin-bottom: 40px;
            display: inline-block;

            @include breaker.below(grid.$s) {
                  margin-bottom: 20px;
            }
      }

      &__download-covers {
            ul {
                  padding-left: 20px;
            }

            a {
                  color: theme.$black;
                  line-height: 24px;
                  transition: opacity .2s ease-in-out;

                  &:hover {
                        opacity: 0.6;
                  }
            }
      }

      &__download-images {
            .grid {
                  @supports (display: grid) {
                        display: grid;
                        width: 100%;
                        grid-template-columns: repeat(3, 1fr);
                        grid-column-gap: 20px;
                        grid-row-gap: 20px;

                        @include breaker.below(grid.$s) {
                              grid-column-gap: 20px;
                              grid-row-gap: 20px;
                        }
                  }
            }

            .caption {
                  display: none;
            }

            &__image {
                  position: relative;

                  img {
                        width: 100%;
                        height: auto;
                  }

                  &:after {
                        content: '';
                        width: 22px;
                        height: 22px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-color: theme.$white;
                        background-image: url('../img/icon-magnifier.svg');
                        background-size: 14px 14px;
                        background-position: center center;
                        background-repeat: no-repeat;
                  }
            }
      }

      &__content__intro {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid theme.$bordergray;
            @include breaker.below(grid.$s) {
                  padding-bottom: 20px;
                  margin-bottom: 20px;
            }

            h1 {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size: 60px;
                  line-height: 66px;
                  margin-bottom: 10px;

                  @include breaker.below(grid.$s) {
                        font-size: 34px;
                        line-height: 42px;
                  }
            }

            .subtitle {
                  display: block;
                  margin-top: -5px;
                  margin-bottom: 10px;
                  font-size: 16px;
                  font-weight: 700;
            }

            &__author {
                  font-size: 24px;
                  line-height: 28px;
                  color: theme.$black;
                  margin-top: 10px;

                  a, p {
                        color: inherit;
                        display: inline-block;
                  }

                  .author-label {
                        font-weight: theme.$theme-book-author-label-font-weight;
                  }

                  @include breaker.below(grid.$s) {
                        margin-bottom: 0;
                  }
            }

            .button {
                  background-image: url('../img/icon-sound.svg');
                  background-repeat: no-repeat;
                  background-position: center left 30px;
                  padding: 12px 31px 12px 67px;

                  &--buy {
                        padding: 12px 31px;
                        background: theme.$theme-button-bg-color;
                        margin-top: 30px;
                        display: inline-block;

                        &:hover {
                              text-decoration: none;
                        }
                  }
            }
      }

      &__content {
            &__example {
                  padding-bottom: 40px;
                  margin-bottom: 20px;
                  border-bottom: 1px solid theme.$bordergray;

                  .button {
                        .site-menu__login__icon {
                              display: inline-block;
                              vertical-align: middle;
                              margin-right: 15px;
                        }
                  }

                  @include breaker.below(grid.$s) {
                        margin-bottom: 20px;
                        padding-bottom: 20px;
                  }

                  h2 {
                        @include mixins.parsefont(theme.$theme-heavy-font);
                        margin-bottom: 20px;
                        font-size: 20px;
                        line-height: 26px;

                        @include breaker.below(grid.$s) {
                              font-size: 16px;
                              line-height: 28px;
                        }
                  }

            p {
                  font-size: 16px;
                  line-height: 28px;
                  margin-bottom: 40px;
            }

            select, input[type=number] {

                  appearance: none;
                  border-radius: 0;
                  font-size: 16px;
                  line-height: 28px;
                  border: 1px solid theme.$black;
                  margin-right: 20px;
                  outline: none;
                  background-color: theme.$white;

                  @include breaker.below(grid.$s) {
                        margin-bottom: 20px;
                  }
            }

            select {
                  max-width: 395px;
                  width: 100%;
                  background-image: url('../img/icon-select.svg');
                  background-repeat: no-repeat;
                  background-position: right 20px center;
                  padding: 10px 20px;
            }

            input[type=number] {
                  max-width: 80px;
                  width: 100%;
                  padding: 10px;
            }
      }

            &__audio_sample {
                  padding-bottom: 20px;
                  margin-bottom: 20px;
                  border-bottom: 1px solid theme.$bordergray;
            }

            &__minprice {
                  margin-top: 25px;
            }
      }

      &__order__error {
            margin-top: 10px;
      }


      &__content__facts {
            margin-bottom:20px;

            @include breaker.below(grid.$s){
                  margin-bottom:0;
            }

            @supports (display: grid) {
                  display:grid;
                  grid-template-columns: repeat(2, 1fr);
                  grid-column-gap: 40px;
            }

            @include breaker.below(grid.$m){
                  @supports (display: grid) {
                        display:grid;
                        grid-template-columns: repeat(1, 1fr);
                        grid-column-gap: 40px;
                  }
            }

            &__facts-col {
                  padding-bottom:20px;
                  margin-bottom:20px;
                  border-bottom:1px solid theme.$bordergray;
                  font-style: theme.$theme-book-fact-value-font-style;

                  p {
                        font-size:20px;
                        line-height:26px;

                        @include breaker.below(grid.$s){
                              font-size:16px;
                              line-height:28px;
                        }
                  }

                  strong {
                        @include mixins.parsefont(theme.$theme-heavy-font);
                  }

                  a {
                        color:theme.$black;
                  }
            }
      }

      &__content__excerpt {
            padding-bottom:40px;
            margin-bottom:40px;
            border-bottom:1px solid theme.$bordergray;

            @include breaker.below(grid.$s){
                  padding-bottom:20px;
                  margin-bottom:20px;
            }

            p {
                  font-size:16px;
                  line-height:28px;
                  margin-bottom:40px;

                  @include breaker.below(grid.$s){
                        margin-bottom:20px;
                  }

                  &:last-child {
                        margin-bottom:0;
                  }
            }
      }

      &__content__recension {
            padding-bottom:40px;
            margin-bottom:40px;
            border-bottom:1px solid theme.$bordergray;

            p {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:16px;
                  line-height:28px;
                  font-weight: 500;
                  margin-bottom: 20px;
            }

            a {
                  color: theme.$black;
            }
      }

      &__content__quote {
            height: auto;
            transform: scaleY(1);

            &.more-quotes {
                  height: 0;
                  transform: scaleY(0);
                  transform-origin: top;
                  transition: transform 0.40s ease;
            }

            .open & {
                  height: 0;
                  transform: scaleY(0);

                  &.more-quotes {
                        height: auto;
                        transform: scaleY(1);
                  }
            }
      }

      &__content__details {
            padding-bottom:40px;
            margin-bottom:40px;
            border-bottom:1px solid theme.$bordergray;
            font-style: theme.$theme-book-details-value-font-style;

            h2 {
                  font-size:20px;
                  line-height:28px;
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  margin-bottom:20px;

                  @include breaker.below(grid.$s){
                        font-size:16px;
                  }
            }

            p {
                  font-size:14px;
                  line-height:24px;
            }

            strong {
                  @include mixins.parsefont(theme.$theme-heavy-font);
            }

            a {
                  color:theme.$black;
            }
      }

      &__content__books {
            padding-bottom:40px;
            margin-bottom:40px;
            border-bottom:1px solid theme.$bordergray;

            h2 {
                  font-size:20px;
                  line-height:28px;
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  margin-bottom:20px;

                  @include breaker.below(grid.$s){
                        font-size:16px;
                  }
            }

            &__book {
                  img {
                        width:100%;
                        height:auto;
                        margin-bottom:20px;
                  }
                  a {
                        color:theme.$black;
                        font-size:16px;
                        line-height:24px;
                  }

                  p {
                        font-size:16px;
                        line-height:24px;
                  }
            }

            .grid {
                  @supports (display: grid) {
                        display:grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-column-gap: 40px;
                        row-gap:40px;
                        align-items:end;

                        @include breaker.below(grid.$s){
                              grid-template-columns:repeat(2, 1fr);
                        }
                  }
            }
      }

      &__content__news {
            &__section-title {
                  @include mixins.parsefont(theme.$theme-heavy-font);
                  font-size:20px;
                  line-height:28px;
                  margin-bottom:40px;

                  @include breaker.below(grid.$s){
                        font-size:16px;
                        margin-bottom:20px;
                  }
            }

            &__col {
                  span {
                        font-size:14px;
                        line-height:22px;
                        letter-spacing:1px;
                        margin-bottom:10px;
                        display:block;
                  }

                  h2 {
                        color:theme.$black;
                        @include mixins.parsefont(theme.$theme-heavy-font);
                        font-size:24px;
                        line-height:32px;
                        margin-bottom:24px;
                  }

                  a {
                        text-decoration:none;
                        color:theme.$black;

                        &:hover {
                              text-decoration:underline;
                        }
                  }
            }

            .grid {
                  @supports (display: grid) {
                        display:grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-column-gap: 40px;
                        row-gap:40px;
                        align-items:end;

                        @include breaker.below(grid.$s){
                              grid-template-columns:repeat(1, 1fr);
                        }
                  }
            }
      }
}