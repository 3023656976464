@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.pagination {
      text-align:center;
      margin:100px 0 80px;

      @include breaker.below(grid.$s){
            margin:60px 0 40px;
      }

      &__wrap {
            display:inline-block;
      }

      .inactive {
            opacity:0.2;
            pointer-events:none;
      }

      &__first {
            width:30px;
            height:21px;
            background-image:url('../img/pagination-icon-first.svg');
            background-size:30px 21px;
            float:left;
      }

      &__prev {
            width:19px;
            height:21px;
            background-image:url('../img/pagination-icon-prev.svg');
            background-size:19px 21px;
            float:left;
            margin-left:30px;
      }

      &__next {
            width:19px;
            height:21px;
            background-image:url('../img/pagination-icon-next.svg');
            background-size:19px 21px;
            float:left;
            margin-right:30px;
      }

      &__last {
            width:30px;
            height:21px;
            background-image:url('../img/pagination-icon-last.svg');
            background-size:30px 21px;
            float:left;
      }

      &__count {
            font-size:16px;
            line-height:21px;
            margin-left:30px;
            margin-right:30px;
            float:left;
      }
}