@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

$backdrop-opacity: 0.1;

// basic icon colours
$lg-icon-color: #fff !default;
$lg-icon-hover-color: #fff !default;
$lg-icon-bg: rgba(50, 50, 52, 0.5) !default;
$lg-icon-hover-bg: rgba(50, 50, 52, 0.9) !default;

// paths
$lg-path-fonts: '../fonts';
$lg-path-images: '../img';

@use "lightgallery.js/src/css/lightgallery";

body:has(.is-fixed-size.lg-outer.lg-visible) {
      overflow: hidden;
}

.is-fixed-size.lg-outer {
      --lg-padding-x: 30px;
      --lg-padding-y: 16px;
      --lg-icon-offset: 24px;

      top: 0px;
      padding-top: var(--wp-admin--admin-bar--height, 0px);
      position: fixed;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding-left: 32px;
      padding-right: 32px;
      min-height: 0;

      &.lg-has-thumb .lg-item {
            padding: 0;
            margin-bottom: 0;
      }

      & > .lg-dialog {
            max-width: 1024px;
            margin: 32px;
            margin-right: auto;
            margin-left: auto;
            pointer-events: none;
            display: flex;
            align-items: center;
            min-height: calc(100% - 16px * 2);
      }

      .lg-content {
            position: relative;
            pointer-events: none;
            overflow: initial;
            width: 100% !important;
            height: auto !important;
            display: flex;
            flex-direction: column;
            padding: var(--lg-padding-y, 16px) var(--lg-padding-x, 16px);
            padding-bottom: calc(var(--lg-padding-y, 16px) + 40px);
            background: #fff;
            border-radius: 8px;
            min-height: 0;
            pointer-events: all;
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1), 0 6px 32px rgba(0, 0, 0, 0.1);
      }

      .lg-inner {
            position: relative;
            height: auto;
            flex-shrink: 0;
            margin-bottom: 16px;
      }

      .lg-item {
            display: block !important;
            top: 0;
            text-align: left;
            width: 100%;
            height: auto;
            overflow-x: hidden;
            margin-bottom: 0;

            &::before {
                  display: none;
                  height: auto;
                  margin: 0;
                  width: 100%;
            }
      }

      .lg-item.lg-current {
            position: relative;
      }

      .lg-img-wrap {
            background: #f1f1f1;
            position: relative;
            height: auto;
            // min-height: 320px;
            // max-height: 640px;
            padding: 0;
            border-radius: 6px;
            overflow: hidden;

            &::before {
                  display: none;

                  // display: block;
                  padding-top: 56.25%;
            }
      }

      .lg-sub-html {
            position: relative;
            text-align: left;
            padding: 32px 0px;
            background: transparent;
            bottom: auto !important;
            color: #444;
            padding: 0;
            margin: 0;
      }

      .lg-thumb-outer {
            margin-top: 32px;
            position: relative;
            bottom: auto;
            background: transparent;
            max-height: none;
            padding: 0;

            .lg-thumb.group {
                  height: auto;
                  display: flex;
                  flex-wrap: wrap;
                  gap: 8px;
                  padding: 0;
                  margin: 0 !important;
            }

            .lg-thumb-item {
                  width: 120px;
                  height: 67.5px;
                  margin: 0 !important;
            }
      }

      .lg-object.lg-image {
            --zero-px: 0px;

            display: block;
            vertical-align: initial;
            width: 100% !important;
            // height: 100% !important;
            object-fit: contain;
            object-position: center;
            // position: absolute;
            // left: 0;
            // top: 0;
            border-radius: 4px;
            height: auto;
            max-height: 640px;
            max-height: Min(640px, calc(100vh - var(--lg-padding-y) * 2 - 32px - var(--wp-admin--admin-bar--height, var(--zero-px, 0px))));
      }

      .lg-actions {
            --btn-size: 36px;

            .lg-prev,
            .lg-next {
                  padding: 7px;
                  background: $lg-icon-bg;
                  border-radius: 999px;
                  width: var(--btn-size, 36px);
                  height: var(--btn-size, 36px);
                  margin-top: calc(var(--btn-size, 36px) / -2);

                  &:hover {
                        background: $lg-icon-hover-bg;
                  }

                  &:disabled {
                        opacity: 0;
                  }
            }

            .lg-prev {
                  left: calc(var(--btn-size, 36px) / -2);
            }

            .lg-next {
                  right: calc(var(--btn-size, 36px) / -2);
            }
      }

      .lg-toolbar {
            opacity: 1;
            position: static;
            background: transparent;
            margin: 0;
            height: 0px;
      }

      .lg-close,
      .lg-download {
            position: absolute;
            width: 40px;
            height: 40px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 999px;
            background: $lg-icon-bg;
            right: var(--lg-icon-offset, 24px);
            top: var(--lg-icon-offset, 24px);

            &:hover {
                  background: $lg-icon-hover-bg;
            }
      }

      .lg-close {
            top: -20px;
            right: -20px;
            background: $lg-icon-hover-bg;
      }

      .lg-download {
            position: relative;
            right: 12px;
            top: -73px;
      }

      #lg-counter {
            position: absolute;
            left: var(--lg-padding-x);
            top: var(--lg-padding-y);
            color: #222;
            padding: 8px 12px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 0 0 6px 0;
            font-weight: bold;
            display: none;
      }

      &.lg-has-thumb #lg-counter {
            display: block;
      }

      @media screen and (min-width: 768px) {
            --lg-padding-x: 36px;
            --lg-padding-y: 24px;
            --lg-icon-offset: 16px;
      }

      @media screen and (min-width: 1024px) {
            --lg-padding-x: 40px;
            --lg-padding-y: 40px;
      }
}