@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.top-section {
      max-width:1440px;
      width:100%;
      margin: 0 auto;
      margin-bottom:60px;
      background-image:theme.$theme-intro-background-image;
      background-repeat:no-repeat;
      background-color:theme.$theme-intro-background-color;
      background-size:cover;
      background-position:center top;
      height:420px;
      position:relative;

      @include breaker.below(grid.$s) {
            height: 280px;
      }

      &--overlap {
            margin-bottom:-100px;

            @include breaker.below(grid.$m){
                  margin-bottom:-60px;
            }
      }

      &__wrapper {
            max-width:1280px;
            width:100%;
            @include mixins.horizontal-align(absolute);
            bottom:135px;

            @include breaker.below(1330px){
                  padding:0 40px;
            }

            @include breaker.below(grid.$m){
                  transform:none;
                  left:20px;
                  right:20px;
                  bottom:theme.$theme-intro-top-section-mobile-bottom;
                  width:auto;
                  padding:0;

                  .col-7{
                        width:100%;
                  }
            }
      }

      &__count {
            background-image:theme.$theme-intro-search-icon;
            background-size:20px 20px;
            width:20px;
            height:20px;
            display:inline-block;
            vertical-align: middle;
            margin-right:5px;
            margin-bottom:10px;
            transition:opacity .4s ease-in-out;

            &.fade {
                  opacity:0.4;
            }
      }

      &__title {
            font-size:40px;
            line-height:48px;
		        color:theme.$theme-intro-main-text-color;

            @include mixins.parsefont(theme.$theme-intro-main-font);

            @include breaker.below(930px){
                  font-size:30px;
                  line-height:40px;
            }

            @include breaker.below(1080px) {
                  font-size:30px;
                  line-height:40px;
            }

            @include breaker.below(grid.$s) {
                  font-size: 20px;
                  line-height: 30px;
            }

            span {
                  transition:opacity .4s ease-in-out;
            }


            a {
                  color:theme.$theme-intro-books-author-color;
                  transition:opacity .4s ease-in-out;
                  text-decoration:none;
                  position:relative;
                  display: inline-block;

                  &:after {
                        content:'';
                        position:absolute;
                        height:3px;
                        width:100%;
                        bottom:0px;
                        left:0;
                        background-color:theme.$theme-intro-books-author-color;
                  }

                  &:before {
                        content:'';
                        position:absolute;
                        height:3px;
                        width:0%;
                        bottom:0px;
                        left:0;
                        background-color:theme.$theme-intro-books-author-color;
                        transition: width .4s cubic-bezier(.405,.07,.575,.995) .1s;
                  }
            }

            &--fade {
                  span {
                        opacity:0.4;
                  }

                  a {
                        opacity:0.4;

                        &.hover {
                              opacity:1;

                              &:after {
                                    display:none;
                              }

                              &:before {
                                    width:100%;
                              }
                        }
                  }
            }

            &--search {
                  color: theme.$theme-intro-search-text-color;
            }
      }

      &__bonnier {
            text-align:right;
            position:absolute;
            bottom:0;
            right:0;

            @include breaker.below(1330px){
                  right:40px;
            }

            @include breaker.below(grid.$m){
                  display:none;
            }

            span {
                  color:theme.$theme-intro-bonnier-text-color;
                  font-size:16px;
                  line-height:24px;


                  a {
                        color:theme.$theme-intro-bonnier-text-color;
                        text-decoration:none;
                        &:hover {
                              text-decoration:underline;
                        }
                  }
            }
      }
}