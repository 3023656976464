
@use "theme/theme";
@use "grid";
@use "mixins";
@use "breaker";

.button {
      color:theme.$theme-button-text-color;
      text-decoration:none;
      background-color:theme.$theme-button-bg-color;
      padding:12px 31px;
      border-radius:8px;
      display:inline-block;
      font-size:16px;
      line-height:26px;
      border:none;
      appearance: none;
      transition:opacity .2s ease-in-out;
      outline:none;


      &:hover {
            text-decoration:underline;
	}

	&--black {
		background-color:theme.$black;
		color:theme.$white;
	}

      &--white {
            background-color:theme.$white;
            border:1px solid theme.$black;
            color:theme.$black;
      }

      &--green {
            color: #000000;
            background-color: #bcd200;
      }

      &--round {
            border-radius: 100px;
      }

      &--full-width {
            width:100%;
            text-align:center;
	}

	&--inactive {
		opacity:0.6;
		pointer-events:none;
	}

      &--add-to-cart {
		position:relative;
		background-image:url('../img/icon-bag.svg');
		background-repeat:no-repeat;
		background-position:center left 30px;
		padding: 14px 31px 10px 67px;
		outline:none;
		transition:background-color .2s ease-in-out;
		overflow:hidden;

            em {
			font-style:normal;
			transition:color .2s ease-in-out;
			line-height:26px;
		}

		svg {
			/* this is the check icon */
			position: absolute;
			left: 50%;
			top: 50%;
			width: 100%;
			/* move the icon on the right - outside the button */
			transform: translateX(50%) translateY(-50%);
			transition:stroke-dashoffset .2s ease-in-out;
			stroke-dashoffset: 19.79;
		}

		&.adding {
			background-image:none;

			em {
				color: transparent;
				transform: translateX(-100%);
			}

			svg {
				transform: translateX(-50%) translateY(-50%);
				stroke-dashoffset: 0;
			}
		}
      }

      &--spinner {
            position:relative;
            overflow:hidden;

            em {
			font-style:normal;
			transition:color .2s ease-in-out;
			line-height:26px;
		}

		svg {
			/* this is the check icon */
			width: 64px;
			height: 64px;
			position: absolute;
			left: 50%;
			top: 50%;
			width: 100%;
			/* move the icon on the right - outside the button */
			transform: translateX(50%) translateY(-50%);
		}

		&.spin {
			pointer-events:none;
			em {
				color: transparent;
				transform: translateX(-100%);
			}

			svg {
				transform: translateX(-50%) translateY(-50%);
				stroke-dashoffset: 0;
			}
		}
      }
}